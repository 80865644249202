import React, { Component } from 'react';
import './page-table.scss';
import { Table, Loading } from 'element-react';
import PageHolder from "./page-holder.component";
import withRouter from "react-router-dom/es/withRouter";
import { translate } from 'react-i18next';

@withRouter
@translate()
export default class PageTable extends Component {
    state = {
        sort: null,
    };
    render() {
        const { sort } = this.state;
        const { t, limit, currentPage, totalResult, results, columns, setSort, setCurrentPage, loading = false } = this.props;
        return (
            <div className="page-table">
                <Loading loading={loading}>
                    <Table
                        defaultSort={sort}
                        onSortChange={(e) => {
                            setSort(e);
                            this.setState({
                                sort: e,
                            });
                        }}
                        border={true}
                        columns={columns}
                        data={results}
                        style={{maxWidth:"100%"}}
                    />
                    <PageHolder
                        results={results}
                        currentPage={currentPage}
                        limit={limit}
                        totalResult={totalResult}
                        setCurrentPage={(Page) => setCurrentPage(Page)}
                    />
                </Loading>
            </div>
        )
    }
}