import React, { Component } from 'react';
import './staff-all.scss';
import * as staffApi from "../../../../api/staff.api";
import { Input, Button } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import withRouter from "react-router-dom/es/withRouter";
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";
import { Table } from 'antd';

@withRouter
@translate()
export default class StaffAllPage extends Component {
    state = {
        results: [],
        lastTextChange: 0,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 10,
        reference: "",
        totalResult: 0,
        reportMonth: new Date(),
        sort: null,
        loading: true,
        columns : [
            {
                title: this.props.t('staff_all_table_column_id'),
                dataIndex: "id",
                sorter: {
                    multiple: 1,
                },
            },
            {
                title: this.props.t('staff_all_table_column_surname'),
                dataIndex: "surname",
            },
            {
                title: this.props.t('staff_all_table_column_name'),
                dataIndex: "name",
            },
            {
                title: this.props.t('staff_all_table_column_gender'),
                dataIndex: "gender",
                render: (_,row) => {
                    return <span>
                        {this.props.t(row.gender)}
                    </span>
                },
                sorter: {
                    multiple: 2,
                },
            },
            {
                title: this.props.t('staff_all_table_column_mobile'),
                dataIndex: "phone",
                sorter: {
                    multiple: 3,
                },
            },
            {
                title: this.props.t('staff_all_table_column_salary_commission'),
                dataIndex: "nowCommission",
            },
            {
                title: this.props.t('staff_all_table_column_expected_commission'),
                dataIndex: "expectedCommission",
            },
            {
                title: this.props.t('staff_all_table_column_type'),
                dataIndex: "type",
                sorter: {
                    multiple: 6,
                },
                render:(_,row) => {
                    return <span>{this.props.t(`staff_tab_basic_info_type_${row.type}`)}</span>
                },
            },
            {
                title: this.props.t('staff_all_table_column_detail'),
                render: (_, row, index) => {
                    return <div style={{textAlign:"center"}}>
                        {
                            <Button onClick={() => this.switchTab("/main/staff-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                        }
                    </div>
                },
                width: "99",
                fixed: "right"
            },
            {
                title: this.props.t('staff_all_table_column_schedule'),
                render: (_, row, index) => {
                    return <div style={{textAlign:"center"}}>
                        {
                            <Button onClick={() => this.switchTab("/main/staff-schedule/" + row.id)}>{this.props.t('button_view')}</Button>
                        }
                    </div>
                },
                width: "150",
                fixed: "right"
            },
            {
                title: this.props.t('operate'),
                render: (_, row, index) => {
                    return <div style={{textAlign:"center"}}>
                        {
                            <Button onClick={() => { this.deleteStaff(row) }}>删除</Button>
                        }
                    </div>
                },
                width: "99",
                fixed: "right"
            },
        ],
        orders:[]
    };

    onSearch = async (reference) => {
        const initiateTime = Date.now();
        this.setState({ lastTextChange: initiateTime });
        setTimeout(async () => {
            if (this.state.lastTextChange === initiateTime) {
                this.setState({ reference });
                this.updateStaffRecords();
            }
        }, 500);
    }

    switchTab = (url) => {
        history.push(url);
    }

    onChange  (pagination, filters, sorter, extra){
        let orders = []
        if (sorter.length > 0) {
            sorter.forEach(item => {
                orders.push([item.field,item.order == "ascend"?"ASC":"DESC"])
            });
        } else if (sorter.field) {
            orders.push([sorter.field,sorter.order == "ascend"?"ASC":"DESC"])
        }
        this.setState({
            orders,
            currentPage:pagination.current,
            limit:pagination.pageSize
        },()=>{
            this.updateStaffRecords()
        })
    };

    async deleteStaff(row) {
        const confirmed = window.confirm(`确定要删除吗？`,"提示信息");
        if (confirmed) {
            staffApi.deleteStaff(row.id).then(res => {
                this.updateStaffRecords()
            })
        }
    }

    async updateStaffRecords() {
        const { currentPage, orderBy, direction, limit, reference,orders,loading } = this.state;
        this.setState({loading:true})     
        let query = {
            page : currentPage, 
            orderBy:null, 
            direction:null, 
            limit,
            reference,
            orders,
        }
        const response = await staffApi.getStaffs(query);
        this.setState({
            results: response.rows.map((staff,index) => {
                const data = {
                    key:index+1+"",
                    id: staff.id,
                    type: staff.type,
                    surname: staff.staff_details[0] ? staff.staff_details[0].surname : null,
                    name: staff.staff_details[0] ? staff.staff_details[0].name : null,
                    phone: staff.phone,
                    gender: staff.gender,
                    nowCommission: staff.nowCommission,
                    expectedCommission: staff.expectedCommission,
                }
                return data;
            }),
            totalResult: response.count,
            loading: false,
        });
    }

    async componentDidMount() {
        await this.updateStaffRecords();
    }

    render() {
        const { t, match } = this.props;
        const { limit, currentPage, totalResult, results, sort, loading, columns } = this.state;
        const isScheduleView = match.url.indexOf('staff-schedule-all') !== -1;

        return (
            <div className="staff-all-page">
                <div className="main-title">
                    {t('staff_all_title')}
                </div>
                <div className="page-header">
                    {/* <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} /> */}
                    <div className="space"></div>
                    <div className="search-bar">
                        <Input placeholder={t('staff_all_search_bar_placeholder')} onChange={(text) => this.onSearch(text)} />
                    </div>
                    {!isScheduleView ? <div className="add-staff-button">
                        <Button onClick={() => this.switchTab("/main/staff-detail/create")}>{t('staff_all_button_add_staff')}</Button>
                    </div> : null}
                </div>
                <Table columns={columns} loading={loading} pagination={{"total":totalResult,showSizeChanger:true}}  total={totalResult} dataSource={results} onChange={this.onChange.bind(this)} scroll={{ x: 1500 }}/>
            </div>
        );
    }
}