import client from '../apiClient';


//产品列表
export async function page(data) {
    let response = await client.post('/product/page' , data)
    return response
}

export async function createProducts(data) {
    let response = await client.post('/product/createProducts' , data)
    return response
}

export async function deleteProduct(id) {
    let response = await client.delete(`/product/${id}`)
    return response
}

export async function updateProduct(data) {
    let response = await client.patch(`/product`,data)
    return response
}
export async function selectAll () {
    let response = await client.get('/product/selectAll')
    return response
}

