import {translate} from "react-i18next";
import React, {Component} from "react";
import {Dialog, Select, Input, Form, Button, DatePicker, Layout, Message , AutoComplete} from 'element-react'
import {connect} from "react-redux";
import './create-order-dialog.scss'
import {getStaffAll} from "../../../../api/staff.api";
import {getAllList} from "../../../../api/user.api";
import {selectAll} from "../../../../api/product.api";
import * as ProductOrderRecordApi from '../../../../api/productOrder.api'

@translate()
@connect((state) => {
    return {
        loginId: state.staff.staff.id,
    };
})

export default class CreateOrderDialog extends Component {
    state = {
        visible : true,
        loading : false,
        formData: {
            staffId: "",
            userId : "",
            date : new Date(),
            products : [
                {
                    productId : "",
                    count : "",
                    price : ""
                }
            ]
        },
        productsList:[],
        staffsList : [],
        usersList : [],
    }

    onChange(index , key, value) {
        this.state.formData.products[index][key] = value;
        if(key === 'productId') {
            let product = this.state.productsList.find(e => e.value === value)
            if (value) {
                this.state.formData.products[index]['price'] = product.price
            }
        }
        this.forceUpdate();
    }

    changeData(key , value) {
        this.state.formData[key] = value
        this.forceUpdate()
    }

    addDomain(e){
        e.preventDefault();
        this.state.formData.products.push({
            name: null,
            price: null,
            repertory: null,
            icon: null,
        });
        this.forceUpdate()
    }

    removeDomain(item, e) {
        e.preventDefault();
        if (this.state.formData.products.length <=1 ) return
        var index = this.state.formData.products.indexOf(item);
        if (index !== -1) {
            this.state.formData.products.splice(index, 1);
            this.forceUpdate();
        }
    }

    commit (e) {
        e.preventDefault();
        this.refs.form.validate((valid) => {
            if (!valid) {
                return false
            }
            this.state.loading = true
            const {loginId , success} = this.props
            let request = {
                ...this.state.formData
            }
            request.loginId = loginId
            ProductOrderRecordApi.createOrder(request).then(result => {
                const {code} = result.data
                if (code !== 200) return
                success()
            }).catch(err => {
                console.log(err.userMessage)
                Message({
                    message: err.userMessage,
                    type: 'error',
                    customClass: 'zZindex',
                    showClose: true
                })
            })

        })
    }

    getUser() {
        getAllList().then(e => {
            let { usersList } = this.state
            e.forEach(user => {
                let data = {
                    value : user.surname + user.name,
                    key : user.id
                }
                usersList.push(data)
            })
            this.forceUpdate();
        }).catch(err => {
            Message.error(err)
        })
    }

    getStaff() {
        getStaffAll().then(e => {
            let { staffsList } = this.state
            e.forEach(staff => {
                let data = {
                    name : staff.staff_details[0].surname + staff.staff_details[0].name,
                    id : staff.id
                }
                staffsList.push(data)
            })
            this.forceUpdate();
        }).catch(err => {
            Message.error(err)
        })
    }

    getProducts() {
        selectAll().then(e => {
            const {data} = e
            let {productsList} = this.state
            data.forEach(product => {
                let data = {
                    label : product.name,
                    value : product.id,
                    price : product.price
                }
                productsList.push(data)
            })

            this.forceUpdate()
        }).catch(err => {
            Message.error(err)
        })
    }

    createFliter(queryString) {
        return (data) => {
            return (data.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0)
        }
    }

    querySearch(queryString , callback) {
        const { usersList } = this.state
        const results = queryString ? usersList.filter(this.createFliter(queryString))  : usersList
        callback(results)
    }

    handleSelect(item) {

    }

    componentDidMount() {
        // this.getData()
        this.getStaff()
        this.getUser()
        this.getProducts()
    }


    render() {
        const {isClosed } =this.props;
        const {formData , visible , loading ,usersList} = this.state
        const { products } = formData
        return (
            <main className='create-product-order-main'>
                <Dialog title='创建订单' visible={ visible } onCancel={ isClosed} >
                    <div className='product-order-container'>
                        <Form ref='form' model={formData} labelPosition='right' labelWidth='80'>
                            <Layout.Row  gutter={20}>
                                <Layout.Col lg='12' md='12' sm='24'>
                                    <Form.Item prop='staffId' style={{textAlign:"center"}} label='銷售人'>
                                        <Select value={formData.price} placeholder="請選擇員工" filterable={true} onChange={(value) => this.changeData("staffId",value)}>
                                            {
                                                this.state.staffsList.map(e => {
                                                    return <Select.Option value={e.id} key={e.id} label={e.name}/>
                                                })
                                            }
                                        </Select>

                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='12' md='12' sm='24'>
                                    <Form.Item prop='userId' style={{textAlign:"center"}} label='客戶'>
                                        {/*<Select value={formData.price}  onChange={(value) => this.changeData(index,"userId",value)}>*/}
                                        {/*    {*/}
                                        {/*        this.state.usersList.map(e => {*/}
                                        {/*            return <Select.Option value={e.id} key={e.id} label={e.name}/>*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</Select>*/}
                                        <AutoComplete
                                            value={formData.userId}
                                            placeholder="請選擇客戶"
                                            onSelect={(value) => this.changeData("userId",value.key)}
                                            fetchSuggestions={this.querySearch.bind(this)}
                                            triggerOnFocus={false}
                                        ></AutoComplete>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='12' md='12' sm='24'>
                                    <Form.Item prop='date'  label='銷售日期'>
                                        <DatePicker value={formData.date} placeholder="选择日期" onChange={this.changeData.bind(this, 'date')}/>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>
                            <hr style={{marginBottom:'22px'}}></hr>
                            {
                                products.map((product , index) => {
                                    return (
                                        <Layout.Row key={index} gutter={20}>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="产品名称" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        productId: { type: 'string', required: true, message: '請選擇產品', trigger: 'change' }
                                                    }
                                                }}>
                                                    <Select value={product.productId} placeholder="請選擇" filterable={true} onChange={(value) => this.onChange(index,"productId",value)}>
                                                        {
                                                            this.state.productsList.map(e => {
                                                                return <Select.Option value={e.value} key={e.value} label={e.label}/>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="數量" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        count: [{ required: true, message: '產品數量', trigger: 'blur' },
                                                            { validator: (rule, value, callback) => {
                                                                    let times = parseInt(value, 10);
                                                                    if (!Number.isInteger(times)) {
                                                                        callback(new Error('请输入数字值'));
                                                                    } else if (times <= 0 ) {
                                                                        callback(new Error('请输入大于0的数值'));
                                                                    } else {
                                                                        callback();
                                                                    }
                                                                },
                                                                trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={product.count} placeholder="請輸入數量" onChange={(value) => this.onChange(index,"count",value)}/>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="产品價格" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        price: [{ required: true, message: '产品价格不能为空', trigger: 'blur' },
                                                            { validator: (rule, value, callback) => {
                                                                    var times = parseInt(value, 10);
                                                                    if (!Number.isInteger(times)) {
                                                                        callback(new Error('请输入数字值'));
                                                                    } else if (times <= 0 ) {
                                                                        callback(new Error('请输入大于0的数值'));
                                                                    } else {
                                                                        callback();
                                                                    }
                                                                },
                                                                trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input disabled={true} value={product.price} placeholder="请输入價格" onChange={(value) => this.onChange(index,"cost",value)}/>
                                                </Form.Item>
                                            </Layout.Col>

                                            <Layout.Col lg="24" md="24" sm="24">
                                                <Form.Item style={{textAlign:"right"}}>
                                                    <Button onClick={this.removeDomain.bind(this, product)}>删除</Button>
                                                </Form.Item>
                                            </Layout.Col>
                                        </Layout.Row>
                                    )
                                })
                            }
                            <Layout.Row className='icon-main'>
                                <Layout.Col lg="24" md="24" sm="24">
                                    <Button onClick={this.addDomain.bind(this)}>增加</Button>
                                    <Button type="primary" loading={loading} onClick={this.commit.bind(this)}>提交</Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form>
                    </div>
                </Dialog>
            </main>
        )
    }
}