import client from '../apiClient';


// 导出execl
export async function exprotExecl(data){
    let response = await client.get(`/reservation/exportExcel`,{
        params:data,
        responseType: "blob"
    })
    return response.data
}

export async function listUserReport(query){
    let response = await client.get(`/report/user`,{
        params:query,
    })
    return response
}

export async function listSerivceReport(query){
    let response = await client.get(`/report/service`,{
        params:query,
    })
    return response
}

export async function listTotalReport(query){
    let response = await client.get(`/report/total`,{
        params:query,
    })
    return response
}

// 导出execl
export async function listExprotExecl(data){
    let response = await client.post(`/report/exportExcel`,
        data,{responseType: "blob"})
    return response.data
}

export async function getExpendExprot(data) {
    let response = await client.post(`/report/consume`,data)
    return response
}

// 导出 expend-execl
export async function expendExecl(data){
    let response = await client.post(`/report/expend-execl`,
        data,{responseType: "blob"})
    return response.data
}

// 新旧客消费报表 
export async function expensesRecordList(data) {
    let response = await client.post(`/report/expenses-record`,data)
    return response
}

//充值消费报表 
export async function topDeductRecordList(data) {
    let response = await client.post(`/report/top-deduct-record`,data)
    return response
}

//员工提成明细报表 
export async function staffCommissionRecordList(data) {
    let response = await client.post(`/report/staff-commission-record`,data)
    return response
}

//员工提成汇总报表 
export async function staffCommissionSummaryList(data) {
    let response = await client.post(`/report/staff-commission-summary`,data)
    return response
}

// 次卡報表
export async function timesRecordList(data) {
    let response = await client.post(`/report/times-record`,data)
    return response
}

export async function timesUsedRecordList(data) {
    let response = await client.post(`/report/times-used-record`,data)
    return response
}

// 汇总
export async function revenueSummaryRecord(data) {
    let response = await client.post(`/report/revenue-summary`,data)
    return response
}

export async function revenueSummaryGender(data) {
    let response = await client.post(`/report/revenue-summary-gender`,data)
    return response
}

export async function serviceSummary(data) {
    let response = await client.post(`/report/service-summary`,data)
    return response
}

export async function getScoreReport(data) {
    let response = await client.post(`/report/getScoreReport`,data)
    return response
}

export  async function getRepertoryReport(data) {
    let response = await  client.post('/report/getRepertoryReport' , data)
    return response
}