import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as reservationApi from "../../../api/reservation.api";
import * as packagesApi from "../../../api/servicePackages";
import { Button, Dialog, Form, Select, Radio,Message,MessageBox, InputNumber } from 'element-react';
import moment from 'moment'
import { isJSON } from '../../../utils/isJSON'

@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class CheckInDialog extends Component {

    state = {
        loading:false,
        checkInVisible:true,
        checkInForm:{
            type: 'normal',
            commission: 100,
            timesId:null,
            payment:"visa",
            times:1
        },
        checkInRules:[],
        serviceTimes:[],
        times:{},
        payOptions: [
            {
                value: 100,
                label: this.props.t('booking_table_full_commission')
            },
            {
                value: 90,
                label: this.props.t('booking_table_full_ninety_percentage_commission')
            }
        ],
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            },{
                value: "現金",
                label: "現金"
            },{
                value:"Octpus",
                label: "Octpus"
            },{
                value:"Kpay",
                label: "Kpay"
            }
        ],
    };

    onChange(key, value) {
        if (key == 'timesId') {
            const {serviceTimes} = this.state
            let timesMap = serviceTimes.map(item => item.key)
            let keyIndex = timesMap.indexOf(value)
            let packageTimes = serviceTimes[keyIndex]
            this.setState({times:packageTimes.service})
        }

        this.setState({
            checkInForm: Object.assign(this.state.checkInForm, { [key]: value })
        });
    }

    getServiceTimesList(row){
        if (!row.userId) return

        let request = {
            userId:row.userId,
            serviceId:row.serviceId
        }
        packagesApi.useListPackages(request).then(result => {
            const { count, result:datas} = result.data
            if (count <= 0) return

            let packages = []

            datas.forEach(data => {
                let date = data.expireDate
                if ( new Date(date).getTime() >= new Date().getTime()) {
                    let results = isJSON(data.extra)
                    let datas = results ? JSON.parse(data.extra)  : data.extra
                    let newDatas = isJSON(datas) ? JSON.parse(datas) : datas
                    newDatas.forEach(services => {
                        let service = services.containService.find(item => item.serviceId == row.serviceId)
                        if (service && services.overTimes > 0) {
                            packages.push({
                                key:data.id,
                                label:data.name,
                                expireDate:data.expireDate,
                                service:{
                                    name:services.containService.map(item => item.name).join("/"),
                                    serviceId:service.serviceId,
                                    times:services.times,
                                    overTimes:services.overTimes,
                                    expireDate:data.expireDate,
                                }
                            })
                        }
                    })
                }
            })
            
            let packageTimes = packages[0]

            this.setState({
                serviceTimes:packages,
                checkInForm: Object.assign({}, this.state.checkInForm, { timesId: packages[0].key }),
                times:packageTimes.service
            },()=>{
                console.log(this.state)
            })
        }).catch(error => {
            MessageBox.alert(error);
        })
    }

    checkIn() {
        const { checkInForm,loading } = this.state
        const { row,staffId } = this.props
        let query = {
            ...checkInForm,
            reservationId : row.id,
            status : 'checked',
            remark : row.remark,
            bookId:staffId
        }
        
        if (loading) return

        this.setState({
            loading:true
        })

        reservationApi.checkIn(query).then(res => {
            const { success } = this.props
            if (res) {
                success()
            }
        }).catch(error => {
            Message.error(error.userMessage)
        }).finally(() => {
            this.setState({
                loading:false
            })
        })
    }

    componentDidMount() {
        const { row }  = this.props
        this.getServiceTimesList(row)
    }

    render() {
        const { checkInVisible,checkInForm,times } = this.state 
        const { row,closed,t } = this.props
        return (
            <main>
                <Dialog size="small" visible={checkInVisible} onCancel={() => closed()} lockScroll={false}>
                    <Dialog.Body>
                        <Form ref="form" model={checkInForm} labelWidth="120">
                            <Form.Item label={t('booking_table_settlement_method')}>
                                <Radio.Group value={checkInForm.type} onChange={(value) => { this.onChange('type', value) }}>
                                    <Radio value="normal">{t('booking_table_pay_normal')}</Radio>
                                    <Radio value="boss">{t('booking_table_pay_boss')}</Radio>
                                    <Radio value="integral" disabled={!(row.user && row.user.integral && row.user.integral > 0)}>{t('booking_table_pay_redeem')}</Radio>
                                    <Radio value="times" disabled={!(row.user && this.state.serviceTimes.length > 0)}>次数支付</Radio>
                                    <Radio value="balance" disabled={!(row.user && row.user.family && row.user.family.balance >= row.service.price)}>点数支付</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {
                                checkInForm.type == 'normal' ?
                                    <Form.Item label="结算方式">
                                        <Select value={checkInForm.payment} onChange={(value) => this.onChange('payment', value)} placeholder="請選擇">
                                            {
                                                this.state.payTypeOptions.map(el => {
                                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item> : null
                            }
                            {
                                checkInForm.type != 'normal' ?
                                    <Form.Item label={t('booking_table_commission_ratio_selection')}>
                                        <Select value={checkInForm.commission} onChange={(value) => this.onChange('commission', value)} placeholder="請選擇">
                                            {
                                                this.state.payOptions.map(el => {
                                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item> : null
                            }
                            {
                                checkInForm.type == 'times' ? 
                                <section>
                                    <Form.Item label='服务套餐选择'>
                                        <Select value={checkInForm.timesId} onChange={(value) => this.onChange('timesId', value)} placeholder="請選擇">
                                            {
                                                this.state.serviceTimes.length > 0 ? this.state.serviceTimes.map(el => {
                                                    return <Select.Option key={el.key} label={el.label} value={el.key} />
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                    {
                                            checkInForm.type == 'times' && Object.keys(times).length > 0 ? 
                                            <div>
                                                <Form.Item label='服务'>
                                                    {times.name}
                                                </Form.Item>
                                                <Form.Item label='总次数'>
                                                    {times.times}
                                                </Form.Item>
                                                <Form.Item label='剩余次数'>
                                                    {times.overTimes}
                                                </Form.Item>
                                                <Form.Item label='使用次数'>
                                                    <InputNumber defaultValue={1} value={checkInForm.times} onChange={(value) => this.onChange('times', value)} min={1} max={times.overTimes}></InputNumber>
                                                </Form.Item>
                                                <Form.Item label='到期日期'>
                                                    {moment(times.expireDate).format('YYYY-MM-DD HH:mm')}
                                                </Form.Item>
                                            </div> : null
                                    }
                                </section>
                                    : null
                            }
                            <Form.Item>
                                <Button onClick={() => { this.checkIn() }}>{t('tab_booking_check_in_confirm')}</Button>
                                <Button onClick={() => { closed() }}>{t('cancel')}</Button>
                            </Form.Item>
                        </Form>
                    </Dialog.Body>
                </Dialog>
            </main>
        )
    }
}