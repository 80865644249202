import React, { Component } from 'react';
import { Form,Layout,Input,InputNumber,Select } from 'element-react';
import { Button, Modal, } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { translate } from 'react-i18next';
import * as productsService from '../../../../api/productService.api'
import * as product from '../../../../api/product.api'
@translate()
export default class AddProductsPage extends Component {
    state = {
        serviceId:"",
        staffId:"",
        open:true,
        form:{
            products:[{
                productId:"",
                count:0
            }]
        },
        confirmLoading:false,
        options:[]
    }

    async componentDidMount(){
        const { serviceId } = this.props
        await this.getProducts()
        if ( serviceId ) {
           await this.getProductByServiceId(serviceId)
        }
    }

    async getProducts(){
        this.setState({loading:true})
        const { data} = await product.selectAll()
        let products = data.map(item => {
            return {
                label:item.name,
                value:item.id
            }
        })
       this.setState({options:products,loading:false})
    }

    async getProductByServiceId(serviceId){
        const {data:{data}} = await productsService.getProductByServiceId(serviceId)
        if (data.length > 0) {
            let productData = data.map(item =>{
                return {
                    productId:item.productId,
                    count : item.count
                }
            })
            this.setState({form:Object.assign({},this.state.form,{products:productData})})
        }
    }

    handleOk(e) {
        const {  isClosed } = this.props

        e.preventDefault();

        this.refs.form.validate((valid) => {
          if (valid) {
            this.setState({confirmLoading:true})

            let query = {
                serviceId : this.props.serviceId,
                staffId : this.props.staffId,
                products : this.state.form.products
            }

            productsService.createProductByServiceId(query).then(result => {
                const {data} = result
                setTimeout(() => {
                    isClosed()
                    this.setState({confirmLoading:false})
                }, 2000);
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    };

    addDom(){
        const { form:{products} } = this.state
        products.push({
            productId:"",
            count:0
        })
        this.forceUpdate();
    }

    removeDom(index) {
        const { form:{products} } = this.state
        products.splice(index,1)
        this.forceUpdate();
    }
    
    onChange(key,value,index) {
        const { form:{products} } = this.state
        if (key == "count") {
            value = value + ""
        }
        products[index][key] = value
        this.setState({form:Object.assign({},this.state.form,{products})},()=>{
            console.log(this.state.form)
        })
    }

    render(){
        const { isClosed,serviceId,staffId } = this.props
        const {open,confirmLoading,form,options,loading} = this.state
        const { products } = form
        return (
            <main>
                <Modal title="關聯產品" open={open} width="40%" onOk={() => this.handleOk()} confirmLoading={confirmLoading} onCancel={() => isClosed()} footer={[
                    <Button key="back" onClick={() => isClosed()}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.handleOk.bind(this)}>
                        確認
                    </Button>
                ]}>
                    <Form  ref="form" model={form} labelWidth="80" labelPosition='left' name="dynamic_form_complex" autoComplete="off">
                    {
                        products.length > 0 && products.map((product,keyIndex) => {
                            return (
                                <Layout.Row key={keyIndex}  gutter={20}>
                                    <Layout.Col lg="11" md="11" sm="24">
                                        <Form.Item label="產品" prop={`products:${keyIndex}`} rules={{
                                            type: 'object', required: true,
                                            fields: {
                                                productId: { required: true, message: '產品不能爲空', trigger: 'change' }
                                            }
                                        }}>
                                            <Select loading={loading} value={product.productId} onChange={(value) => this.onChange("productId",value,keyIndex)} >
                                                {
                                                    options.length > 0 && options.map(el => {
                                                    return  <Select.Option key={el.value} label={el.label} value={el.value} />
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col lg="11" md="11" sm="24">
                                    <Form.Item label="次数" prop={`products:${keyIndex}`} rules={{
                                        type: 'object', required: true,
                                        fields: {
                                            count: [{ required: true, message: '數量不能为空', trigger: ['blur','change']},{ validator: (rule, value, callback) => {
                                                var count = parseInt(value, 10);
                                                if (!Number.isInteger(count)) {
                                                    callback(new Error('請輸入數字值'));
                                                } else if (count <= 0 ) {
                                                    callback(new Error('請輸入大於0的值'));
                                                } else {
                                                    callback();
                                                }
                                            }, trigger: ['blur','change']}]
                                        }
                                    }}>
                                        <InputNumber value={parseInt(product.count)} defaultValue={parseInt(product.count)} onChange={(value)=>this.onChange('count',value,keyIndex)}></InputNumber>
                                    </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col lg="1" md="1" sm="24" >
                                        <Form.Item label="" labelWidth="0">
                                            <MinusCircleOutlined onClick={() => this.removeDom(keyIndex)}/>
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>   
                            )
                        })
                    } 
                    </Form>
                   <div>
                        <Button type="dashed" onClick={() => this.addDom()} block icon={<PlusOutlined />}>  Add sights </Button>
                   </div>         
                </Modal>
            </main>
        )
    }
}