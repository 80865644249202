import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import './index.scss';
import { Dialog, Button, Form, Layout, Input,  DatePicker, Message ,Select} from 'element-react';
import * as staffApi from "../../../api/staff.api";
import * as packagesApi from "../../../api/servicePackages";
import moment from 'moment';

@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class UserAddServicePackage extends Component {
    state = {
        staffs:[],
        visible:true,
        request:{
            packageId:null,
            mainSales:null,
            minorSales:null,
            expireDate:null,
            payment:null
        },
        packages:[],
        rules:{
            packageId:[
                { required: true, message: '请选择套餐', trigger: 'change' },
            ],
            mainSales:[
                { required: true, message: '请选择主销售', trigger: 'change' },
            ],
            expireDate:[
                { required: true, message: '请选择过期日期', trigger: 'change' , type:"date" },
            ],
            payment:[
                { required: true, message: '请选择支付方式', trigger: 'change' },
            ]
        },
        price:0,
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            }
        ],
    }

    onChange(key, value) {

        if(!value) return

        if (key == "packageId") {
            const { packages } = this.state
            let data = packages.find(item => item.id == value)

            if (data) {
                this.setState({
                    request: Object.assign({}, this.state.request, { ["price"]: data.price }),
                    price:data.price
                });
            }
        }

        this.setState({
            request: Object.assign({}, this.state.request, { [key]: value })
        },() => {
            console.log(this.state.request)
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        
        this.refs.form.validate((valid) => {
            if (valid) {
                const { request, price } = this.state
                const { staffId,user } = this.props

                console.log(user)

                let query = {
                    ...request,
                    expireDate: moment(request.expireDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
                    price,
                    bookId:staffId,
                    userId:user.id
                }
                packagesApi.buyPackages(query).then(result => {
                   if (result.data.code != 200) return
                   const { successed } =this.props;
                   successed()
                }).catch(error => {
                    this.MessageUtice('error',error.userMessage)
                });
            } else {
                return false;
            }
        });
    }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    async componentDidMount() {
        const staffs = await staffApi.getStaffAll();
        const packages = await packagesApi.packagesAll()

        this.setState({
            staffs,
            packages
        })
    }

    render(){
        const { isClosed } = this.props
        const { request, staffs,packages, visible, price,rules } = this.state
        return (
            <main>
                 <Dialog title="次卡套餐购买" visible={ visible } onCancel={ isClosed } lockScroll={ false }>
                    <div className='add-packages-main'>
                        <Form ref="form" model={request} labelWidth="80" labelPosition='left' rules={rules}>
                            <Layout.Row className='service-times-main' gutter="20">
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="選擇套餐" prop="packageId">
                                        <Select value={request.packageId} placeholder="请选择" style={{width:"100%"}}  onChange={this.onChange.bind(this, 'packageId')}>
                                            {
                                                packages.length > 0 &&  packages.map(item => {
                                                    return <Select.Option key={item.id} label={item.name} value={item.id} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="价格">
                                        <Input value={price} disabled></Input>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="主销售" prop="mainSales">
                                        <Select value={request.mainSales} placeholder="请选择" style={{width:"100%"}}  onChange={this.onChange.bind(this, 'mainSales')}>
                                            {
                                                staffs.length > 0 &&  staffs.map(staff => {
                                                    return <Select.Option key={staff.id} label={staff.staff_details[0].name} value={staff.id} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="副销售" prop="minorSales">
                                        <Select value={request.minorSales} placeholder="请选择" style={{width:"100%"}}  onChange={this.onChange.bind(this, 'minorSales')}>
                                            {
                                                staffs.length > 0 &&  staffs.map(staff => {
                                                    return <Select.Option key={staff.id} label={staff.staff_details[0].name} value={staff.id} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="到期日期" prop="expireDate">
                                        <DatePicker value={request.expireDate} onChange={this.onChange.bind(this, 'expireDate')}  placeholder="选择日期" disabledDate={time=>time.getTime() < Date.now() - 8.64e7}/>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="支付方式" prop="payment">
                                        <Select value={request.payment} onChange={this.onChange.bind(this,'payment')} placeholder="請選擇">
                                            {
                                                this.state.payTypeOptions.map(el => {
                                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row className='icon-main'>
                                <Layout.Col lg="24" md="24" sm="24">
                                    <Button type="primary" onClick={this.handleSubmit.bind(this)}>提交</Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form>
                    </div>
                 </Dialog>
            </main>
        )
    }
}
