import client from '../apiClient';

export async function getProductByServiceId(serviceId) {
    let response = await client.get('/productServiceRel/selectByServiceId',{
        params:{
            serviceId
        }
    });
    return response;
}

export async function createProductByServiceId(data) {
    let response = await client.post('/productServiceRel/createRel',data);
    return response;
}