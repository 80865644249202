import React, { Component } from 'react';
import { translate } from 'react-i18next';
import '../record.scss'
import { Button, Select, Form, Input , DatePicker, Col, Row, Typography, Table } from 'antd';
import moment from 'moment';
import { removeEmptyFiled } from '../../../../utils/removeEmptyFiled'
import * as reportApi from "../../../../api/report.api";

const { RangePicker } = DatePicker;

@translate()
export default class ServiceSummary extends Component {
    formRef = React.createRef();
    state = {
        search:{
            date:[moment(new Date()),moment(new Date())]
        },
        result:[],
        loading : false,
        percent:0,
        columns:[
            {
                title:"名稱",
                dataIndex: 'serviceName',
            },
            {
                title:"單價",
                dataIndex: 'price',
                sorter:{
                    compare: (a, b) => a.price - b.price,
                },
            },
            {
                title:"單數",
                dataIndex: 'times',
                sorter:{
                    compare: (a, b) => a.times - b.times,
                },
            },
            {
                title:"業績",
                dataIndex: 'total',
                sorter:{
                    compare: (a, b) => a.total - b.total,
                },
            },
            {
                title:"實際業績",
                dataIndex: 'amountAfterDiscount',
                sorter:{
                    compare: (a, b) => a.amountAfterDiscount - b.amountAfterDiscount,
                },
            },
            {
                title:"業績占比",
                render:(_,row) => {
                    return (
                        <div>{((row.times/this.state.percent) * 100 || 0).toFixed(2)} %</div>
                    ) 
                },
                sorter:{
                    compare: (a, b) => a.times/this.state.percent - b.times/this.state.percent,
                },
            },
            {
                title:"師傅占比",
                render:(_,row) => {
                    return (
                        <div>
                            {
                                row.data.map(item => {
                                    return <p> {item.staffSurName}{item.staffName} : {((parseFloat(item.times)/ parseFloat(row.times) * 100) || 0).toFixed(2)} %</p>
                                })
                            }
                        </div>
                    ) 
                }
            },
        ]
    }

    getData(){
        this.setState({loading:true})

        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
        }

        if (data.startTime && data.endTime) {
            query.startTime = moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            query.endTime = moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        reportApi.serviceSummary(query).then(result => {
            const { data:{ data } } = result
            if (data.length == 0) return
            let percent = data.reduce((pre,current) => {
                return pre + parseFloat(current.times)
            },0)
            this.setState({
                result:data,
                percent
            })
        }).finally(()=>{
            this.setState({loading:false})
        })
    };

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 

        delete query.date

        return removeEmptyFiled(query)
    }

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    componentDidMount(){
        this.getData()
    }

    render() {
        const { loading,result,columns } = this.state
        return (
            <main className='report-contanier'>
                <section className='search-contanier'>
                    <Form ref={this.formRef} initialValues={{ date:[moment(new Date()),moment(new Date())] }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                        <Row gutter={20}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <Form.Item name="date" label="查詢日期">
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        查詢
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </section>

                <section className='table-contanier'>
                    <div className='table-main'>
                        <Table tableLayout="auto" scroll={{x:true,y:true}} columns={columns} dataSource={result} pagination={false} loading={loading} bordered={true}></Table>
                    </div>
                </section>
            </main>
        )
    }
}