import {translate} from "react-i18next";
import React, {Component} from "react";
import {Button, Dialog, Form, Input, Layout , Message} from "element-react";
import AccountIcon from "../../../../components/icon.component"
import fileDialog from "file-dialog";
import "../product-list/add-product-dialog.scss";
import * as productApi from '../../../../api/product.api'
import {connect} from "react-redux";
import * as XLSX from 'xlsx';

@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class AddProductDialog extends Component {
    state = {
        visible : true,
        icon: null,
        localIcon: null,
        loading: false,
        rules : {
            name: [
                { required: true, message: '请输入产品名称', trigger: 'blur' },
            ],
        },
        formData: {
            products:[
                {
                    name:"",
                    price: "",
                    repertory: "",
                    icon: null,
                    echoIcon:null,
                }
            ]
        }
    }

    onChange(index , key, value) {
        this.state.formData.products[index][key] = value;
        this.forceUpdate();
    }

    async uploadIcon(index) {
        const { products } = this.state.formData
        products[index].icon = (await fileDialog({accept: 'image/*'}))[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            products[index].echoIcon = e.target.result
            this.setState({formData:Object.assign({},this.state.formData,{products})})
        }
        reader.readAsDataURL(products[index].icon)
    }

    addDomain(e){
        e.preventDefault();
        this.state.formData.products.push({
            name: null,
            price: null,
            repertory: null,
            icon: null,
        });
        this.forceUpdate()
    }

    removeDomain(item, e) {
        e.preventDefault();
        if (this.state.formData.products.length <=1 ) return
        var index = this.state.formData.products.indexOf(item);
        if (index !== -1) {
            this.state.formData.products.splice(index, 1);
            this.forceUpdate();
        }
    }

    commit(e) {
        e.preventDefault();
        this.refs.form.validate((valid) => {
            if (!valid) {
                return false
            }
            this.state.loading = true
            const { staffId , success, productDetail } = this.props
            let request = {
                createdBy : staffId,
            }

            let apiGet = productApi.createProducts
            if (!productDetail) {
                request.products = this.state.formData.products
            } else {
                let productUpdate = this.state.formData.products[0]
                request = {...productUpdate,...request}
                request.icon = request.echoIcon
                apiGet = productApi.updateProduct
            }

            apiGet(request).then(result => {
                const { code } = result.data
                if ( code != 200 ) return
                success()
            }).catch(err => {
                Message.error(err)
            })

        })
    }

    componentDidMount(){
        const { productDetail } = this.props
        if (productDetail) {
            this.state.formData.products.splice(0,1,{...productDetail})
            this.forceUpdate();
        }
    }

    render() {
        const {isClosed,productDetail } =this.props;
        const {formData , visible , loading} = this.state
        const { products } = formData
        return (
            <main className='add-product-dialog-main'>
                <Dialog title='新增产品' visible={ visible } onCancel={ isClosed} >
                    <div className='product-container'>
                        <Form ref='form' model={formData} >
                            {
                                products.map((product , index) => {
                                    return (
                                        <Layout.Row key={index} gutter={20}>
                                            <Layout.Col lg='24' md='24' sm='24'>
                                                <Form.Item prop={`products:${index}`} style={{textAlign:"center"}}>
                                                    <div className='avatar'>
                                                        <AccountIcon className="icon" icon={product.echoIcon} onClick={() => this.uploadIcon(index)} />
                                                    </div>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="产品名称" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        name: { type: 'string', required: true, message: '产品名称不能为空', trigger: 'change' }
                                                    }
                                                }}>
                                                    <Input value={product.name} placeholder="请输入产品名称" onChange={(value) => this.onChange(index,"name",value)}/>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="产品单价" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        price: [{ required: true, message: '产品单价不能为空', trigger: 'blur' },
                                                            { validator: (rule, value, callback) => {
                                                                var times = parseInt(value, 10);
                                                                if (!Number.isInteger(times)) {
                                                                    callback(new Error('请输入数字值'));
                                                                } else if (times <= 0 ) {
                                                                    callback(new Error('请输入大于0的数值'));
                                                                } else {
                                                                    callback();
                                                                }
                                                            },
                                                            trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={product.price} placeholder="请输入单价" onChange={(value) => this.onChange(index,"price",value)}/>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="产品成本" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        cost: [{ required: true, message: '产品成本不能为空', trigger: 'blur' },
                                                            { validator: (rule, value, callback) => {
                                                                var times = parseInt(value, 10);
                                                                if (!Number.isInteger(times)) {
                                                                    callback(new Error('请输入数字值'));
                                                                } else if (times <= 0 ) {
                                                                    callback(new Error('请输入大于0的数值'));
                                                                } else {
                                                                    callback();
                                                                }
                                                            },
                                                            trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={product.cost} placeholder="请输入成本" onChange={(value) => this.onChange(index,"cost",value)}/>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg='12' md='12' sm='24'>
                                                <Form.Item label="库存数量" prop={`products:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        repertory: [
                                                            { required: true, message: '库存数量不能为空', trigger: 'blur' },
                                                            { validator: (rule, value, callback) => {
                                                                var times = parseInt(value, 10);
                                                                if (!Number.isInteger(times)) {
                                                                    callback(new Error('请输入数字值'));
                                                                } else if (times <= 0 ) {
                                                                    callback(new Error('请输入大于0的数值'));
                                                                } else {
                                                                    callback();
                                                                }
                                                            },
                                                            trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={product.repertory} placeholder="请输入库存数量" onChange={(value) => this.onChange(index,"repertory",value)}/>
                                                </Form.Item>
                                            </Layout.Col>
                                            {
                                                !productDetail ? <Layout.Col lg="24" md="24" sm="24">
                                                <Form.Item style={{textAlign:"right"}}>
                                                    <Button onClick={this.removeDomain.bind(this, product)}>删除</Button>
                                                </Form.Item> </Layout.Col>: null
                                            }
                                        </Layout.Row>
                                    )
                                })
                            }
                            <Layout.Row className='icon-main'>
                                <Layout.Col lg="24" md="24" sm="24">
                                    {
                                        !productDetail ? <Button onClick={this.addDomain.bind(this)}>增加</Button> :null
                                    } 
                                    <Button type="primary" loading={loading} onClick={this.commit.bind(this)}>提交</Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form>
                    </div>
                </Dialog>
            </main>
        )
    }
}