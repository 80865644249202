import React, { Component } from 'react';
import { jsPDF } from "jspdf";
import moment from 'moment';
import {  Layout } from 'element-react';
import html2canvas from 'html2canvas'
import { translate } from 'react-i18next';
import "jspdf-autotable";
import logo from '../../../../assets/MIUSEE_Beauty_logo.png';
import './pdf.scss'


@translate()
class Table extends Component {
    state = {
        columns: [
            {
                label: "購買明細",
                prop: "service",
            },
            {
                label: "單價",
                prop: "price",
            },
            {
                label: "數量",
                prop: "amount"
            },
            {
                label: "折減金額",
                prop: "discount"
            },
            {
                label: "交易金額",
                prop: "dealPrice"
            }
        ]
    }

    toThousands(num) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    }

    calcAoumt(type){
        const { content } = this.props
        let total = 0
        if(content.length > 1) {
            total = content.reduce(( previous, current ) =>  {
                return parseFloat(previous[type]) + parseFloat(current[type])
            })
        }else {
            total = content[0][type]
        }
        
        return this.toThousands(total) 
    }

    render() {
        const { columns } = this.state
        const { content,t,payment } = this.props
        let pays = {}
        payment.forEach(pay => {
            pays[pay.payment] = pay.payment || ''
        })
        return (
            <table className='table'>
                <thead className='thead-content'>
                    <tr style={{display:"flex",justifyContent:"space-between",width:"100%",padding:"0 0 10px 0",borderBottom: "1px solid rgb(26, 26, 26)"}}>
                        {
                            columns.map((item, index) => {
                                return (
                                    item.prop == 'service' ? <th key={index} style={{flex:"42%"}}>{item.label}</th> :
                                    <th key={index} style={{flex:"1"}}>{item.label}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody className='tbody-content'>
                    { content && content.length > 0 && content.map((element, key) => {
                        return (
                                <tr key={key} className='tr-content' style={{display:"flex",justifyContent:"space-between",width:"100%",marginBottom:"15px"}}>
                                    {columns.map((item, index) => {
                                        return (
                                            item.prop == 'service' ? <td key={index} style={{flex:"42%"}}> { key + 1 + ' ' + '.)'  + ' ' + element[item.prop] } </td> : item.prop != 'amount' ? <th key={index} style={{flex:"1"}}>$ { this.toThousands(element[item.prop]) }</th> : <td key={index} style={{flex:"1"}}> { element[item.prop] }</td> 
                                        )
                                    })}
                                </tr>
                        )
                    })}
                </tbody>
                <tfoot className='tfoot-content'>
                    <tr style={{display:"flex",justifyContent:"space-between",width:"100%",borderTop: "1px solid rgb(26, 26, 26)",padding:"10px 0 0 0",marginBottom:"10px"}}>
                        
                        <td>交易途徑(如適用) : 
                            {/* {JSON.stringify(payment)} */}
                            {
                                Object.keys(pays).length > 0 &&  Object.keys(pays).map((pay,index) => {
                                    return (
                                        <span>{pays[pay]}{index == Object.keys(pays).length -1 ? null : "、" }</span>
                                    )
                                })
                            }
                        </td>
                        <td>已減金額 : $ {this.calcAoumt('discount')}</td>
                    </tr>
                    <tr style={{display:"flex",justifyContent:"end",width:"100%"}}>
                        <td>最終交易金額 : $ {this.calcAoumt('dealPrice')}</td>
                    </tr>
                </tfoot>
            </table>
        )
    }
}

@translate()
class PdfMain extends Component {
    state = {
        pdfs: [0, 1],
        date : new Date(),
        show:false
    }
    render() {
        const { content } = this.props;
        
        let pdfTop = {
            id : 'MB' + moment(content.date).format('YYYYMMDD')+moment(new Date()).format('HHmmss'),
            center: content.staff_schedule.center.center_details[0].name,
            date: content.date,
            userId: content.userId ? content.userId : '非會員用戶',
            name: content.userId ? content.user.surname + '' + content.user.name : content.user.name,
            staff: content.staff_schedule.staff.staff_details[0].surname + '' + content.staff_schedule.staff.staff_details[0].name,
            datetime : content.staff_schedule.startTime
        }

        const { date } = this.state 

        return (
            this.state.pdfs.map((_, index) => {
                return (
                    <div className='pdf-main' key={index}>
                        <div>
                            <div className='pdf-top'>
                                <img className="pdf-logo" src={logo} alt="" />
                            </div>
                            <div className='pdf-main-header'>
                                <Layout.Row>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>店铺 : {pdfTop.center}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}} >收據編號 : {pdfTop.id}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>會員編號(如適用) : {pdfTop.userId}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}}>日期 : {pdfTop.date}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>客戶名稱 : {pdfTop.name}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}}>時間 : {moment(date).format('HH:mm A')}</p>
                                    </Layout.Col>
                                </Layout.Row>
                            </div>
                            <div className='pdf-main-content'>
                                <Table
                                    style={{ width: '100%' }}
                                    columns={this.state.columns}
                                    content={content.origin}
                                    payment={content.payme}
                                />
                            </div>
                            
                            <div className='tokens'>
                                <div className='token'>
                                    客戶簽名 : &nbsp; <span style={{flex : "1",borderBottom: "1px solid rgb(26, 26, 26)"}}></span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='conditions'>
                            <p>條款細則</p>
                            <ul>
                                <li>1. 必須年滿18歲</li>
                                { content.show ? 
                                <div>
                                    <li>2. 本公司所有半永久定妝項目6個月內免費贈送補色一次(特價項目除外)，逾期無效。</li>
                                <li>3. 首次療程日期起12個月之內補色費用為$1000元一次。</li>
                                <li>4. 本公司補色服務不設指定塑眉師，如需指定，需付款500至1000元指定費用(視乎塑眉師而定)。<br/>
                                注 : 如補色期間，所指定塑眉師的工作有特殊的變動，導致無法安排同一位塑眉師為你跟進，本公司將會免費安排其他相同年資的塑眉師為您跟進
                                </li>
                                <li>5. 任何服務之預約如需更改，必須提前72小時更改(僅限一次)，臨時更改預約，訂金將不獲任何退款及補償。補色服務的預約不會接受任何更改，如需更改，將視為放棄服務處理。</li>
                                <li>6. 購買的療程為特價項目，不設退換服務</li>
                                </div> : null }
                            </ul>
                        </div>
                    </div>
                )
            })
        )
    }
}
@translate()
export default class extends Component {
    state = {
        src: ''
    }

    px2mm(px) {
        var ratio = 0;
        var div = document.createElement('div')
        div.style.width = '1cm'
        div.id = 'puc'
        document.body.appendChild(div)
        var w = getComputedStyle(div, null).width
        ratio = w.substr(0, w.length - 2)
        div.parentNode.removeChild(div)

        var r = ratio / 10
        return Math.round(px / r)
    }

    dowloadPDF(pdfDatas) {
        pdfDatas.forEach((item, index) => {
            // this.refs['exportPDF'+index]
            const element = document.getElementById(`exportPDF${index}`);  // 这个dom元素是要导出的pdf的div容器
            const w = element.offsetWidth;  // 获得该容器的宽
            const h = element.offsetHeight;  // 获得该容器的高
            const offsetTop = element.offsetTop; // 获得该容器到文档顶部的距离  
            const offsetLeft = element.offsetLeft; // 获得该容器到文档最左的距离
            const canvas = document.createElement("canvas")
            let abs = 0;
            const win_i = document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
            const win_o = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
            if(win_o > win_i){
            abs = (win_o - win_i) / 2; // 获得滚动条宽度的一半
            }
            canvas.width = w * 2; // 将画布宽&&高放大两倍
            canvas.height = h * 2;
            const context = canvas.getContext('2d');
            context.scale(2, 2);
            context.translate(-offsetLeft - abs, -offsetTop);
            html2canvas(element, {
                // windowWidth: "297mm",
                // windowHeight: "210mm",
                allowTaint: true,
                scale: 2 // 提升画面质量，但是会增加文件大小
            }).then((canvas) => {
                const contentWidth = canvas.width;
                const contentHeight = canvas.height;

                console.log(contentHeight)
                // 一页pdf显示html页面生成的canvas高度
                const pageHeight = this.px2mm(contentHeight / 2);
                // 未生成pdf的html页面高度
                let leftHeight = contentHeight;
                // 页面偏移
                let position = 0;
                // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                const imgWidth = 297;
                const imgHeight = this.px2mm(contentHeight / 2)
                
                const pageDate = canvas.toDataURL('image/jpeg', 1.0);
                // 通过 html2canvas 将 html 渲染成 canvas，然后获取图片数据
                // var imgData = canvas.toDataURL('image/jpeg')


                this.setState({
                    src: pageDate
                })
                // 设置pdf 格式
                var doc = new jsPDF({
                    orientation: "landscape",
                    unit: 'mm',
                    format: 'a4',
                })

                doc.setFontSize(10)
                // 设置 a4 纸尺寸
                // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面的高度（841.89）
                // 当内容未超过pdf一页显示的范围，无需分页
                if(leftHeight < pageHeight) {
                    doc.addImage(pageDate,'JPEG', 0, position, imgWidth, imgHeight);
                }else { // 分页
                    while (leftHeight > 0){
                        doc.addImage(pageDate, 'JPEG', 0, position, imgWidth, imgHeight) 
                        leftHeight -= pageHeight;
                        position -= 210;
                        // 避免添加空白页
                        if(leftHeight > 0){
                            doc.addPage()
                        }
                    }
                }
                // doc.addImage(imgData, 'jpeg', 0, 0, 297, 210)
                const date = Date().split(" ");

                const userId = item.userId && item.userId !== '' ? item.user.surname + '' + item.user.name : item.user.name

                // we use a date string to generate our filename.
                const dateStr = date[1] + date[2] + date[3] + date[4];
                // ticket title. and margin-top + margin-left
                // we define the name of our PDF file.
                doc.save(`${userId}_report_${dateStr}.pdf`);
            })
        })
    }

    async componentDidMount() {
        const { pdfDatas } = this.props
        if (pdfDatas && pdfDatas.length > 0) {
            await this.dowloadPDF(pdfDatas)
            await setTimeout(() => {
                this.props.closedPDF()
            })
        }
    }
    render() {
        const { pdfDatas, t } = this.props

        const { src } = this.state

        const pdfContanier = pdfDatas.map((item, index) => {
            return (
                <section id={`exportPDF${index}`} ref={`exportPDF${index}`} key={index} className='exprot-pdf'>
                    <div className='pdf-contanier'>
                        <PdfMain content={item} src={src} t={t}></PdfMain>
                    </div>
                </section>
            )
        })
        return (
            <main>
                {pdfContanier}
            </main>
        )
    }
}

