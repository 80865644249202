import React, { Component } from 'react';
import './staff-detail.scss';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as staffApi from "../../../../api/staff.api";
import * as permissionApi from "../../../../api/permission.api";
import * as serviceApi from "../../../../api/service.api";
import * as centerApi from "../../../../api/center.api";
import { Route, withRouter } from 'react-router-dom';
import history from '../../../../history';
import fileDialog from "file-dialog";
import { Tabs, Button, Message, MessageBox } from 'element-react';
import StaffBasicInfoViewTab from "../../../../components/staff/tab-basic-info-view/tab-basic-info-view.component";
import StaffServiceDetailViewTab from "../../../../components/staff/tab-service-detail-view/tab-service-detail-view.component";
import StaffCMSPermissionViewTab from "../../../../components/staff/tab-cms-permission-view/tab-cms-permission-view.component";
import StaffCSalaryViewTab from "../../../../components/staff/tab-salary-view/tab-salary-view.component";
import StaffBasicInfoEditTab from "../../../../components/staff/tab-basic-info-edit/tab-basic-info-edit.component";
import StaffServiceDetailEditTab from "../../../../components/staff/tab-service-detail-edit/tab-service-detail-edit.component";
import StaffCMSPermissionEditTab from "../../../../components/staff/tab-cms-permission-edit/tab-cms-permission-edit.component";
@withRouter
@translate()
@connect((state) => {
    return {
        cmsPermissions: state.staff.permissions,
    };
})
export default class StaffDetailPage extends Component {
    state = {
        edit: false,
        staff: null,
        staffUpdate: null,
        icon: null,
        localIcon: null,
        permissions: null,
        staffPermissions: null,
        centers: null,
        services: null,
        selectedTab: "",
    }

    async componentDidMount() {
        if (this.props.match.params.staffId !== "create") {
            await this.getData(this.props.match.params.staffId);
        } else {
            await this.getData(null);
            this.setState({ edit: true });
        }
    }

    switchTab = (url) => {
        history.push(url);
    }

    async getData(staffId) {
        const staff = staffId ? await staffApi.getStaff(staffId) : null;
        const serviceResponse = await serviceApi.getServices(1, "id", "ASC", 999, "");
        const centerResponse = await centerApi.getCenters();
        const permissionResponse = await permissionApi.getPermissions();
        this.setState({
            staff,
            centers: centerResponse.map((center) => {
                let selected = false;
                if (staff && staff.id) {
                    for (let i = 0; i < staff.centers.length; i++) {
                        if (staff.centers[i].id === center.id) {
                            selected = true;
                            break;
                        }
                    }
                } else {
                    selected = true
                }
                const newCenter = {
                    selected,
                    ...center,
                }
                return newCenter;
            }),
            services: serviceResponse.rows.map((service,index) => {
                let selected = false;
                let extraFee = 0;
                let commission = 0;
                let fixedCommission = 0
                if (staff && staff.id) {
                    for (let i = 0; i < staff.services.length; i++) {
                        if (staff.services[i].id === service.id) {
                            selected = true;
                            extraFee = staff.services[i].extraFee;
                            commission = staff.services[i].commission;
                            fixedCommission = staff.services[i].fixedCommission;
                            break;
                        }
                    }
                }
                const newService = {
                    key:`${index+1}`,
                    extraFee,
                    commission,
                    selected,
                    fixedCommission,
                    ...service,
                }
                return newService;
            }),
            permissions: permissionResponse,
            staffPermissions: staff ? staff.permissions : {},
        }, () => {
            console.log('staff', this.state.staff);
            console.log('services', this.state.services);
        });
    }

    async changeMode(staffId) {
        await this.getData(staffId);
        this.setState({
            edit: !this.state.edit,
        });
    }

    updateStaff(title, value) {
        if ((title == 'mainSales' && value) || (title == 'minorSales' && value) ) {
            value = parseFloat((value.match(/^\d*(\.?\d{0,2})/g)[0]))
            if (value > 100) value = 100
        }
        const newStaffUpdate = { ...this.state.staffUpdate };
        newStaffUpdate[title] = value;
        const newStaff = { ...this.state.staff };
        newStaff[title] = value;
        this.setState({
            staffUpdate: newStaffUpdate,
            staff: newStaff,
        }, () => {
            console.log('staffUpdate:', this.state.staffUpdate)
        });
    }

    updateStaffDetail(languageId, title, value) {
        const newStaffUpdate = { ...this.state.staffUpdate };
        newStaffUpdate.details = newStaffUpdate.details || [];
        let newDetail = { languageId, value: {} };
        let detailIndex = -1;
        for (let i = 0; i < newStaffUpdate.details.length; i++) {
            const detail = newStaffUpdate.details[i];
            if (detail.languageId === languageId) {
                newDetail = { ...detail };
                detailIndex = i;
                break;
            }
        }
        newDetail.value[title] = value;

        if (detailIndex === -1) {
            newStaffUpdate.details.push(newDetail);
        } else {
            newStaffUpdate.details[detailIndex] = newDetail;
        }
        const newStaff = { ...this.state.staff };
        if (!newStaff.details) {
            newStaff["details"] = {};
        }
        if (!newStaff.details[languageId]) {
            newStaff.details[languageId] = {};
        }
        newStaff.details[languageId][title] = value;
        this.setState({
            staffUpdate: newStaffUpdate,
            staff: newStaff,
        });
    }

    async uploadIcon() {
        this.setState({ icon: (await fileDialog({ accept: 'image/*' }))[0] },
            () => {
                if (this.state.icon) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({ localIcon: e.target.result });
                    }
                    reader.readAsDataURL(this.state.icon);
                }
            }
        );
    }

    updateStaffCenter(index) {
        const newCenters = this.state.centers;
        newCenters[index].selected = !newCenters[index].selected;
        this.setState({
            centers: newCenters,
        });
    }


    updateStaffService(id, extraFee = null, commission = null, fixedCommission = null) {
        const newServices = this.state.services;

        console.log(id)

        let index = newServices.findIndex(service => id == service.id )

        if (index >= 0) {
            if (commission !== null) {
                newServices[index].commission = commission
            } else if (extraFee !== null) {
                newServices[index].extraFee = extraFee;
            } else if (fixedCommission != null) {
                newServices[index].fixedCommission = fixedCommission
            } else {
                newServices[index].selected = !newServices[index].selected;
            }
            this.setState({
                services: newServices,
            });
        }   
    }

    async updatePermission(id, right) {
        const newStaffPermission = { ...this.state.staffPermissions };
        if (right) {
            newStaffPermission[id] = right;
        } else {
            delete newStaffPermission[id];
        }
        this.setState({
            staffPermissions: newStaffPermission,
        });
    }

    async submit() {
        const { staff, staffUpdate, icon, centers, services, staffPermissions } = this.state;
        let staffId;

        if (staffUpdate) {
            Object.keys(staffUpdate).forEach((item) => {
                const key = staffUpdate[item];
                if (key === "" || key === null || key === undefined) {
                    delete staffUpdate[item];
                }
            });
        } 
        if (staff && staff.id) {
            staffId = staff.id;
            await staffApi.updateStaff(staff.id, staffUpdate);
        } else {
            await staffApi.createStaff(staffUpdate).then(result => {
                staffId = result.data.id;
            }).catch(error => {
                MessageBox.alert(error.userMessage);
            })
        }
        if (icon) {
            await staffApi.uploadIcon(staffId, icon);
        }
        let newServices = [];
        for (let i = 0; i < services.length; i += 1) {
            if (services[i].selected) {
                newServices.push({
                    id: services[i].id,
                    extraFee: services[i].extraFee,
                    commission: services[i].commission,
                    fixedCommission: services[i].fixedCommission
                });
            }
        }
        if (newServices.length) {
            console.log('newServices', newServices);
            await staffApi.assignService(staffId, newServices);
        }

        let newCenters = [];
        console.log(centers)
        for (let i = 0; i < centers.length; i += 1) {
            if (centers[i].selected) {
                newCenters.push(centers[i].id);
            }
        }
        if (newCenters.length) {
            console.log('newCenters', newCenters);
            await staffApi.assignCenter(staffId, newCenters);
        }
        if (staffPermissions) {
            const newPermissions = [];
            for (let id of Object.keys(staffPermissions)) {
                const newData = {
                    id,
                    right: staffPermissions[id],
                }
                newPermissions.push(newData);
            }
            await staffApi.assignPermission(staffId, newPermissions);
        }
        this.setState({
            staffUpdate: null,
        });
        if (staff.id) {
            this.changeMode(staffId)
        } else {
            this.switchTab(`/main/staff-detail/${staffId}`);
            this.changeMode(staffId)
        }
    }

    render() {
        const { t, cmsPermissions } = this.props;
        const { staff, permissions, services, centers, edit, selectedTab, localIcon, staffPermissions } = this.state;
        return (
            <div className="staff-detail-page" >
                <div className="main-title">
                    {t('staff_detail_title')}
                </div>
                <div className="page-go-back"><Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button></div>
                <div className="tab">
                    <Tabs onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })} type="card" value={selectedTab}>
                        <Tabs.Pane label={t('staff_detail_tab_basic_info')} name="1">{
                            edit ? <StaffBasicInfoEditTab staff={staff} changeToEdit={(staffId) => this.changeToEdit(staffId)} changeMode={(staffId) => this.changeMode(staffId)}
                                updateStaff={(title, value) => this.updateStaff(title, value)}
                                updateStaffDetail={(languageId, title, value) => this.updateStaffDetail(languageId, title, value)}
                                uploadIcon={() => this.uploadIcon()}
                                submit={() => this.submit()}
                                localIcon={localIcon}
                            /> : (staff ? <StaffBasicInfoViewTab staff={staff} /> : null)
                        }</Tabs.Pane>
                        {staff ? <Tabs.Pane label={t('staff_detail_tab_service_detail')} name="2">{
                            edit ? <StaffServiceDetailEditTab staff={staff} centers={centers} services={services} changeMode={(staffId) => this.changeMode(staffId)}
                                updateStaffCenter={(index) => this.updateStaffCenter(index)}
                                updateStaffService={(index, extraFee, commission, fixedCommission) => this.updateStaffService(index, extraFee, commission, fixedCommission)}
                                submit={() => this.submit()}
                            /> : <StaffServiceDetailViewTab staff={staff} />
                        }</Tabs.Pane> : null}
                        {staff && cmsPermissions.permission ? <Tabs.Pane label={t('staff_detail_cms_permission')} name="3">{
                            edit ? <StaffCMSPermissionEditTab staff={staff} permissions={permissions} changeMode={(staffId) => this.changeMode(staffId)}
                                staffPermissions={staffPermissions}
                                updatePermission={(id, right) => this.updatePermission(id, right)}
                                submit={() => this.submit()}
                            /> : <StaffCMSPermissionViewTab permissions={staff.permissions} />
                        }</Tabs.Pane> : null}
                        {staff ? <Tabs.Pane label={t('staff_detail_tab_salary_preview')} name="4">
                            <StaffCSalaryViewTab staff={staff} switchTab={(url) => this.switchTab(url)} />
                        </Tabs.Pane> : null}
                    </Tabs>
                </div>
                <div className="page-footer">
                    {staff && !edit && selectedTab !== '4' ? <Button onClick={() => this.setState({ edit: !edit })}>{t('button_edit')}</Button> : null}
                </div>
            </div >
        );
    }
}