import {translate} from "react-i18next";
import React, {Component} from "react";
import {Dialog, Form, Input, Layout , Message , Select , DatePicker} from "element-react";
import AccountIcon from "../../../../components/icon.component"
import fileDialog from "file-dialog";
import "../product-list/storage-dialog.scss";
import * as productApi from '../../../../api/product.api'
import {connect} from "react-redux";
import {Button} from 'antd'
import * as productRepertoryRecordApi from '../../../../api/productRepertoryRecord'
@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class StorageDialog extends Component {
    state = {
        visible : true,
        icon: null,
        localIcon: null,
        loading: false,
        rules : {
            name: [
                { required: true, message: '请输入产品名称', trigger: 'blur' },
            ],
        },
        formData: {
            type: 'in',
            name: this.props.product.name,
            productId: this.props.product.id,
            date : new Date(),
        }
    }

    onChange(key, value) {
        this.setState({
            formData: Object.assign({}, this.state.formData, {[key]: value})
        });
    }

    commit(e) {
        e.preventDefault();
        this.refs.form.validate((valid) => {
            if (!valid) {
                return false
            }
            this.state.loading = true
            const { staffId , success } = this.props
            const { price , count} = this.state.formData
            let request = {
                ...this.state.formData,
                cost :  parseFloat(price) * parseFloat(count),
                createdBy: staffId
            }

            productRepertoryRecordApi.storage(request).then(result => {
                const { code } = result.data
                if ( code != 200 ) return
                success()
            }).catch(err => {
                Message.error(err)
            })

        })
    }

    render() {
        const {isClosed , success } =this.props;
        const {formData , visible , loading} = this.state
        const { date } = formData
        return (
            <main className='storage-main'>
                <Dialog title='入库' visible={ visible } onCancel={ isClosed} >
                    <div className='storage-container'>
                        <Form ref='form' model={formData} labelPosition="left" labelWidth='80px'>
                            <Layout.Row gutter={20}>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='产品名称'>
                                        <Input value={formData.name} disabled={true}></Input>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='类型'>
                                        <Select value={formData.type} disabled={true}>
                                            <Select.Option value='in' label={this.props.t('storage')}></Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='数量'>
                                        <Input value={formData.count} onChange={this.onChange.bind(this, 'count')}></Input>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='产品单价'>
                                        <Input value={formData.price} onChange={this.onChange.bind(this, 'price')}></Input>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='成本'>
                                        {formData.count * formData.price || 0.00}
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg='24' md='24' sm='24'>
                                    <Form.Item label='日期'>
                                        <DatePicker value={date} placeholder="选择日期" onChange={this.onChange.bind(this, 'date')}/>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row className='icon-main'>
                                <Layout.Col lg="24" md="24" sm="24">
                                    <Button>取消</Button>
                                    <Button style={{marginLeft: '10px'}} loading={loading} onClick={this.commit.bind(this)}>提交</Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form>
                    </div>
                </Dialog>
            </main>
        )
    }
}