import React, { Component } from 'react';
import moment from 'moment';
import './tab-service-detail-view.scss';
import { translate } from 'react-i18next';
import { baseURL } from "../../../apiClient";
import PageTable from "../../page-table.component";
import { Table,Col, Row } from 'antd';

@translate()
export default class StaffServiceDetailViewTab extends Component {
    state={
        columns :[
            {
                title: this.props.t('staff_tab_service_detail_title_project'),
                dataIndex: "name",
            },
            {
                title: this.props.t('staff_tab_service_detail_title_price'),
                dataIndex: "extraFee",
            },
            {
                title: "百分比提成",
                dataIndex: "commission",
            },
            {
                title: "固定提成",
                dataIndex: "fixedCommission",
            }
        ],
        services:[]
    }

    componentDidMount(){
        const { staff } = this.props;
        let data = staff.services.map((item,index) =>  
        { 
           let data = { key:`${index+1}`,...item } 
           return data
        })
        this.setState({ services:data})
    }

    render() {
        const { t, staff } = this.props;
        const { columns,services} = this.state
        console.log("staff.services",services)
        return (
            <div className="service-detail-tab">
                <Row gutter={16}>
                    <Col span={6} className="col-span">
                        {t('staff_tab_service_detail_title_center')}
                    </Col>
                    <Col span={18} className="col-span">
                        {
                            staff.centers.map((center, i) => {
                                return (
                                    <div key={i}>
                                        {center.name}
                                    </div>
                                );
                            })
                        }
                    </Col>
                    <Col span={6} className="col-span">
                        {t('staff_tab_service_detail_title_service_type')}
                    </Col>
                    <Col span={18} className="col-span">
                        <Table columns={columns} pagination={false} total={services.length} dataSource={services} bordered/>
                    </Col>
                </Row>
            </div>
        );
    }
}