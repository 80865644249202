import React, { Component } from 'react';
import './bookingTable.scss';
import * as reservationApi from "../../../../api/reservation.api";
import * as serviceApi from "../../../../api/service.api";
import * as serviceTimesApi from "../../../../api/serviceTimes.api";
import * as staffApi from "../../../../api/staff.api";
import { Button, Table, Dialog, Form, Select, Radio, Input, Layout, Loading, Cascader, DateRangePicker,Message } from 'element-react';
import DatePickerButton from "../../../../components/date-picker-button.component";
import BookingInform from "../../../../components/user/booking-inform/bookingTable.component";
import { translate } from 'react-i18next';
import moment from 'moment';
import "jspdf-autotable";
import PDF from './pdf'
import { ServiceTree } from "../../../../utils/dataTransform";
import BatchConfirmation from "./batchConfirmation"
import CheckInDialog from '../../../../components/user/check-in-dialog/check-In-compoent';
import * as categoryApi from "../../../../api/category.api";
@translate()
export default class UserFeedbackPage extends Component {

    state = {
        ids:[],
        staffs: [],
        calendarLoading:false,
        date: new Date(),
        day: moment().toDate(),
        minTime: moment().startOf('day').add(10, 'hours').toDate(),
        maxTime: moment().startOf('day').add(21, 'hours').toDate(),
        results: [],
        lastTextChange: 0,
        selectedUser: undefined,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        totalResult: 0,
        selectedFeedback: null,
        sort: null,
        selectionChange: [],
        columns: [
            {
                type: 'selection',
            },
            {
                label: this.props.t('booking_table_column_date'),
                prop: "startTime",
                sortable: 'custom',
                render: this.renderDate,
                width: 150,
            },
            {
                label: this.props.t('booking_table_column_time'),
                prop: "startTime",
                sortable: 'custom',
                render: this.renderTime,
                width: 160,
            },
            {
                label: this.props.t('booking_table_column_user'),
                sortable: 'custom',
                prop: "userId",
                render: function (data) {
                    
                    if (data.user) {
                        return (
                            <div>
                                <span>{data.user.surname}</span>
                                <span>{data.user.name}</span>
                            </div>
                        )
                    } else {
                        return (
                            <span>{data.userInfo?data.userInfo.name:''}</span>
                        )
                    }
                }
            },
            {
                label: this.props.t('booking_table_column_phone'),
                width: 200,
                render: function (data) {
                    return JSON.parse(sessionStorage.getItem("permissions")).reservation == 'read'  ? null : data.user ? <span>{data.user.phone}</span> : <span>{data.userInfo.phone}</span>
                }
            },
            {
                label: this.props.t('booking_table_column_staff'),
                // prop: "staff_schedule.staffId",
                render: function (data) {
                    let level = {
                        "normal":"普通",
                        "senior":"資深",
                        "chief":"首席"
                    } 
                    return (
                        <div>
                            <span>{data.staff_schedule && data.staff_schedule.staff ?data.staff_schedule.staff.staff_details[0].surname:''}</span>
                            <span>{data.staff_schedule && data.staff_schedule.staff ?data.staff_schedule.staff.staff_details[0].name:''}</span>
                        </div>
                    )
                }
            },
            {
                label: "星級",
                render: function (data) {
                    let level = {
                        "normal":"普通",
                        "senior":"資深",
                        "chief":"首席"
                    } 
                    return (
                        <div>
                            <span>{level[data.staffLevel] || level["normal"]}</span>
                        </div>
                    )
                }
            },
            {
                label: this.props.t('booking_table_column_server'),
                render: function (data) {
                    return (
                        <span>{data.service?data.service.service_details[0].name:''}</span>
                    )
                }
            },
            {
                label: this.props.t('booking_revise_record_price'),
                prop: "price",
            },
            {
                label: this.props.t('booking_revise_record_discount_type'),
                prop: "type",
                render: (data) => {
                    return (<span>{data.type ? this.props.t('booking_discount_type_' + data.type) : this.props.t('booking_discount_type_normal')}</span>)
                }
            },
            {
                label: this.props.t('booking_revise_record_discount_price'),
                render: function (data) {
                    if (data.type === 'discount') {
                        return (
                            <span>{((data.price && data.discount && data.price * (1 - data.discount / 100)).toFixed(2)) || 0}</span>
                        )
                    } else {
                        return (
                            <span>{(data.discount && (data.discount).toFixed(2)) || 0}</span>
                        )
                    }
                }
            },
            {
                label: this.props.t('commission_record'),
                render: (row) => {
                    return (
                        <span>{row.commission_record ? row.commission_record.commissionSalary  || 0 : 0}</span>
                    )
                }
            },
            {
                label: "加價",
                render: (row) => {
                    return (
                        <span>{row.levelPrice || 0}</span>
                    )
                }
            },
            {
                label: "定金",
                render: (row) => {
                    return (
                        <span>{(row.reservation_deposit && row.reservation_deposit.depositPrice) || 0}</span>
                    )
                }
            },
            {
                label: this.props.t('booking_revise_record_real_deal_price'),
                prop: 'amountAfterDiscount',
                render: (row) => {
                    return (
                        <span>{row.amountAfterDiscount || 0}</span>
                    )
                }
            },
            {
                label:"創建人",
                render:(row)=>{
                    if ((row.bookedBy && row.staff) && row.bookedBy == row.staff.id) {
                        return (
                            <div>
                                <span>{row.staff.staff_details[0].surname ?row.staff.staff_details[0].surname : ""}</span>
                                <span>{row.staff.staff_details[0].name ?row.staff.staff_details[0].name : ""}</span>
                            </div>
                        )
                        
                    } else {
                        return <span>{row.bookedBy}</span>
                    }
                }  
            },
            {
                label: this.props.t('booking_table_column_status'),
                prop: "status",
            },
            {
                label: this.props.t('booking_table_column_action'),
                prop: "general_rating",
                render: (row) => {
                    return (
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button  plain={true} type="info" size="small" disabled={row.status !== 'waiting' && row.status !== 'unchecked'} onClick={()=>this.setState({checkInVisiable:true,row})}>{this.props.t('tab_booking_check_in_confirm')}</Button>
                            <Button type="danger" disabled={row.status !== 'waiting' && row.status !== 'unchecked'} size="small">{this.props.t('cancel')}</Button>
                            <Button type="info" disabled={row.status !== 'waiting' && row.status !== 'unchecked'} size="small" onClick={()=>{this.modify(row)}}>{this.props.t('update')}</Button>
                        </span>
                    )
                },
                width: 200,
            },
            {
                label: this.props.t('booking_table_column_Issue_an_order'),
                prop: "staff_rating",
                render: (row) => {
                    return (
                        <span style={{ display: "flex", justifyContent: "center" }}>
                            <Button type="text" size="small" onClick={() => {this.exportPDF(row)}}
                                disabled={row.status !== 'checked'}
                            >{this.props.t('booking_table_download')}</Button>
                        </span>
                    )
                },
            }
        ],
        shortcuts: [{
            text: '最近一周',
            onClick: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                this.onChange('date', [start, end])
                this.daterangepicker.togglePickerVisible()
            }
        }, {
            text: '最近一個月',
            onClick: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                this.onChange('date', [start, end])
                this.daterangepicker.togglePickerVisible()
            }
        }, {
            text: '最近三個月',
            onClick: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                this.onChange('date', [start, end])
                this.daterangepicker.togglePickerVisible()
            }
        }],
        addBooking: false,
        form: {
            service: '',
            time: '',
            user: '',
            phone: '',
            staff: '',
            price: '',
            room: [],
            remark: ''
        },
        showBookingInform: false,
        selectedBooking: null,
        payShow: false,
        row: {},
        payForm: {
            type: 'normal',
            commission: 100,
            timesId:null,
        },
        payOptions: [
            {
                value: 100,
                label: this.props.t('booking_table_full_commission')
            },
            {
                value: 90,
                label: this.props.t('booking_table_full_ninety_percentage_commission')
            }
        ],
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            }
        ],
        select: {
            date: [],
            userName: null,
            userSurname:null,
            staffName: null,
            staffSurname:null,
            staff: null,
            serverName: [],
            phone:null,
        },
        serviceName: [],
        intentionalService: [],
        prop: {
            value: 'value',
            label: "name"
        },
        serviceTimes:[],
        times:{},
        batchCheckIn:false,
        checkInVisiable:false
    };
    //判断是否数组
    isArrayFn(value) {
        if (typeof Array.isArray === "function") {
            return Array.isArray(value);
        } else {
            return Object.prototype.toString.call(value) === "[object Array]";
        }
    }

    payOpen(row) {
        this.getServiceTimesList(row)
        this.setState({
            payShow: true,
            row: row
        })
    }

    checkIn() {
        const { row, payForm } = this.state

        let query = {
            ...payForm,
            reservationId : row.id,
            status : 'checked',
            remark : row.remark
        }

        reservationApi.checkIn(query).then(res => {
            if (res) {
                this.setState({
                    payShow: false
                })
                this.refreshData()
            }
        })
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await ServiceTree(result.rows)
            this.setState({ intentionalService: data })
        })
        .catch(error => {
            console.log(error);
            MessageBox.alert(error.userMessage);
        })
    }

    exportPDF(row) {
        if (this.isArrayFn(row)) {
            var afterData = [];
            var repeatUser = []
            for (let i = 0; i < row.length; i++) {
                let payme = {
                    type:row[i].settlement_method.type || '',
                    payment:row[i].payment || ''
                }
                if (repeatUser.indexOf(row[i].userId) === -1) {
                    afterData.push({
                        id : row[i].id,
                        userId: row[i].userId,
                        staff_schedule: row[i].staff_schedule,
                        date: moment(row[i].startTime).format('YYYY-MM-DD'),
                        user: row[i].userId ? row[i].user : row[i].userInfo,
                        room: row[i].room,
                        origin: [row[i]],
                        payme:[payme]
                    })
                    repeatUser.push(row[i].userId)
                } else {
                    for (let j = 0; j < afterData.length; j++) {
                        if (afterData[j].userId === row[i].userId) {
                            afterData[j].origin.push(row[i]);
                            afterData[j].payme.push(payme)
                            break;
                        }
                    }
                }
            }

            afterData.forEach(e => {
                let contents = {}
                let origin = []
                e.origin.forEach((item) => {
                    contents[item.serviceId] = contents[item.serviceId] || {
                        id:item.serviceId,
                        categoryId:item.service.categoryId,
                        service:item.service && item.service.service_details && item.service.service_details.length > 0 && item.service.service_details[0].name,
                        price:item.costPrice || 0,
                        amount:1,
                        discount:item.discount || 0,
                        dealPrice:item.price || 0
                    }
                    var findIndex = origin.findIndex( o => o.id === item.serviceId)
                    if (findIndex < 0) {
                        origin.push(contents[item.serviceId])
                    } else {
                        contents[item.serviceId].amount++
                        contents[item.serviceId].price += item.costPrice,
                        contents[item.serviceId].discount += item.discount,
                        contents[item.serviceId].dealPrice +=  parseFloat(item.price),
                        origin[findIndex] = contents[item.serviceId]
                    }
                })
                e.origin = origin

                let res =  origin.map(item => {
                    return this.state.ids.includes(item.categoryId)
                })

                let index = res.indexOf(true)
    
                e.show = index < 0 ? false : true
            }) 

            this.setState({
                pdfDatas: afterData
            }, () => {
                this.setState({
                    pdfDowload: true
                })
            })
        } else {
            var data = []
            let payme = {
                type:row.settlement_method.type || '',
                payment:row.payment || ''
            }
            data.push({
                id : row.id,
                userId: row.userId,
                staff_schedule: row.staff_schedule,
                date: moment(row.startTime).format('YYYY-MM-DD'),
                user: row.userId ? row.user : row.userInfo,
                room: row.room,
                origin: [row],
                payme:[payme]
            })
            data.forEach(e => {
                let contents = {}
                let origin = []
                e.origin.forEach((item) => {
                    contents[item.serviceId] = contents[item.serviceId] || {
                        id:item.serviceId,
                        categoryId:item.service.categoryId,
                        service:item.service && item.service.service_details && item.service.service_details.length > 0 && item.service.service_details[0].name,
                        price:item.costPrice || 0,
                        amount:1,
                        discount:item.discount || 0,
                        dealPrice:item.price || 0
                    }
                    var findIndex = origin.findIndex( o => o.id === item.serviceId)
                    if (findIndex < 0) {
                        origin.push(contents[item.serviceId])
                    } else {
                        contents[item.serviceId].amount++
                        contents[item.serviceId].price += item.costPrice,
                        contents[item.serviceId].discount += item.discount,
                        contents[item.serviceId].dealPrice +=  parseFloat(item.price),
                        origin[findIndex] = contents[item.serviceId]
                    }
                })
                e.origin = origin

                let res =  origin.map(item => {
                    return this.state.ids.includes(item.categoryId)
                })

                let index = res.indexOf(true)
    
                e.show = index < 0 ? false : true
            }) 

            this.setState({
                pdfDatas: data
            }, () => {
                this.setState({
                    pdfDowload: true
                })
            })
        }
    }

    morePDF() {
        var selectionChange = this.state.selectionChange
        let isExport = true
        selectionChange.forEach(item => {
            if (item.status != 'checked') {
                isExport = false
                return Message({
                    type:'error',
                    message:'选中的订单中包含有未确认的订单',
                    customClass: 'zZindex',
                    showClose:true,
                    duretion: 500
                })
            }
        })
        if (isExport) {
            this.exportPDF(selectionChange)
        }
    }

    modify(row) {
        this.setState({
            selectedBooking: row,
        })
        this.setState({
            showBookingInform: true
        })
    }

    renderDate(date) {
        var date = new Date(date.startTime).toJSON();
        var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return val.replace(/\d{1,2}:\d{1,2}:\d{1,2}/,'')
    }

    renderTime(date) {
        var startTime = new Date(date.startTime).toJSON();
        var endTime = new Date(date.endTime).toJSON();
        var from = new Date(+new Date(startTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        var to = new Date(+new Date(endTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return from.replace(/\d{4}-\d{1,2}-\d{1,2}/,'') + "-" + to.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
    }

    async dateChange(value) {
        if (!value) {
            const newForm = { ...this.state.select, startTime: null, endTime: null, date: null }
            this.setState({
                select: { ...newForm }
            });
        } else {
            var startTime = moment(value[0]).format('YYYY-MM-DD HH:mm:ss');
            var endTime = moment(value[1]).format('YYYY-MM-DD HH:mm:ss');
            const newForm = { ...this.state.select, startTime, endTime, date: value }
            this.setState({
                select: { ...newForm }
            });
        };
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateUserRecords())
    }

    setSort(e) {
        if (e.order) {
            this.setState({
                orderBy: e.prop,
                direction: e.order === "ascending" ? "ASC" : "DESC",
            });
        }
    }

    async setDayAndRefresh(day) {
        await this.setAndRefresh({
            day,
            minTime: moment(day).startOf('day').add(10, 'hours').valueOf(),
            maxTime: moment(day).startOf('day').add(21, 'hours').valueOf()
        });
    }

    async setAndRefresh(changes) {
        await new Promise(res => {
            this.setState(changes, res);
        });
        await this.refreshData(true);
    }

    async refreshData(select) {
        let reservations;
        if(select){
            console.log("111",select)
            this.setState({calendarLoading:true})
            reservations = await reservationApi.getDayReservations(
                this.state.select.date && this.state.select.date.length > 0 ? moment(this.state.select.date[0]).toISOString() : moment(new Date()).toISOString(),
                this.state.select.date && this.state.select.date.length > 0 ? moment(this.state.select.date[1]).toISOString() : moment(new Date()).toISOString(),
                {
                    centerId: this.state.center ? this.state.center.id : undefined,
                    serviceId: this.state.serviceName[this.state.serviceName.length - 1] || undefined,
                    staffId: this.state.select.staff || undefined,
                    // serviceName: this.state.serviceName || undefined,
                    phone:this.state.select.phone || undefined,
                    staffName: this.state.select.staffName || undefined,
                    userName: this.state.select.userName || undefined,
                    createdAt: null,
                    userSurname: this.state.select.userSurname || undefined,
                    staffSurname: this.state.select.staffSurname || undefined,
                    orderBy:this.state.select.orderBy || 'startTime',
                    direction:this.state.select.direction || 'DESC'
                }
            );
            this.setState({calendarLoading:false})
        }else{
            this.setState({calendarLoading:true})
            reservations = await reservationApi.getDayReservations(
                moment(this.state.select.date[0]).toISOString(),
                moment(this.state.select.date[1]).toISOString(),
                {
                    centerId: this.state.center ? this.state.center.id : undefined,
                    serviceId: this.state.service || undefined,
                    staffId: this.state.staff || undefined
                }
            );
            this.setState({calendarLoading:false})
        }

        // console.log(reservations);
        this.setState({
            results: reservations.filter(result => result.rest !== 'yes' && result.staff_schedule.staff.isDeleted == false),
            addBookingForm: {
                ...this.state.addBookingForm,
                ["center"]: this.state.center,
                ["centerName"]: this.state.center ? this.state.center.center_details[0].name : ''
            },
        });
        console.log(this.state.results);
    }
    // 更新用户信息
    // async updateUserRecords() {
    //     const { currentPage, orderBy, direction, limit } = this.state;
    //     const response = await reservationApi.getUserReservations(null, false, currentPage, orderBy, direction, limit);
    //     console.log('response:',  response);
    //     this.setState({
    //         results: response.rows.map((reservation) => {
    //             const data = {
    //                 date: moment(reservation.createdAt).format("YYYY/MM/DD"),
    //                 time: `${moment(reservation.startTime).format("hh:mmA")}-${moment(reservation.endTime).format("hh:mmA")}`,
    //                 surname: reservation.user.surname,
    //                 name: reservation.user.name,
    //                 tel: reservation.user.phone,
    //                 center: reservation.center,
    //                 appointment: `${reservation.category}-${reservation.service}`,
    //                 staff_name: reservation.staff,
    //                 general_rating: reservation.overallRating ? `${reservation.overallRating}/5` : null,
    //                 staff_rating: reservation.therapistRating ? `${reservation.therapistRating}/5` : null,
    //                 feedBack: reservation.feedBack,
    //             }
    //             return data;
    //         }),
    //         totalResult: response.count,
    //     });
    // }

    getServiceTimesList(row){
        if (!row.userId) return

        let request = {
            userId:row.userId,
            serviceId:row.serviceId
        }
        serviceTimesApi.listServiceTimes(request).then(result => {
            const {data} = result

            if (data.length <= 0) return
            
            let combo = data.map(item => {
                return {
                    key:item.secondaryCardTransactionId,
                    times:item.times,
                    label:item.secondary_card_transaction.name,
                    expireDate:item.expireDate
                }
            })

            this.setState({
                serviceTimes:combo,
                payForm: Object.assign({}, this.state.payForm, { timesId: data[0].secondaryCardTransactionId }),
                times:data[0]
            })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        })
    }

    async componentDidMount() {
        this.getServiceList();
        const staffs = await staffApi.getStaffAll();
        this.setState({
            staffs,
        })
        var d = new Date();
        var start = new Date(d.getFullYear(),d.getMonth(), d.getDate(),0,0,0);
        var end = new Date(d.getFullYear(),d.getMonth(), d.getDate(),23,59,59);
        this.state.select.date = [start, end];
        this.state.select.startTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
        this.state.select.endTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
        this.refreshData()

        categoryApi.all().then(result => {
            const {data} = result
            let dataMaps = {}
            data.forEach(item => {
                if (item.stratum == 1) {
                    dataMaps[item.id] = dataMaps[item.id] || {
                        id: item.id,
                        slide: item.slide,
                        stratum: item.stratum,
                        stratumId: item.stratumId,
                        category_details:item.category_details,
                        datas:[]
                    }
                } else {
                    if (dataMaps[item.stratumId]) {
                        dataMaps[item.stratumId].datas.push(item)
                    }
                }
            })

            let ids = []

            Object.keys(dataMaps).forEach(item => {
                let element = dataMaps[item]
                if(element && element.id == "0ecd757f-ee42-4217-b1d9-f74fe184bf7d") {
                    ids = dataMaps[item].datas.map(e => e.id)
                }
            })

            this.setState({
                ids
            })
        })
    }

    onChange(key, value) {
        if (key == 'timesId') {
            const {serviceTimes} = this.state
            let timesMap = serviceTimes.map(item => item.key)
            console.log(timesMap)
            let keyIndex = timesMap.indexOf(value)
            this.setState({
                times:serviceTimes[keyIndex]
            })
        }

        this.setState({
            form: Object.assign(this.state.payForm, { [key]: value })
        });
    }

    setSelect(e, key) {
        this.setState({
            select: {
                ...this.state.select,
                [key]: e
            }
        })
    }

    search() {
        this.refreshData(true)
    }

    handleSort(data){
        let value = data.order == 'descending' ? 'DESC' : 'ASC'
        this.setState({
            select: {
                ...this.state.select,
                "orderBy": data.prop,
                "direction": value
            }
        },()=>{
            this.refreshData(true)
        })
    }

    downloadPDF

    render() {
        const { t } = this.props;
        const { select, shortcuts, day, selectedBooking, showBookingInform, results ,calendarLoading, serviceName, staffs,checkInVisiable,row } = this.state
        return (
            <div className="booking-table-page">
                <div>
                    <div className="main-title">
                        {t('booking_appointment_list')}
                    </div>
                    <div>
                        <DatePickerButton
                            style={{ border: 'none' }}
                            value={day}
                            placeholder="Select Date"
                            onChange={date => {
                                if (!date) {
                                    this.setDayAndRefresh(new Date());
                                } else {
                                    this.setDayAndRefresh(date);
                                }
                            }}
                        >
                            <div style={{ flex: 1, fontSize: '1.4em', textAlign: 'right' }}>
                                {moment(day).format('MMMM DD, YYYY')}
                            </div>
                        </DatePickerButton>
                    </div>
                </div>

                <div style={{ padding: '25px 25px 0' }}>
                    <Form ref="select" model={select} labelWidth="75" labelPosition='left'>
                        <Layout.Row gutter="45">
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="日期時間" prop="date">
                                    <DateRangePicker style={{width:"100%"}} value={select.date} isShowTime={true} placeholder="選擇日期時間範圍" align="right" onChange={(value) => { this.dateChange(value) }} ref={e => this.daterangepicker = e} shortcuts={shortcuts} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8" lg="8">
                                <Form.Item label={t('客戶姓名')} prop='userName'>
                                    <Input value={select.userName} onChange={(e) => { this.setSelect(e, 'userName') }} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8" lg="8">
                                <Form.Item label={t('师傅')} >
                                        <Select style={{width:"100%"}} clearable value={select.staff} disabled={staffs.length <= 0} onChange={(e) => this.setSelect(e,'staff')} placeholder="Select">
                                            {staffs.map(staff => (
                                                <Select.Option key={staff.id} label={staff.staff_details[0].name} value={staff.id} />
                                            ))}
                                        </Select>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8" lg="8">
                                <Form.Item label={t('項目')} prop='serverName'>
                                    <Cascader
                                        style={{width:"100%"}}
                                        value={serviceName}
                                        placeholder={t('please_choose')}
                                        props={this.state.prop}
                                        options={this.state.intentionalService}
                                        filterable={true}
                                        clearable={true}
                                        onChange={(serviceName,selectOption) => this.setAndRefresh({ serviceName })}
                                    ></Cascader>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8" lg="8">
                                <Form.Item label={t('電話號碼')} prop='serverName'>
                                    <Input value={select.phone} onChange={(e) => { this.setSelect(e, 'phone') }} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="24">
                                <div style={{textAlign:"center"}}>
                                    <Button onClick={() => { this.search() }}>查詢</Button>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </div>
                <Button onClick={this.morePDF.bind(this)} style={{ float: 'left', margin: '12px 1px' }} icon="plus" disabled={!this.state.selectionChange.length > 0}>批量出單</Button>
                <Button onClick={()=>this.setState({batchCheckIn:true})} style={{ float: 'left', margin: '12px 1px' }} icon="plus" disabled={!this.state.selectionChange.length > 0}>批量确认</Button>
                {
                    this.state.batchCheckIn && this.state.selectionChange.length > 0 ? <BatchConfirmation selectionChange={this.state.selectionChange} success={()=>this.setState({batchCheckIn : false},()=>{this.refreshData()})} isClosed={() => this.setState({batchCheckIn : false})}></BatchConfirmation> : null
                }
                <Loading loading={calendarLoading} text="拼命加载中">
                    <Table
                        style={{ width: '100%' }}
                        columns={this.state.columns}
                        data={results}
                        border={true}
                        onSelectChange={(selection) => {
                            console.log(selection)
                            this.setState({
                                selectionChange: selection
                            })
                        }}
                        onSelectAll={(selection) => {
                            this.setState({
                                selectionChange: selection
                            })
                        }}
                        onSortChange={this.handleSort.bind(this)}
                    >
                    </Table>
                </Loading>
                
                {showBookingInform 
                    ? <BookingInform 
                        booking={selectedBooking} 
                        visible={showBookingInform} 
                        onCancel={() => this.setState({ showBookingInform: false }, () => this.refreshData())} 
                        /> 
                    : null
                }
                {
                    checkInVisiable && <CheckInDialog row={row} closed={()=>this.setState({checkInVisiable:false})} success={()=>{this.setState({checkInVisiable:false},()=>{this.refreshData()})}}></CheckInDialog>
                }
                {/* PDF */}
                <div style={{ "position": "absolute", "zIndex": "-999", "opacity": "0" }}>
                    {
                        this.state.pdfDowload ? <PDF className="pdf" pdfDatas={this.state.pdfDatas} t={t} closedPDF={() => { this.setState({ pdfDowload: false }) }}></PDF> : null
                    }
                </div>
            </div>
        );
    }
}