import {createBrowserHistory} from 'history';

// const history = createBrowserHistory();

import {createHashHistory} from 'history';

const history = createHashHistory();

// const oldPush = history.push;
// history.push = (arg1, ...args) => {
//     if (arg1[0] === '/') {
//         return oldPush(`${process.env.PUBLIC_URL}${arg1}`, ...args);
//     } else {
//         return oldPush(arg1, ...args);
//     }
// }

export default history;