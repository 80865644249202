import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {  FileExcelFilled } from '@ant-design/icons';
import { Button, Select, Form, Input , DatePicker, Col, Row,Table, Spin,Checkbox,message,Tooltip } from 'antd';
import moment from 'moment';
import "./record.scss"
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import { isJSON } from '../../../utils/isJSON'
import * as reportApi from "../../../api/report.api";
import { downloadExcel } from "../../../utils/execlUtils";
const { RangePicker } = DatePicker;


@translate()
export default class TimesRecordReport extends Component {
    formRef = React.createRef();
    state = {
        search:{
            date:[moment(new Date()),moment(new Date())]
        },
        usedSearch:{
            date:[moment(new Date()),moment(new Date())]
        },
        loading:false,
        usedLoading:false,
        columns:[
            {
                title: '姓名',
                width:200,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '手機號',
                width:200,
                dataIndex: 'phone',
                key: 'phone'
            },
            {
                title: '次卡名稱',
                width:200,
                dataIndex: 'packageName',
                key: 'packageName'
            },
            {
                title: '項目名稱',
                key: 'containService',
                dataIndex: 'containService',
                render(_,row){
                    return (
                        <div>
                            {
                                row.containService.map(item => {
                                    return <p style={{margin:"0 5px"}}>{item}</p>
                                })
                            }
                        </div> 
                    )
                },
                width:200,
                ellipsis:true
            },
            {
                title: '總次數',
                key: 'times',
                dataIndex: 'times',
                width:200,
                sorter: true,
            },
            {
                title: '總價格',
                key: 'total',
                dataIndex: 'total',
                width:200,
                render(_,row) {
                    return <div>{parseFloat(row.price) * parseFloat(row.times)}</div>
                },
                sorter: true,
            },
            {
                title: '剩餘次數',
                key: 'overTimes',
                dataIndex: 'overTimes',
                width:200,
                sorter: true,
            },
            {
                title: '剩餘價格',
                dataIndex: 'overPrice',
                width:200,
                render(_,row) {
                    return <div>{parseFloat(row.price) * parseFloat(row.overTimes)}</div>
                },
                sorter: true,
            },
            {
                title: '過期時間',
                key: 'expireDate',
                width:200,
                dataIndex: 'expireDate',
                render(_,row) {
                    return <div>{moment(row.expireDate).format('YYYY-MM-DD')}</div>
                }
            },
            {
                title: '是否已回退',
                key: 'isDeleted',
                width:200,
                dataIndex: 'isDeleted',
                render(_,row) {
                    console.log('row',row)
                    return <div>{row.isDeleted ? '是':'否'}</div>
                }
            }
        ],
        record : [],
        usedRecord:[],
        usedColumns:[
            {
                title: '次卡名称',
                dataIndex: 'name',
                key: 'name',
                width:200,
            },
            {
                title: '服务名称',
                width:200,
                dataIndex: 'serviceName',
                key: 'serviceName'
            },
            {
                title: '客户名称',
                width:200,
                render(_,row){
                    return <div>{ row.userSurname + row.userName}</div>
                }
            },
            {
                title: '单价',
                width:200,
                render(_,row){
                    let results = isJSON(row.extra)
                    let datas = results ? JSON.parse(row.extra)  : row.extra
                    let packages = datas[0]
                    return <div>{ packages.price || 0 }</div>
                }
            },
            {
                title: '使用次数',
                width:200,
                dataIndex: 'times',
                key: 'times'
            },
            {
                title: '使用时间',
                width:200,
                dataIndex: 'createdAt',
                key: 'createdAt',
                render(_,row){
                    return <div>{moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            }
        ]
    }

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getApiData(this.state.search,reportApi.timesRecordList,"loading","record")
        })
    }

    onUsedFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            usedSearch:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getApiData(this.state.usedSearch,reportApi.timesUsedRecordList,"usedLoading","usedRecord")
        })
    }

    getApiData(query,api,loading,name){
        let values = this.formatRequest(query)

        let search = {
            ...values,
        }

        if (values.startTime && values.endTime) {
            search.startTime = moment(moment(values.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            search.endTime = moment(moment(values.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }
    
        this.setState({[loading]:true})

        api(search).then(result => {
            const {data:{data}} = result
            this.setState({
                [name]:data,
                [loading]:false
            })
        })
    }

    getData(){
        const { search } = this.state
        this.getApiData(search,reportApi.timesUsedRecordList,"loading","usedRecord")
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 

        delete query.date

        return removeEmptyFiled(query)
    }

    onChange  (pagination, filters, sorter, extra){
        let order = {}
        if (sorter.field && sorter.order ) {
            order.field = sorter.field
            order.order = sorter.order == "ascend" ? "ASC":"DESC"
        }

        this.setState({
            search:Object.assign({},this.state.search,{order})
        },()=>{
            this.getApiData(this.state.search,reportApi.timesRecordList,"loading","record")
        })
    };

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }
 
    componentDidMount(){
        this.getData()
    }

    render(){
        const { loading ,usedLoading, record ,columns, usedRecord,usedColumns } = this.state
        return (
            <main className='report-contanier'>
                <section className='search-contanier'>
                    <Form initialValues={{ date:[moment(new Date()),moment(new Date())] }} onFinish={this.onUsedFinish.bind(this)} autoComplete="off">
                        <Row gutter={20}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <Form.Item name="date" label="查詢日期">
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item name="userName" label="姓名">
                                    <Input/>
                                </Form.Item> 
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item name="timesName" label="次卡名称">
                                    <Input/>
                                </Form.Item> 
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        查詢
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </section>

                <section className='table-contanier'>
                    <div className='table-main'>
                        <Table columns={usedColumns} dataSource={usedRecord} pagination={{"total":record.length,showSizeChanger:true}} loading={usedLoading} bordered={true}
                        title ={() => {
                            return (
                                <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                    <div>
                                        <p style={{margin:0,fontSize:18}}>客戶次卡套餐使用情況</p>
                                    </div>
                                    <div>
                                        <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel("客戶次卡套餐使用報表",usedColumns,usedRecord)}></Button>
                                    </div>
                                </main>
                            )
                        }} 
                        ></Table>
                    </div>
                </section>
            </main>
        )
    }
}