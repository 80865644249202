import {translate} from "react-i18next";
import React, {Component} from "react";
import {Form , DateRangePicker, Input, Layout} from "element-react";
import {  FileExcelFilled } from '@ant-design/icons';
import moment from "moment";
import * as reportApi from "../../../../api/report.api";
import "./score-report.scss"
import {Table,Button} from "antd";
import { downloadExcel } from "../../../../utils/execlUtils";


import ExportExeclButton from "../exprot-execl-button/index"

@translate()
export default class ScoreReportPage extends Component {
    state = {
        total:100,
        tableData:[],
        loading : false,
        search :{
            page:1,
            limit:40,
            date:[new Date() , new Date()],
            keywords : "",
            orders:[],
        },
        exportList :[
            {label:"變更日期" , value :''},
            {label:"會員姓名" , value :''},
            {label:"電話號碼" , value :''},
            {label:"變更類型" , value :''},
            {label:"積分" , value :''},
            {label:"當前剩餘" , value :''},
            {label:"備註" , value :''},
            {label:"操作時間" , value :''},
            {label:"操作人" , value :''},
        ],
        exportVisibled: false,
        columns:[
            {
                title:"變更日期",
                dataIndex:'changeDate',
                render: (row) => {
                    return <div>{moment(row.changeDate).format("YYYY/MM/DD")}</div>
                },
                sorter: {
                    multiple: 1,
                },
            },
            {
                title: "會員姓名",
                render:(_,row) => {
                    return <div>{row.userSurname}{row.userName}</div>
                }
            },
            {
                title: "電話",
                dataIndex: "phone"
            },
            {
                title:"變更類型",
                dataIndex: "type",
                render: (_,row) => {
                    return <div>{row.type == 'checkIn' || row.type == 'times' ? "新增" : "扣除"}</div>
                },
                sorter: {
                    multiple: 2,
                },
            },
            {
                title: "積分",
                dataIndex: "score",
                sorter: {
                    multiple: 3,
                },
            },
            {
                title: "當前剩餘",
                dataIndex: "afterChangeScore",
                sorter: {
                    multiple: 4,
                },
            },
            {
                title:"操作日期",
                dataIndex:'changeDate',
                render: (row) => {
                    return <div>{moment(row.createdAt).format("YYYY/MM/DD")}</div>
                },
                sorter: {
                    multiple: 5
                },
            }
        ],
    }

    componentDidMount(){
        this.getData()
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, { [key]: value })
        });
    }

    pageAndOrder(pagination, filters, sorter, extra) {
        console.log(pagination)
        let orders = []
        if (sorter.length > 0) {
            sorter.forEach(item => {
                let obj = {
                    field:item.field,
                    orders:item.order == "ascend"?"ASC":"DESC"
                }
                orders.push(obj)
            });
        } else if (sorter.field) {
            orders.push({
                field:sorter.field,
                orders:sorter.order == "ascend"?"ASC":"DESC"
            })
        }
        this.setState({
            search: Object.assign({}, this.state.search, {
                orders,
                page:pagination.current,
                limit: pagination.pageSize
            })
        },() => {
            this.getData()
        })
    }

    getData() {
        const { search } = this.state
        let query = {...search}
        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }

        this.setState({
            loading:true
        })

        reportApi.getScoreReport(query).then (result => {
            const { data }  = result
            this.state.tableData = data.data
            this.state.total = data.count
        }).catch(error => {
            this.setState({
                loading:false
            })
        }).finally(() => {
            this.setState({
                loading:false
            })
        })
    }

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }

    render() {
        const {search , exportList , exportVisibled , columns ,tableData , total } = this.state
        return (
            <main className='score-report-main'>
                <section className='search-info'>
                    <Form ref='form' model={search} labelWidth='75' labelPosition="left" className="rule-form">
                        <Layout.Row gutter="20">
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="日期時間" prop="date">
                                    <DateRangePicker value={search.date} placeholder="選擇日期範圍" onChange={this.onChange.bind(this,'date')} align="right" style={{width:'100%'}} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="查詢框">
                                    <Input value={search.keywords} placeholder="請輸入" onChange={this.onChange.bind(this, 'keywords')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <div className='submit-buttons' style={{textAlign:"left"}}>
                                    <Button onClick={() => this.getData()}>查詢</Button>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='score-table-container'>
                    <div className='score-table-main'>
                        <Table columns={columns} dataSource={tableData} pagination={{"total":total , showSizeChanger:true}} onChange={this.pageAndOrder.bind(this)}
                        title ={() => {
                            return (
                                <main style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
                                    <div>
                                        <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel("積分報表",columns,tableData)}></Button>
                                    </div>
                                </main>
                            )
                        }}
                        ></Table>
                    </div>
                </section>
            </main>
        )
    }
}