import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Button, Select, Form, Input , DatePicker, Col, Row, Typography, Table } from 'antd';
import "./record.scss"
import moment from 'moment';
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import * as reportApi from "../../../api/report.api";
import { downloadExcel } from "../../../utils/execlUtils";

const { RangePicker } = DatePicker;


const { Text } = Typography;
@translate()
export default class ExpensesRecord extends Component {
    formRef = React.createRef();
    state={
        search:{
            date:[moment(new Date()),moment(new Date())]
        },
        columns: [
            {
                title: '服务一级分类',
                dataIndex: 'categoryName',
                key: 'categoryName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.oldClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.categoryId === row.categoryId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].categoryId !== row.categoryId) {
                      obj.props.rowSpan = dataSource.filter(item => item.categoryId === row.categoryId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '服务二级分类',
                dataIndex: 'stratumName',
                key: 'stratumName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.oldClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                      obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '业绩',
                dataIndex: 'performance',
                key: 'performance',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.oldClientData;
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: '占比',
                dataIndex: 'proportion',
                key: 'proportion',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.oldClientData;
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
              },
            {
                title: '服務名稱',
                dataIndex: 'serviceName',
                key: 'serviceName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.oldClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if ( dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '单价',
                dataIndex: 'price',
                key: 'price',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.oldClientData;
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '业绩',
                dataIndex: 'serviceNamePerformance',
                key: 'serviceNamePerformance',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.oldClientData;
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                    obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
            },
            {
                title: '总数量',
                dataIndex: 'totalCount',
                key: 'totalCount',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.oldClientData;
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '男客数量',
                dataIndex: 'maleCount',
                key: 'maleCount',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.oldClientData;
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                  },
            },
            {
                title: '女客数量',
                dataIndex: 'femaleCount',
                key: 'femaleCount',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.oldClientData;
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
            title: '其他客数量',
            dataIndex: 'otherCount',
            key: 'otherCount',
            render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.oldClientData;
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title:"客戶名稱",
                dataIndex:'name',
                key:'name',
            }
        ],
        newColumns: [
            {
                title: '服务一级分类',
                dataIndex: 'categoryName',
                key: 'categoryName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.categoryId === row.categoryId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].categoryId !== row.categoryId) {
                      obj.props.rowSpan = dataSource.filter(item => item.categoryId === row.categoryId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '服务二级分类',
                dataIndex: 'stratumName',
                key: 'stratumName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                      obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '业绩',
                dataIndex: 'performance',
                key: 'performance',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.newClientData;
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: '占比',
                dataIndex: 'proportion',
                key: 'proportion',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.newClientData;
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].stratumId !== row.stratumId) {
                    obj.props.rowSpan = dataSource.filter(item => item.stratumId === row.stratumId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
              },
            {
                title: '服務名稱',
                dataIndex: 'serviceName',
                key: 'serviceName',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if ( dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '单价',
                dataIndex: 'price',
                key: 'price',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '业绩',
                dataIndex: 'serviceNamePerformance',
                key: 'serviceNamePerformance',
                render: (value, row, index) => {
                  const obj = {
                    children: value,
                    props: {},
                  };
                  const dataSource = this.state.results.newClientData
                  if (index === 0) {
                    obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                  } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                    obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                },
            },
            {
                title: '总数量',
                dataIndex: 'totalCount',
                key: 'totalCount',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title: '男客数量',
                dataIndex: 'maleCount',
                key: 'maleCount',
                render: (value, row, index) => {
                    const obj = {
                      children: value,
                      props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                      obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                      obj.props.rowSpan = 0;
                    }
                    return obj;
                  },
            },
            {
                title: '女客数量',
                dataIndex: 'femaleCount',
                key: 'femaleCount',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
            title: '其他客数量',
            dataIndex: 'otherCount',
            key: 'otherCount',
            render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    const dataSource = this.state.results.newClientData
                    if (index === 0) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else if (dataSource[index - 1] && dataSource[index - 1].serviceId !== row.serviceId) {
                        obj.props.rowSpan = dataSource.filter(item => item.serviceId === row.serviceId).length;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
            },
            {
                title:"客戶名稱",
                dataIndex:'name',
                key:'name',
            }
        ],
        loading: false,
        results: {}
    }


    async getServiceMaps(value) {
        let data = value
        // 计算总金额
        let totalAmount = data.reduce((total, item) => total + (item.amountAfterDiscount || item.price), 0);
        // 计算每个二级分类的业绩
        let performanceMap = {};
        // 计算每个服务名称的业绩
        let serviceNamePerformanceMap = {};
        // 计算每个服务名称的男客数量、女客数量和总数量
        let serviceNameCountMap = {};

        data.forEach(item => {
            if (!performanceMap[item.stratumId]) {
                performanceMap[item.stratumId] = 0;
            }
            if (!serviceNamePerformanceMap[item.serviceId]) {
                serviceNamePerformanceMap[item.serviceId] = 0;
            }

            if (!serviceNameCountMap[item.serviceId]) {
                serviceNameCountMap[item.serviceId] = { male: 0, female: 0,other: 0, total: 0 };
            }

            if (item.gender === 'male') {
                serviceNameCountMap[item.serviceId].male++;
            } else if (item.gender === 'female') {
                serviceNameCountMap[item.serviceId].female++;
            } else {
                serviceNameCountMap[item.serviceId].other++;
            }

            serviceNameCountMap[item.serviceId].total ++
            serviceNamePerformanceMap[item.serviceId] += (item.amountAfterDiscount || item.price) || 0 ;
            performanceMap[item.stratumId] += (item.amountAfterDiscount || item.price) || 0 ;
        });

        // 计算业绩和占比
        data.forEach(item => {
            item.performance = performanceMap[item.stratumId];
            item.serviceNamePerformance = serviceNamePerformanceMap[item.serviceId];
            item.maleCount = serviceNameCountMap[item.serviceId].male;
            item.femaleCount = serviceNameCountMap[item.serviceId].female;
            item.totalCount = serviceNameCountMap[item.serviceId].total;
            item.otherCount = serviceNameCountMap[item.serviceId].other;
            item.proportion = (item.performance / totalAmount * 100).toFixed(2) + '%';
        });

        return data;
    }

    async getData(){
        await this.setState({loading:true})

        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
            startTime:moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss"),
            endTime:moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss"),
        }

        let newRequest = {...query,type:1}
        let oldRequest = {...query,type:0}

        const [
            newClient,
            oldClient
        ] = await Promise.all([
            reportApi.expensesRecordList(newRequest),
            reportApi.expensesRecordList(oldRequest),
        ]);

        const { data:{data:newClientData} } = newClient
        const { data:{data:oldClientData} } = oldClient

        let oldData = await this.getServiceMaps(oldClientData)
        let newData = await this.getServiceMaps(newClientData)

        await this.setState({
            loading:false,
            results:{
                oldClientData:oldData.sort((a, b) => {
                    if (a.categoryId !== b.categoryId) {
                        return a.categoryId.localeCompare(b.categoryId);
                    } else if (a.stratumId !== b.stratumId) {
                        return a.stratumId.localeCompare(b.stratumId);
                    } else {
                        return a.serviceId.localeCompare(b.serviceId);
                    }
                }),
                newClientData:newData.sort((a, b) => {
                    if (a.categoryId !== b.categoryId) {
                        return a.categoryId.localeCompare(b.categoryId);
                    } else if (a.stratumId !== b.stratumId) {
                        return a.stratumId.localeCompare(b.stratumId);
                    } else {
                        return a.serviceId.localeCompare(b.serviceId);
                    }
                }),
            }
        })
    };

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    componentDidMount(){
        this.getData()
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        }
        
        delete query.date

        return removeEmptyFiled(query)
    }

    onChange  (pagination, filters, sorter, extra){
        let orders = []
    };

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }
    

    render(){
        const dateFormat = 'YYYY/MM/DD';
        const { loading,results,columns,newColumns } = this.state
        let data = []
        let payment = {}
        let total = 0
        for (var i in results) {
            data = [...data,...results[i]]
            results[i].forEach(item => {
                total += item.amountAfterDiscount
                if (item.type == "normal") {
                    if (!item.payment) {
                        item.payment = "other"
                        item.amountAfterDiscount = 0
                    }
                    payment[item.payment] = payment[item.payment] ? payment[item.payment] + item.amountAfterDiscount : item.amountAfterDiscount
                } else {
                    if (!item.type) {
                        item.type = "other"
                        item.amountAfterDiscount = 0
                    }
                    payment[item.type] = payment[item.type] ?  payment[item.type] + item.amountAfterDiscount : item.amountAfterDiscount
                }
            })
        }

        return (
            <main className='report-contanier'>
                <section className='search-contanier'>
                    <Form ref={this.formRef} initialValues={{ date:[moment(new Date()),moment(new Date())] }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                        <Row gutter={20}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <Form.Item name="date" label="查詢日期">
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item name="gender" label="客人分類">
                                    <Select allowClear>
                                        <Select.Option value="male">男客</Select.Option>
                                        <Select.Option value="female">女客</Select.Option>
                                        <Select.Option value="null">其他</Select.Option>
                                    </Select>
                                </Form.Item> 
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        查詢
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </section>
                <section>
                    <p style={{fontSize:"18px"}}>
                        <span style={{marginRight:"10px"}}>消費總收入 : {total}</span>                        
                        {
                            Object.keys(payment).map((item,index) => {
                                return (
                                    <span key={index}  style={{marginRight:"10px",color:"#f44447",fontSize:"14px"}}>{item == "balance" ? "點數" : item}({payment[item]}) </span>
                                )
                            })
                        }
                    </p>
                </section>
                <section className='table-contanier'>
                    {
                        Object.keys(results).map((item,index) => {
                            return (
                                <div className='table-main' key={index}>
                                    <Table columns={item=="newClientData"?newColumns:columns}  key={index} dataSource={results[item]} pagination={false} loading={loading} bordered={true}
                                    title={(currentPageData) => {
                                        let userMaps = {}
                                        currentPageData.forEach(item => {
                                            userMaps[item.userId] = userMaps[item.userId] || {
                                                id : item.userId,
                                                name : item.name,
                                                gender : item.gender,
                                                total : 0 
                                            }

                                            userMaps[item.userId].total += item.amountAfterDiscount || item.price
                                        })
                                        let users = Object.keys(userMaps).map(item => userMaps[item])
                                        let female = users.filter(item => item.gender == "female")
                                        let femaleTotal = female.reduce((pre,current) => { return pre + current.total },0) 
                                        let male = users.filter(item => item.gender == "male")
                                        let maleTotal = male.reduce((pre,current) => { return pre + current.total },0) 
                                        let other = users.filter(item => item.gender != "male" && item.gender != "female")
                                        let otherTotal = other.reduce((pre,current) => { return pre + current.total },0) 
                                        return (
                                            <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                                <div>
                                                    {item == "oldClientData"?<p style={{margin:0}}>舊客戶消費表</p>:<p style={{margin:0}}>新客戶消費表</p>}
                                                    <p style={{margin:0}}>
                                                        <span style={{color:"#f44447"}}>女客({female.length})  業績({femaleTotal}) ；男客({male.length})  業績({maleTotal}) ；其他({other.length})  業績({otherTotal})</span>
                                                    </p>
                                                    {
                                                        users.length > 0 ? (
                                                            <div>
                                                                <p  style={{margin:0}}>消費排行:</p>
                                                                <div  style={{display:"flex",flexWrap:"wrap",alignItems:"center"}}>
                                                                    {users.map((item,index) => {
                                                                        return (
                                                                            <p style={{margin:0,color:"#f44447",marginRight:"10px"}} key={index}>{index + 1}. {item.name} : {item.total} </p>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </main>
                                        )
                                    }}
                                    summary={(pageData) => {
                                        let totalPrice = 0;
                                        let totalamountAfterDiscount = 0;
                                        let totalCount =pageData.length

                                        let genderMap = {}

                                        pageData.forEach(({ total, amountAfterDiscount,times,userTotal,gender,price }) => {
                                          totalPrice += total;
                                          totalamountAfterDiscount += amountAfterDiscount || price ;
                                          if (!gender) {
                                            gender = "other"
                                          }
                                          genderMap[gender] = genderMap[gender] || 0
                                          genderMap[gender] ++ 
                                        });

                                        return (
                                            <Table.Summary.Row className="summary-cell">
                                              <Table.Summary.Cell index={0} colSpan={6} style={{borderRight:"0"}}>合计</Table.Summary.Cell>
                                              <Table.Summary.Cell index={7}>
                                                <Text type="danger">{totalamountAfterDiscount}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={8}>
                                                <Text type="danger">{totalCount}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={9}>
                                                <Text type="danger">{genderMap["mela"] || 0}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={10}>
                                                <Text type="danger">{genderMap["female"] || 0}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={11}>
                                                <Text type="danger">{genderMap["other"] || 0}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={11}>
                                              </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                    onChange={this.onChange.bind(this)}></Table>
                                </div>
                            )
                        })
                    }
                </section>
            </main>
        )
    }
}