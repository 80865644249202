import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './staff-commission-report.scss'
import { Button, Select, Form, Input , DatePicker, Col, Row,Table, Spin,Checkbox,message } from 'antd';
import {  FileExcelFilled,SmileFilled, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import * as reportApi from "../../../api/report.api";
import * as staffApi from "../../../api/staff.api";
import { downloadExcel } from "../../../utils/execlUtils";

const { RangePicker } = DatePicker;


@translate()
export default class RechargeReport extends Component {
    formRef = React.createRef();
    state = {
        emptyText:'请点击查询按钮查看',
        search:{
            date:[moment(new Date()),moment(new Date())],
            plainOptions:['reservations', 'products', 'times'],
            status:"checked"
        },
        staffs:[],
        checkOptions : [
            { label: '服務', value: 'reservations' },
            { label: '產品', value: 'products' },
            { label: '次卡銷售', value: 'times' },
        ],
        loading:false,
        tableLoading:false,
        antIcon : ( <LoadingOutlined style={{ fontSize: 24,}} spin /> ),
        columns:[
            {
                title: '項目',
                dataIndex: 'name',
                width:200
            },
            {
                title: '類型',
                dataIndex: 'source',
                width:200,
                render: (value, row) => {
                    return (<div>{row.source =="times"? "次卡":row.source =="reservations" ?"服務":"產品"}</div>)
                },
            },
            {
                title: '客數',
                width:200,
                dataIndex: 'times',
            },
            {
                title: '數量',
                width:200,
                dataIndex: 'times',
            },
            {
                title: '價格',
                width:200,
                dataIndex: 'total',
                sorter: true,
            },
            {
                title: '提成',
                width:200,
                dataIndex: 'commission',
                sorter: true,
                render(_,row){
                    return <div>{row.commission || 0}</div>
                }
            },
        ],
        result:[]
    }

    handleChange(value) {
        const { search } = this.state
        if (search.staffId && search.staffId == value) return
        this.setState({
            search:Object.assign({},this.state.search,{staffId:value})
        },()=>{
            this.getData()
        })
    }

    getStaffs(){
        let query = {
           page:1,
           limit:999,
           orders:[["id","ASC"]]
        }
        this.setState({loading:true})
        if (this.state.loading) return
        staffApi.getStaffs(query).then(result => {
            const { rows } = result
            let staffs = rows.map(item => {
                return {
                    id:item.id,
                    name : item.staff_details[0].surname + item.staff_details[0].name
                }
            })
            this.setState({staffs})
        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    onFinish(values){
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 

        delete query.date

        return removeEmptyFiled(query)
    }

    getData() {
        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
        }

        if (data.startTime && data.endTime) {
            query.startTime = moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            query.endTime = moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        if (!query.staffId) {
            message.error('請選擇要查詢的員工')
            return
        }
        if (this.state.tableLoading) return
        this.setState({tableLoading:true})
        reportApi.staffCommissionRecordList(query).then(result => {
            const { data } = result.data
            if (data.length == 0) {
                this.setState({emptyText:"没有找到匹配的记录"})
            }
            this.setState({result:data})
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            this.setState({tableLoading:false})
        })
    }

    onChange  (pagination, filters, sorter, extra){
        let order = {}
        if (sorter.field && sorter.order ) {
            order.field = sorter.field
            order.order = sorter.order == "ascend" ? "ASC":"DESC"
        }
        this.setState({
            search:Object.assign({},this.state.search,{order})
        },()=>{
            this.getData()
        })
    };

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }

    componentDidMount(){
        this.getStaffs()
    }

    render(){
        const { staffs,loading,search,checkOptions, antIcon, columns, result,tableLoading,emptyText } = this.state
        const imageEdit = {
            backgroundPosition:"-110px -48px"
        }
        return (
            <main className='staff-commission-contanier'>
                <Row gutter={30}>
                    <Col span={4}>
                        <Spin spinning={loading} indicator={antIcon}>
                            <section className='staff-main'>
                                <p className='staff-main-title'> <SmileFilled style={{marginRight:"10px"}}/> 员工</p>
                                <div className='staff-change-main'>
                                    {  
                                        staffs.map((item,index) => {
                                            return <p key={index} className='staff-item' onClick={this.handleChange.bind(this,item.id)}>
                                                <span className='icon-image-staff' style={{background:"none"}}></span>
                                                <span className='icon-image-staff' style={item.id == search.staffId ? imageEdit :null }></span>
                                                <span style={item.id == search.staffId ? {color:"#2bbdf8",lineHeight:"19px"} :null}>
                                                    {item.name}
                                                </span>
                                            </p>
                                        })
                                    }
                                </div>
                            </section>
                        </Spin>
                    </Col>
                    <Col span={20}>
                        <section className='staff-commission-main'>
                            <div className='ibox-arrow'></div>
                            <div className='search-contanier'>
                                <Form ref={this.formRef} initialValues={{ date:[moment(new Date()),moment(new Date())],status:"checked" }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                                    <Row gutter={20}>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <Form.Item name="date" label="查詢日期">
                                                <RangePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{ textAlign: 'left' }}>
                                            <Form.Item name="status" label="狀態">
                                                <Select allowClear>
                                                    <Select.Option value="checked">已完成</Select.Option>
                                                    <Select.Option value="waiting">未完成</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                            
                                        <Col span={4} style={{ textAlign: 'left' }}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    查詢
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className='search-checkbox-contanier'>
                                <div className='checkbox-grounp'>
                                    <Checkbox.Group options={checkOptions} defaultValue={search.plainOptions} onChange={(value) => this.setState({ search:Object.assign({},this.state.search,{plainOptions:value}) })}/>
                                </div>
                                <div className='download-main'>
                                <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel(`${search.staffId}提成報表`,columns,result)}></Button>
                                </div>
                            </div>
                            <div className='table-main'>
                                <Table scroll={{x:true,y:true}} size={"small"} locale={{emptyText}} columns={columns} dataSource={result} pagination={{"total":result.length,showSizeChanger:true}}  total={result.length} loading={{spinning:loading || tableLoading,indicator:antIcon}} bordered={true}
                                footer={(pageData) => {
                                    let totalCommision = 0;
                                    let totalamountAfterDiscount = 0;
                                    let totalTimes = 0
                                    let timesSales = 0 
                                    let timesCommision = 0
                                    pageData.forEach(({ total, times,commission,source }) => {
                                        totalamountAfterDiscount += total;
                                        totalCommision += commission;
                                        totalTimes += times
                                        if (source == "times") {
                                            timesSales += total
                                            timesCommision  += commission
                                        }
                                    });
                                    return(
                                        <main>
                                            <Row className="summary-row">
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <div>
                                                        <span className='cell-span'>小計</span>
                                                        <span className='cell-span'>客數: {totalTimes} </span>
                                                        <span className='cell-span'>數量: {totalTimes}</span>
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <div>
                                                        <span className='cell-span'>服務價格總機 :{totalamountAfterDiscount} </span>
                                                        <span className='cell-span'>次卡銷售業績: {timesSales} </span>
                                                        <span className='cell-span'>提成: {totalCommision} </span>
                                                        <span className='cell-span'>次卡銷售提成: {timesCommision} </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="summary-row">
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <div>
                                                        <span className='cell-span'>项目类型</span>
                                                        <span className='cell-span'>服务数量: {timesSales} </span>
                                                        <span className='cell-span'>服务业绩: {totalCommision} </span>
                                                        <span className='cell-span'>服务提成: {timesCommision} </span>
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <div>
                                                        <span className='cell-span'>产品数量</span>
                                                        <span className='cell-span'>产品业绩: {timesSales} </span>
                                                        <span className='cell-span'>产品提成: {timesCommision} </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </main>
                                    )
                                }}
                                onChange={this.onChange.bind(this)}
                                ></Table> 
                            </div>
                        </section>
                    </Col>
                </Row>
            </main>
        )
    }
}