
export default {
    translation: {
        main_tab_report_management_balance:"點數支付",
        main_tab_report_management_integral:"積分支付",
        main_tab_report_management_boss:"老闆代付",
        main_tab_report_management_normal:"普通支付",
        main_tab_report_management_times:"次卡支付",
        user_board_edit_classification: '消费分类：',
        staff_tab_consumption_time: '消费时间',
        commission_record: '佣金',
        pos_Trading_price: '交易价格',
        pos_Amount_of_relief: '减免金额',
        pos_Project_original_price: '原价',
        pos_number: '数量',
        pos_project_name: '项目名称',
        Point_Expenses_record: '消费记录',
        Point_consumption_record: "积分消费记录",
        booking_table_deduct_points: '扣除积分',
        booking_table_Customer_Number: '客户编号',
        checked: '成功',
        waiting: '等待',
        unchecked: '失败',
        male: '男',
        female: '女',
        service_detail_content_integral: '服务积分：',
        Category_Name: '分类名称：',
        English_version: '英文版：',
        Chinese_Version: '中文版：',
        secondary_classification_details: '二级分类详情',
        Owning_parent: '所属父级：',
        please_choose: '请选择',
        please_select_a_language: '请选择填选语言',
        first_class_classification_name: '一级分类名称：',
        borad_category_description: '分类描述：',
        service_secondary_classification_table: '二级分类表：',
        save: '保存',
        base_service_color: '相关顔色*:',
        board_service_color: '相关顔色：',
        board_service_description: '服务描述：',
        board_precautions: '注意事项：',
        you_sure_delete: '确定要删除吗？',
        image_board_title: '相关图片：',
        image_base_title: '相關圖片*:',
        operate: "操作",
        button_edit: "编辑",
        button_save: "储存",
        button_create: "新增",
        button_view: "检视",
        button_delete: "删除",
        button_update:"更新",
        login_title: 'Miusee - 后台管理系统',
        login_staff_id: '职员编号',
        login_password: '密码',
        login_button: '登入',
        main_tab_book: '预约概览',
        main_tab_customer_management: '客户管理',
        main_tab_customer_summary: '客户概览',
        main_tab_customer_all: '所有登记用户',
        main_tab_service_feedback: '服务评价',
        main_tab_staff_management: '职员管理',
        main_tab_staff_all: '所有职员',
        main_tab_staff_holiday: '职员假期',
        main_tab_staff_schedule: '职员工作行程设定',
        main_tab_service_management: '服务管理',
        main_tab_center_management: '中心管理',
        main_tab_room_management: '房间管理',
        main_tab_about_hwl: '关于康和乐',
        main_tab_terms_conditions: '使用条款及细则',
        main_tab_health_tips: '健康贴士',
        main_tab_press: '媒体报导',
        main_tab_push_notification: '推送通知',
        main_tab_web_management: '网站管理',
        main_tab_web_management_homepage: '主页',
        main_tab_web_management_mobile: '手机应用程式描述',
        main_tab_category_management: '服务分类管理',
        main_tab_report_management: '报表管理',
        main_tab_product_management: '产品管理',
        main_pop_button_change_password: '更改密码',
        main_pop_button_log_out: '登出',
        change_password_panel_title: '更改密码',
        change_password_original_password: '原本密码',
        change_password_new_password: '新密码',
        change_password_confirm_new_password: '确认密码',
        change_password_submit: '提交',
        user_summary_title: '搜寻客户概览 (客户编号/身份证/电话号码)',
        user_summary_search_bar_placeholder: '输入客户编号、姓名、电话号码或身份证进行搜寻',
        user_search_item_member_id: '客户编号:',
        user_search_item_name: '姓名：',
        user_search_item_gender: '性别：',
        user_search_item_hkid: '身份证：',
        user_search_item_tel: '电话：',
        user_board_view_member_id: '客户编号：',
        user_board_view_name: '姓名：',
        user_board_view_gender: '性别：',
        user_board_view_hkid: '身份证：',
        user_board_view_tel: '电话号码：',
        user_board_view_remark: '备注：',
        user_board_view_surname: '姓氏：',
        user_board_view_birthday: '出生日期：',
        user_board_view_family_id: '家庭户口编号：',
        user_board_view_email: '电邮：',
        user_board_view_address: '地址：',
        user_board_view_family_balance: '现有家庭户口结馀：',
        user_board_view_photo: '相片：',
        user_board_view_body_condition: '体质：',
        user_board_edit_member_id: '客户编号：',
        user_board_edit_name: '名*：',
        user_board_edit_gender: '性别：',
        user_board_edit_hkid: '身份证：',
        user_board_edit_tel: '电话*：',
        user_board_edit_surname: '姓*:',
        user_board_edit_birthday: '出生日期：',
        user_board_edit_family_id: '家庭户口编号：',
        user_board_edit_email: '电邮：',
        user_board_edit_address: '地址：',
        user_board_edit_remark: '备注：',
        user_board_edit_photo: '相片：',
        user_board_edit_body_condition: '体质：',
        user_board_edit_intentional_service: '意向服務：',
        user_board_edit_consume_class: '消費分類：',
        user_board_edit_customer_class: '客戶分類：',
        user_board_edit_introducer: '介紹人選擇：',
        user_summary_customer_summary: '客户摘要',
        user_summary_tab_booking: '预览预约记录',
        user_summary_tab_body_condition: '用户体质',
        user_summary_tab_family: '相关家庭成员户口',
        user_summary_tab_transaction: '账户交易记录',
        user_summary_tab_top_up: '增值',
        user_summary_tab_transfer: '转移户口结馀',
        tab_booking_result_limit_show: '每页显示',
        tab_booking_result_limit_per: '项结果',
        tab_booking_total_page: "显示 {{head}} 至 {{end}} ，共 {{total}} 项结果",
        tab_booking_table_column_ymd: "日期 (年月日)",
        tab_booking_table_column_time: "时间",
        tab_booking_table_column_center: "分店",
        tab_booking_table_column_category: "分类",
        tab_booking_table_column_service: "服务名称：",
        tab_booking_table_column_doctor: "治疗师／中医",
        tab_booking_table_button_upload: "上载",
        tab_booking_table_button_share: "分享",
        tab_booking_table_button_not_share: "不分享",
        tab_booking_table_column_prescription: "药单",
        tab_booking_table_column_report: "报告",
        tab_booking_table_column_action: "动作",
        tab_booking_table_column_pay: "付款",
        tab_booking_check_in_title: "确认客人报道／没有出现？",
        tab_booking_check_in_date: "日期／时间",
        tab_booking_check_in_name: "名字",
        tab_booking_check_in_hkid: "身份证",
        tab_booking_check_in_appointment: "预约",
        tab_booking_check_in_therapist: "治疗师／医师",
        tab_booking_check_in_balance: "从家庭账户扣除",
        tab_booking_check_in_note: "备注",
        tab_booking_check_in_confirm: "确认",
        tab_booking_check_in_cancel: "取消",
        tab_booking_attended_title: "检阅预约",
        tab_booking_confirm_title_check_out: "结帐？",
        tab_booking_share_status: "更改报告分享状态？",
        tab_booking_delete_report: "删除报告?",
        tab_booking_paid_title: "预先缴费？",
        tab_booking_pay_dialog_content: "收费总额 = 港币$ {{amount}} 从户口馀额扣除",
        tab_booking_pay_dialog_yes: "是",
        tab_booking_pay_dialog_no: "否",
        tab_booking_check_in: "挂号",
        tab_booking_check_out: "结帐",
        tab_booking_attended: "已出席",
        tab_booking_pay: "付款",
        tab_booking_paid: "已付款",
        tab_body_condition_table_column_ymd: "日期 (年月日)",
        tab_body_condition_table_column_bd: "体质",
        tab_family_table_column_id: "客户编号",
        tab_family_table_column_surname: "姓",
        tab_family_table_column_name: "名",
        tab_family_table_column_tel: "电话",
        tab_family_table_column_gender: "性别",
        tab_family_table_column_hkid: "身份证",
        tab_family_title: "相关家庭成员户口",
        tab_transaction_title: "交易记录",
        tab_transaction_table_column_date: "日期",
        tab_transaction_table_column_time: "时间",
        tab_transaction_table_column_staff: "由(员工编号)处理",
        tab_transaction_table_column_user: "职员",
        tab_transaction_table_column_action: "增值/扣除",
        tab_transaction_action_top_up: "增值",
        tab_transaction_action_deduct: "點數扣除",
        tab_transaction_action_normal:"普通支付",
        tab_transaction_action_times_deduct: "次卡扣除",
        tab_transaction_action_boss_deduct: "boss支付",
        tab_transaction_action_integral_deduct: "積分扣除",
        tab_transaction_action_refund: "套餐餘額回退",
        tab_transaction_table_column_service: "服务",
        tab_transaction_table_column_amount: "价格",
        tab_top_up_title: "户口结馀",
        tab_top_up_content_name: "姓名",
        tab_top_up_content_hkid: "身份证",
        tab_top_up_content_balance: "增值",
        tab_top_up_button_confirm: "确认",
        tab_transfer_title: "由个人／家庭户口转账至另一户口",
        tab_transfer_content_name: "姓名",
        tab_transfer_content_hkid: "身份证",
        tab_transfer_content_member: "客户编号",
        tab_transfer_content_current_family: "现时家庭编号",
        tab_transfer_content_transfer_family: "转账至",
        tab_transfer_content_transfer_placeholder: "输入家庭编号",
        tab_transfer_content_balance: "户口结馀转账",
        tab_transfer_button_confirm: "确认",
        error_board_title: "错误",
        error_board_button_ok: "确认",
        user_name: "{{name}} {{surname}}",
        user_all_title: "客户管理",
        user_all_table_column_id: "客户编号",
        user_all_table_column_surname: "姓氏",
        user_all_table_column_name: "名",
        user_all_table_column_tel: "电话号码",
        user_all_table_column_gender: "性别",
        user_all_table_column_hkid: "身份证",
        user_all_table_column_family_code: "家庭编号",
        user_all_table_column_family_balance: "家庭户口结馀",
        user_all_table_column_detail: "检视内容",
        user_all_button_add_user: "新增客户",
        user_all_search_bar_placeholder: "搜寻",
        user_view_title: "客户管理 > 检视客户资料",
        user_edit_item_id: "自动建立编号",
        user_feedback_title: "服务反馈",
        user_feedback_table_column_date: "日期",
        user_feedback_table_column_surname: "姓氏",
        user_feedback_table_column_name: "名",
        user_feedback_table_column_tel: "电话",
        user_feedback_table_column_center: "分店",
        user_feedback_table_column_appointment: "预约",
        user_feedback_table_column_staff_name: "职员姓名",
        user_feedback_table_column_general_rating: "整体评分",
        user_feedback_table_column_staff_rating: "职员评分",
        user_feedback_table_column_view_feedback: "检视顾客反馈",
        user_feedback_dialog_title: "服务反馈",
        user_feedback_dialog_title_date_time: "服务日子/时间",
        user_feedback_dialog_title_user_name: "Name",
        user_feedback_dialog_title_contact: "联络资料",
        user_feedback_dialog_title_center: "分店",
        user_feedback_dialog_title_appointment: " 预约",
        user_feedback_dialog_title_staff_name: "员工",
        user_feedback_dialog_title_overall: "整体",
        user_feedback_dialog_title_therapy: "治疗",
        user_feedback_dialog_title_note: "备注",
        booking_appointment_list: "預約列表",
        booking_table_column_date: "日期",
        booking_table_column_time: "时间",
        booking_table_column_user: "客户",
        booking_table_column_phone: "客户号码",
        booking_table_column_staff: "美容师",
        booking_table_column_server: "项目",
        booking_table_column_status: "状态",
        booking_table_column_action: "动作",
        booking_table_column_Issue_an_order: "出单",
        booking_table_download: "下載",
        booking_table_pay_boss: "老闆結算",
        booking_table_pay_normal: "普通結算",
        booking_table_pay_redeem: "積分兌換",
        booking_table_full_commission: "全佣",
        booking_table_settlement_method: "結算方式",
        booking_table_commission_ratio_selection: "佣金比選擇",
        booking_table_full_ninety_percentage_commission: "90%佣金",
        booking_revise_record: "预约列表修改记录",
        booking_revise_record_room_update: "房间修改",
        booking_revise_record_price_update: "价格修改",
        booking_revise_record_discount_update: "优惠修改",
        booking_revise_record_content_update: "修改内容",
        booking_revise_record_price: "價格",
        booking_revise_record_discount_type: "折扣類型",
        booking_revise_record_discount_price: "扣除金額",
        booking_revise_record_real_deal_price: "真實交易金額",
        booking_revise_record_update_date: "修改時間",
        booking_discount_type_relief: "減免金額",
        booking_discount_type_discount: "優惠打折",
        booking_discount_type_normal: "照常收費",
        staff_all_title: "所有员工",
        staff_all_table_column_id: "员工编号",
        staff_all_table_column_type: "员工类别",
        staff_all_table_column_surname: "姓氏",
        staff_all_table_column_name: "名",
        staff_all_table_column_mobile: "电话号码",
        staff_all_table_column_gender: "性别",
        staff_all_table_column_detail: "查阅内容",
        staff_all_table_column_schedule: "检视员工服务时间表",
        staff_all_button_add_staff: "新增员工",
        staff_all_search_bar_placeholder: "用员工编号/姓名搜寻",
        staff_detail_title: "员工管理 > 检视员工资料内容",
        staff_detail_tab_basic_info: "基本资料",
        staff_detail_tab_service_detail: "服务资料",
        staff_detail_cms_permission: "后台管理系统许可",
        staff_tab_basic_info_title_id: "员工*:",
        staff_tab_basic_info_title_surname: "姓*:",
        staff_tab_basic_info_title_name: "名*:",
        staff_tab_basic_info_title_gender: "性别*:",
        staff_tab_basic_info_title_hkid: "身份证*:",
        staff_tab_basic_info_title_tel: "电话*:",
        staff_tab_basic_info_title_email: "电邮*:",
        staff_tab_basic_info_title_address: "地址*:",
        staff_tab_basic_info_title_qualification: "资历许可（只供治疗师／医师):",
        staff_tab_basic_info_title_status: "状态*:",
        staff_tab_basic_info_status_working: "工作中",
        staff_tab_basic_info_status_resigned: "已辞职",
        staff_tab_basic_info_title_staff_type: "员工类别*:",
        staff_tab_basic_info_type_super_admin: "超级管理员",
        staff_tab_basic_info_type_admin: "管理员",
        staff_tab_basic_info_type_normal: "普通",
        staff_tab_basic_info_type_therapist: "医师",
        staff_tab_basic_info_type_beautician: "美容师",
        staff_tab_service_detail_title_center: "分店*：",
        staff_tab_service_detail_title_service_type: "服务分类*：",
        staff_tab_cms_permission_title_center: "分店资料",
        staff_tab_cms_permission_title_category: "服务分类",
        staff_tab_cms_permission_title_service: "服务资料",
        staff_tab_cms_permission_title_company: "关于康和乐",
        staff_tab_cms_permission_title_document: "条款及细则",
        staff_tab_cms_permission_title_health_tips: "健康小贴士",
        staff_tab_cms_permission_title_body_condition: "体质",
        staff_tab_cms_permission_title_notification: "推送通知",
        staff_tab_cms_permission_title_web: "网站",
        staff_tab_cms_permission_title_user: "客户资料",
        staff_tab_cms_permission_title_family: "相关家庭成员户口",
        staff_tab_cms_permission_title_reservation: "预约 (包括管理报告)",
        staff_tab_cms_permission_title_staff: "员工管理",
        staff_tab_cms_permission_title_schedule: "员工服务时间表",
        staff_tab_cms_permission_title_self: "员工资料",
        staff_tab_cms_permission_title_permission: "后台系统管理许可",
        staff_tab_cms_permission_title_press: "媒体报导",
        staff_tab_cms_permission_title_times: "次卡套餐管理",
        staff_tab_cms_permission_title_product: "产品管理",
        staff_tab_cms_permission_sub_title_hwl_center: "康和乐分店管理许可",
        staff_tab_cms_permission_sub_title_customers: "客户管理许可",
        staff_tab_cms_permission_sub_title_staff: "后台员工管理许可",
        staff_tab_cms_permission_no_permission: "没有权限",
        staff_tab_basic_info_edit_id: "自动建立编号",
        staff_tab_basic_info_edit_button_upload: "上载",
        staff_tab_basic_info_edit_button_reset: "重设密码",
        staff_tab_cms_permission_edit: "编辑",
        staff_tab_cms_permission_view: "检视",
        service_all_main_title: "服务",
        service_all_center_filter_title: "分店",
        service_all_center_filter_all: "所有",
        service_all_table_column_category: "分类",
        service_all_table_column_service_name: "服务名称：",
        service_all_table_column_status: "状态",
        service_all_table_column_session_interval: "服务时间：",
        service_all_table_column_session_price: "服务价格：",
        service_all_table_column_center: "分店",
        service_all_table_column_view_detail: "检视内容",
        service_all_button_add_new_service: "新增服务",
        service_detail_title_view: "服务管理 > 检视服务内容",
        service_detail_title_edit: "服务管理 > 编辑服务内容",
        service_detail_title_create: "服务管理 > 新增服务",
        service_detail_content_title_center: "分店：",
        service_detail_content_title_category: "所属分类：",
        service_detail_content_title_walk_in: "预约登记 / 非预约登记：",
        service_detail_content_value_reservation: "预约登记",
        service_detail_content_value_walk_in: "非预约登记 walk-in",
        service_detail_content_title_status: "状态",
        service_detail_content_value_inactive: "无效",
        service_detail_content_value_active: "有效",
        service_detail_content_title_images: "图片：",
        service_detail_content_title_video: "影片：",
        service_detail_content_value_video: "上载（最多一条影片）",
        service_detail_content_title_session_interval: "服务时节：",
        service_detail_content_value_minutes: "分钟",
        service_detail_content_title_price: "价钱：",
        center_all_main_title: "分店管理",
        center_all_button_add_new_center: "建立新分店",
        center_all_table_column_center: "分店",
        center_all_table_column_status: "状态",
        center_all_table_column_address: "地址",
        center_all_table_column_phone: "电话号码",
        center_all_table_column_opening: "营业时间",
        center_all_table_column_view: "检视资料",
        center_detail_title_view: "分店管理 > 检视分店资料",
        center_detail_title_edit: "分店管理 > 编辑分店",
        center_detail_title_create: "分店管理 > 建立新分店",
        center_detail_content_title_status: "状态：",
        center_detail_content_value_inactive: "无效",
        center_detail_content_value_active: "有效",
        center_detail_content_title_tel: "电话：",
        center_detail_content_title_opening_hour: "营业时间：",
        center_detail_content_value_open_time: "开店时间",
        center_detail_content_value_close_time: "关店时间",
        center_detail_content_title_address_coordinates: "地址坐标：",
        enter_detail_content_title_latitude: "纬度：",
        enter_detail_content_title_longitude: "经度：",
        center_detail_content_title_images: "图片：",
        staff_type_normal: "正常",
        staff_type_admin: "管理员",
        staff_type_super_admin: "超级管理员",
        staff_type_therapist: "治疗师／医师",
        company_detail_title_view: "关于康和乐 > 检视",
        company_detail_title_edit: "关于康和乐 > 修改",
        company_detail_content_value_video: "上载（最多一条影片）",
        company_detail_content_title_images: "图片：",
        company_detail_content_title_video: "影片：",
        document_detail_title_view: "条款及细则 > 检视",
        document_detail_title_edit: "条款及细则 > 修改",
        health_tips_all_main_title: "健康小贴士",
        health_tips_all_button_add: "新增文章",
        health_tips_all_table_title_date: "日期",
        health_tips_all_table_title_body_condition: "体质",
        health_tips_all_table_title_title: "标题",
        health_tips_all_table_title_bookmark_count: "收藏次数",
        health_tips_all_table_title_action: "操作",
        health_tips_main_title_add: "健康小贴士 > 新增文章",
        health_tips_main_title_edit: "健康小贴士 > 编辑文章",
        health_tips_title_date: "日期：",
        health_tips_sub_title_condition: "客户体质",
        health_tips_sub_title_slideshow: "标题图片集",
        health_tips_detail_content_title_images: "图片",
        health_tips_detail_content_title_video: "影片",
        health_tips_detail_content_value_video: "上载（最多一条影片）",
        health_tips_sub_title_article: "文章内容",
        press_all_main_title: "媒体报导",
        press_all_button_add: "新增报导",
        press_all_table_title_date: "日期",
        press_all_table_title_title: "标题",
        press_all_table_title_bookmark_count: "收藏次数",
        press_all_table_title_action: "操作",
        press_main_title_add: "媒体报导 > 新增报导",
        press_main_title_edit: "媒体报导 > 编辑报导",
        press_title_date: "日期：",
        press_sub_title_slideshow: "标题图片集",
        press_detail_content_title_images: "图片",
        press_detail_content_title_video: "影片",
        press_detail_content_value_video: "上载（最多一条影片）",
        press_sub_title_article: "报导内容",
        category_all_main_title: "分类",
        category_all_button_add_new_category: "新增分类",
        category_all_table_column_view_detail: "检视",
        category_all_table_column_category: "一级分类",
        category_all_table_column_category_tow: "二级分类",
        category_tow_name: "二级分类名称：",
        category_detail_content_title_images: "图片：",
        category_detail_title_view: "分类详细资料",
        category_detail_title_edit: "编辑分类",
        category_detail_title_create: "建立分类",
        notification_all_main_title: "推送讯息",
        notification_all_search_bar_placeholder: "使用标题内容搜寻",
        notification_all_table_column_title: "标题",
        notification_all_table_column_status: "状态",
        notification_all_status_pushed: "已推送",
        notification_all_status_un_sent: "未推送",
        notification_all_table_column_push_time: "推送讯息时间",
        notification_all_table_column_target: "目标",
        notification_all_table_column_action: "操作",
        notification_all_button_add_new_notification: "新增推送信息",
        notification_all_target_type_gender: "性别",
        notification_all_target_type_age_range: "年龄层",
        notification_all_target_type_body_condition: "体质",
        notification_all_target_type_users: "个别客户",
        notification_all_target_type_birthday: "生日",
        notification_all_target_type_all_users: "所有客户",
        notification_detail_title_view: "推送讯息内容",
        notification_detail_title_edit: "修改推送讯息",
        notification_detail_title_create: "建立推送讯息",
        notification_detail_content_title_images: "图片",
        notification_detail_content_title_target: "目标",
        notification_detail_content_title_push_time: "推送讯息时间",
        notification_detail_content_push_time_place_holder: "请输入日期",
        notification_detail_content_title_status: "状态",
        notification_detail_content_title_repeat: "每",
        notification_detail_content_repeat_per_day: "日重複",
        notification_detail_radio_all_users: "所有客户",
        notification_detail_radio_group: "类别",
        notification_detail_target_age_range: "年龄层：",
        notification_detail_target_gender: "性别：",
        notification_detail_gender_male: "男",
        notification_detail_gender_female: "女",
        notification_detail_target_birthday: "生日",
        notification_detail_birthday_message: "于",
        notification_detail_birthday_message_days: "日前推送提示",
        notification_detail_target_body_condition: "体质：",
        notification_detail_radio_users: "个别客户",
        notification_birthday_january: "一月",
        notification_birthday_february: "二月",
        notification_birthday_march: "三月",
        notification_birthday_april: "四月",
        notification_birthday_may: "五月",
        notification_birthday_june: "六月",
        notification_birthday_july: "七月",
        notification_birthday_august: "八月",
        notification_birthday_september: "九月",
        notification_birthday_october: "十月",
        notification_birthday_november: "十一月",
        notification_birthday_december: "十二月",
        room_all_main_title: "房间管理",
        room_all_table_column_center: "中心",
        room_all_table_column_title: "题目",
        room_all_table_column_service: "服务",
        room_all_table_column_status: "状态",
        room_all_table_column_view_detail: "详细资料",
        room_all_center_filter_title: "中心",
        room_all_center_filter_all: "全部",
        room_all_button_add_new_room: "新增房间",
        room_detail_title_edit: "编辑房间",
        room_detail_title_create: "创建房间",
        room_detail_content_title_title: "题目",
        room_detail_content_title_center: "中心",
        room_detail_content_title_service: "服务",
        room_detail_content_title_status: "状态",
        room_detail_content_value_active: "活动中",
        room_detail_content_value_inactive: "暂停",
        video_uploaded: "影片已上载！",
        confirm_dialog_title: "确认？",
        confirm_dialog_button_yes: "是",
        confirm_dialog_button_no: "否",
        web_homepage_title_view: '网页-预览',
        web_homepage_title_edit: '网页-编辑',
        web_homepage_title_images: '横幅图像',
        web_homepage_title_section_1: '第一节',
        web_homepage_title_section_2: '第二节',
        web_mobile_description_title_view: '网页 - 手机应用简介预览',
        web_mobile_description_title_edit: '网页 - 手机应用简介编辑',
        booking_overview_title: "预约概览",
        booking_overview_sub_title_center: "中心：",
        booking_overview_sub_title_service: "服务：",
        booking_overview_sub_title_therapist: "医师：",
        booking_overview_option_all: "所有",
        booking_overview_button_add_booking: "新增预约",
        add_booking_title: "新增预约",
        message_title: "预约资料",
        add_booking_button_back: "返回",
        add_booking_user_name: "预约客户*",
        add_booking_user_number: "客戶号码*",
        register_as_new_client: "登记成为新会员",
        add_booking_sub_title_appointment: "预约*",
        add_booking_sub_title_date: "日期/时间*",
        add_booking_sub_title_center: "中心",
        add_booking_sub_title_therapist: "医师",
        add_booking_sub_title_client: "客户",
        add_booking_sub_title_client_number: "联络号码",
        add_booking_remark: "备用",
        add_booking_option_any: "任何",
        add_booking_table_time: "时间",
        add_booking_table_therapist: "医师",
        add_booking_table_center: "中心",
        add_booking_table_price: "价钱",
        add_booking_table_action: "动作",
        add_booking_table_button_book: "预约",
        add_booking_success: "预约成功！",
        add_booking_failure: "预约失败！请检查塑眉师的时间表",
        staff_schedule_title: "职员工作行程 ＞ 设定工作行程以提供预约服务",
        staff_schedule_button_prev: "＜ 上一个",
        staff_schedule_button_next: "下一个 ＞",
        staff_schedule_button_add_schedule: "+ 工作行程",
        staff_schedule_button_cancel: "取消",
        staff_schedule_dialog_title: "新增工作行程",
        staff_schedule_dialog_center: "中心",
        staff_schedule_dialog_service: "服务",
        staff_schedule_dialog_time: "时间",
        staff_schedule_dialog_repeat: "将来重複使用？",
        staff_schedule_dialog_alldays: "所有日子",
        staff_schedule_dialog_date: "日期",
        staff_schedule_dialog_day: "日子",
        staff_schedule_dialog_day_sunday: "星期日",
        staff_schedule_dialog_day_monday: "星期一",
        staff_schedule_dialog_day_tuesday: "星期二",
        staff_schedule_dialog_day_wednesday: "星期三",
        staff_schedule_dialog_day_thursday: "星期四",
        staff_schedule_dialog_day_friday: "星期五",
        staff_schedule_dialog_day_saturday: "星期六",
        staff_schedule_dialog_start_date: "开始日期",
        staff_schedule_dialog_end_date: "结束日期",
        staff_schedule_dialog_add: "新增",
        staff_schedule_dialog_cancel: "取消",
        center: '中心',
        time_from: '开始时间',
        time_to: '结束时间',
        day_of_week: '日子',
        starting_date: '开始日期',
        end_date: '结束日期',
        date: '日期',
        user_inform_title: '客户资料',
        staff_tab_cms_permission_title_transaction: "交易(付款，增值)",
        view: '阅览',
        edit: '编辑',
        no_this_permission: '没有权限',
        remark: '备注：',
        type: '分类',
        schedule: '行程',
        active: '活跃',
        inactive: '无效',
        new: '新',
        family: '家庭',
        id: '编号',
        account: '户口',
        booking: '预约',
        inform: '资料',
        room: '房间',
        original: '原本的',
        available: '可提供的',
        exchange: '交换',
        target: '目标',
        update: '更新',
        delete: '删除',
        cancel: '取消',
        time: '时间',
        past: '过去',
        enable: '啓用',
        rebook: '重新预约',
        download_report: '下载报告',
        timeline: '时间表',
        schedule_create_unsuccessfully: '由于时间冲突，未能成功创建以下计划日程：',
        select_time: '选择时间',
        select_client_category: '请选择客户分类',

        edit_schedule: '$t(edit)$t(schedule)',
        past_time: '$t(past)$t(time)',
        original_room: '$t(original)$t(room)',
        available_room: '$t(available)$t(room)',
        exchange_room: '$t(exchange)$t(room)',
        exchange_booking_room: '$t(exchange)$t(booking)$t(room)',
        exchange_target: '$t(exchange)$t(target)',
        booking_inform: '$t(booking)$t(inform)',
        family_id: '$t(family)$t(id)',
        new_family_account: '$t(new)$t(family)$t(account)',
        past_time_enable: '$t(past_time)$t(enable)',
        delete_and_rebook: '$t(delete)及$t(rebook)',
        storage: '入库',
        details: '详情',
        repertoryIn: '入库',
        repertoryOut: '出库',
    },
    staff:{
        "beautician":"美容师",
        "therapist":"塑眉师",
    },
    error: {
        please_input_field: '请输入{{field}}',
        field_type_number: '{{field}}应为数字',
        max_min_correct: '请确定最高及最低值为正确！',
    }
};


