import React, { Component } from 'react';
import './tab-top-up.scss';
import { translate } from 'react-i18next';
import { Dialog, Button, Input, Textarea,Select } from 'element-react';
import * as familyApi from "../../../api/family.api";

@translate()
export default class UserTopUpTab extends Component {
    state = {
        amount: 0,
        confirmFunction: null,
        confirmMsg: null,
        confirmTitle: null,
        showConfirm: false,
        remark: '',
        payment:"visa",
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            }
        ],
    };

    async changeFamilyBalance(familyId, userId, amount, remark,payment) {
        await familyApi.updateFamily(familyId, userId, { amount,action:'Top-up', note: "top-up", remark,payment });
        this.setState({ amount: 0, remark: '' });
        this.props.refresh();
    }

    render() {
        const { t, user } = this.props;
        const { amount, confirmTitle, showConfirm, confirmMsg, confirmFunction, remark,payment } = this.state;
        return (
            <div className="customer-top-up-tab">
                <div className="main-title">
                    {t('tab_top_up_title')}
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_top_up_content_name')}
                    </div>
                    <div className="value">
                        {user.name} {user.surname}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_top_up_content_hkid')}
                    </div>
                    <div className="value">
                        {user.hkid}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        余额
                    </div>
                    <div className="value">
                        {user.family.balance}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        总交易额
                    </div>
                    <div className="value">
                        {user.family.totalBalance}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_top_up_content_balance')}
                    </div>
                    <div className="value">
                        HK$<Input type="text" value={amount} onChange={(value) => this.setState({ amount: value })} /><br />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('remark')}
                    </div>
                    <div className="value">
                        <Input type="textarea" value={remark} onChange={(value) => this.setState({ remark: value })} />
                    </div>
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.setState({
                        confirmFunction: () => this.changeFamilyBalance(user.familyId, user.id, amount, remark,payment),
                        showConfirm: true,
                    })}>{t('tab_top_up_button_confirm')}</Button>
                </div>
                <Dialog
                    size={'tiny'}
                    title={confirmTitle || t('confirm_dialog_title')}
                    visible={showConfirm}
                    onCancel={() => this.setState({
                        confirmMsg: null,
                        confirmTitle: null,
                        showConfirm: false,
                    })}
                >
                    <div className="dialog">
                        <div className="dialog-body">
                            {confirmMsg || null}
                            <Select value={payment} onChange={(value) => this.setState({payment:value})} placeholder="請選擇">
                                {
                                    this.state.payTypeOptions.map(el => {
                                        return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                            </Select>
                            <div className="row">
                                <div className="row">
                                    <Button type="primary" onClick={() => {
                                        confirmFunction()
                                        this.setState({
                                            confirmMsg: null,
                                            confirmTitle: null,
                                            showConfirm: false,
                                        });
                                    }}>{t('confirm_dialog_button_yes')}</Button>
                                    <Button onClick={() => this.setState({
                                        confirmMsg: null,
                                        confirmTitle: null,
                                        showConfirm: false,
                                    })}>{t('confirm_dialog_button_no')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
