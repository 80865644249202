import axios from "axios";
import store from './redux/store';
import { refreshAccessToken } from "./redux/staff/staff.action"
import history from './history';
import { setError } from "./redux/staff/error.action"
import { Message } from 'element-react';

// export const baseURL = 'https://pos-api-aws.miuseebeauty.com';
// export const baseURL = 'http://localhost:8000';//  接口地址
export const baseURL = 'https://dev-api.miuseebeauty.com/';// 测试 ~ 接口地址

const client = axios.create({
    baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

const interceptConfig = (requestConfig) => {
    const stateTree = store.getState();
    const { accessToken, refreshToken } = stateTree.session.session;
    if (accessToken) {
        requestConfig.refreshToken = refreshToken;
        requestConfig.headers = requestConfig.headers || {};
        requestConfig.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    requestConfig.headers['Access-Control-Allow-Origin'] = '*';
    requestConfig.params = requestConfig.params || {};
    requestConfig.params.languageId = stateTree.language.languageId;
    return requestConfig;
};

client.interceptors.request.use(interceptConfig);

client.interceptors.response.use((response) => response, async (err) => {
    // try refresh token
    console.log("fuck errr here")
    console.log(err.config)     //request
    console.log(err.request)    //response
    if (err.request && err.request.status === 401) {
        if (err.request.response) {
            var data = JSON.parse(err.request.response);
            if (data.data && data.errorCode === "ACCESS_TOKEN_EXPIRED" && err.config.url !== err.config.baseURL + '/auth/staffTokens') {
                if (!err.config._retryAfterRefreshToken) {
                    try {
                        await store.dispatch(refreshAccessToken(err.config.refreshToken));

                        err.config._retryAfterRefreshToken = true;
                        return axios(interceptConfig(err.config));
                    } catch (e) {
                        history.push('/login');
                    }
                }
            }
        } else {
            history.push('/login');
        }
    } else if (err.request && err.request.status === 409) {
        return Promise.reject(err.response.data)
    }

    if (err.response.status === 500) {
        let error = {}
        error.userMessage = err.response.statusText
        return Promise.reject(error)
    }

    if (err.request && err.request.response) {
        let data = JSON.parse(err.request.response);
        if (data.data) {
            store.dispatch(setError(err.response.data.userMessage));
            return Promise.reject(err.response.data);
        }
    }

    return Promise.reject(err.response.data);
});

export default client;