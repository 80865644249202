
export default {
    translation: {
        main_tab_report_management_balance:"點數支付",
        main_tab_report_management_integral:"積分支付",
        main_tab_report_management_boss:"老闆代付",
        main_tab_report_management_normal:"普通支付",
        main_tab_report_management_times:"次卡支付",
        main_tab_product_management: 'product management',
        user_board_edit_classification: 'consumption classification：',
        staff_tab_consumption_time: 'consumption time',
        commission_record: 'commission',
        pos_Trading_price: 'Trading price',
        pos_Amount_of_relief: 'Amount of relief',
        pos_Project_original_price: 'Original price',
        pos_number: 'quantity',
        pos_project_name: 'project name',
        Point_Expenses_record: 'Expenses record',
        Point_consumption_record: "Point consumption record",
        booking_table_deduct_points: 'deduct points',
        booking_table_Customer_Number: 'Customer Number',
        checked: 'checked',
        waiting: 'waiting',
        unchecked: 'unchecked',
        male: 'male',
        female: 'Female',
        service_detail_content_integral: 'Service Credits：',
        Category_Name: 'Category Name：',
        English_version: 'English version：',
        Chinese_Version: 'Chinese Version：',
        secondary_classification_details: 'Secondary classification details',
        Owning_parent: 'Owning parent：',
        please_choose: 'please choose',
        please_select_a_language: 'Please select a language',
        first_class_classification_name: 'First-class classification name',
        borad_category_description: 'Category Description',
        service_secondary_classification_table: 'Secondary classification table：',
        oneClass: "primary classification",
        towClass: "Secondary classification",
        save: 'save',
        cht: 'Chinese',
        eng: 'English',
        base_service_color: 'Related Colors*:',
        board_service_color: 'Related Colors：',
        board_service_description: 'Service description：',
        board_precautions: 'Precautions：',
        you_sure_delete: 'You sure you want to delete it?',
        image_board_title: 'related pictures：',
        image_base_title: 'related pictures*:',
        operate: "Operate",
        button_edit: 'Edit',
        button_save: 'Save',
        button_create: 'Create',
        button_view: "View",
        button_delete: "Delete",
         button_update:"Update",
        login_title: 'Miusee - Content Management System',
        login_staff_id: 'Staff ID',
        login_password: 'Password',
        login_button: 'Login',
        main_tab_book: 'Booking Overview',
        main_tab_booking_timetable: 'Timetable',
        main_tab_booking_list: 'List',
        main_tab_booking_revise_record: "Revise Record",
        main_tab_booking: 'Booking',
        main_tab_customer_management: 'Customer Management',
        main_tab_customer_summary: 'Customer Summary',
        main_tab_customer_all: 'All Registered Customer',
        main_tab_service_feedback: 'Service Feedback',
        main_tab_staff_management: 'Staff Management',
        main_tab_staff_all: 'All Staff',
        main_tab_staff_holiday: 'Staff Holiday',
        main_tab_staff_schedule: 'Staff Schedule Settings',
        main_tab_service_management: 'Service Management',
        main_tab_center_management: 'Center Management',
        main_tab_room_management: 'Room Management',
        main_tab_about_hwl: 'About HWL',
        main_tab_terms_conditions: 'Terms and Conditions',
        main_tab_health_tips: 'Health Tips',
        main_tab_press: 'Press',
        main_tab_push_notification: 'Push Notification',
        main_tab_web_management: 'Website Management',
        main_tab_web_management_homepage: 'Homepage',
        main_tab_web_management_mobile: 'Mobile App Description',
        main_tab_category_management: 'Category Management',
        main_tab_report_management: 'Report Management',
        main_pop_button_change_password: 'Change Password',
        main_pop_button_log_out: 'Logout',
        change_password_panel_title: 'Change Password',
        change_password_original_password: 'Original Password',
        change_password_new_password: 'New Password',
        change_password_confirm_new_password: 'Confirm New Password',
        change_password_submit: 'Submit',
        user_summary_title: 'Search for customer summary (using ID/HKID/phone number)',
        user_summary_search_bar_placeholder: 'Enter membership id, surname, name, phone number of hkid to search for user account summary',
        user_search_item_member_id: 'Membership ID:',
        user_search_item_name: 'Name:',
        user_search_item_gender: 'Gender:',
        user_search_item_hkid: 'HKID:',
        user_search_item_tel: 'Tel:',
        user_board_view_member_id: 'Membership ID:',
        user_board_view_name: 'Name:',
        user_board_view_gender: 'Gender:',
        user_board_view_hkid: 'HKID：',
        user_board_view_tel: 'Tel:',
        user_board_view_remark: 'Remark:',
        user_board_view_surname: 'Surname:',
        user_board_view_birthday: 'DOB:',
        user_board_view_family_id: 'Family ID:',
        user_board_view_email: 'Email:',
        user_board_view_address: 'Address:',
        user_board_view_family_balance: 'Current Family Balance:',
        user_board_view_photo: 'Photo:',
        user_board_view_body_condition: 'Body Condition:',
        user_board_edit_member_id: 'Membership ID:',
        user_board_edit_name: 'Name*:',
        user_board_edit_gender: 'Gender:',
        user_board_edit_hkid: 'HKID:',
        user_board_edit_tel: 'Tel*:',
        user_board_edit_surname: 'Surname*:',
        user_board_edit_birthday: 'DOB:',
        user_board_edit_family_id: 'Family ID:',
        user_board_edit_email: 'Email:',
        user_board_edit_address: 'Address:',
        user_board_edit_remark: 'Note/Remark:',
        user_board_edit_photo: 'Photo:',
        user_board_edit_body_condition: 'Body Condition:',
        user_board_edit_intentional_service: 'Intentional Service：',
        user_board_edit_consume_class: 'Consume Class',
        user_board_edit_customer_class: 'Customer Class：',
        user_board_edit_introducer: 'Introducer：',
        user_summary_customer_summary: 'Customer Summary',
        user_summary_tab_booking: 'View Booking',
        user_summary_tab_body_condition: 'Body Condition',
        user_summary_tab_family: 'Associated Family Account',
        user_summary_tab_transaction: 'Account Balance Transaction',
        user_summary_tab_top_up: 'Top-up',
        user_summary_tab_transfer: 'Transfer',
        tab_booking_result_limit_show: 'Show',
        tab_booking_result_limit_per: 'result per page',
        tab_booking_total_page: "Showing {{head}} to {{end}} of {{total}} entries",
        tab_booking_table_column_ymd: "Date (YMD)",
        tab_booking_table_column_time: "Time",
        tab_booking_table_column_center: "Center",
        tab_booking_table_column_category: "Category",
        tab_booking_table_column_service: "Service Name",
        tab_booking_table_column_doctor: "Therapist/Doctor",
        tab_booking_table_button_upload: "Upload",
        tab_booking_table_button_share: "Share",
        tab_booking_table_button_not_share: "Not Share",
        tab_booking_table_column_prescription: "Prescription",
        tab_booking_table_column_report: "Report",
        tab_booking_table_column_action: "Action",
        tab_booking_table_column_pay: "Pay",
        tab_booking_check_in_title: "Confirm Check-in/No Show?",
        tab_booking_check_in_date: "Date/Time",
        tab_booking_check_in_name: "Name",
        tab_booking_check_in_hkid: "HKID",
        tab_booking_check_in_appointment: "Appointment",
        tab_booking_check_in_therapist: "Therapist",
        tab_booking_check_in_balance: "Deduct from Family Balance",
        tab_booking_check_in_note: "Note",
        tab_booking_check_in_confirm: "Confirm",
        tab_booking_check_in_cancel: "Cancel",
        tab_booking_attended_title: "Booking Review",
        tab_booking_confirm_title_check_out: "Customer check out?",
        tab_booking_share_status: "Change report share status?",
        tab_booking_delete_report: "Delete report?",
        tab_booking_paid_title: "Pay in advance?",
        tab_booking_pay_dialog_content: "Amount = HK$ {{amount}} deduct from credit",
        tab_booking_pay_dialog_yes: "YES",
        tab_booking_pay_dialog_no: "NO",
        tab_booking_check_in: "Check-in",
        tab_booking_check_out: "Check-out",
        tab_booking_attended: "Attended",
        tab_booking_pay: "Pay",
        tab_booking_paid: "Paid",
        tab_body_condition_table_column_ymd: "Date (YMD)",
        tab_body_condition_table_column_bd: "Body Condition",
        tab_family_table_column_id: "ID",
        tab_family_table_column_surname: "Surname",
        tab_family_table_column_name: "Name",
        tab_family_table_column_tel: "Tel",
        tab_family_table_column_gender: "Gender",
        tab_family_table_column_hkid: "HKID",
        tab_family_title: "Associated Family Account",
        tab_transaction_title: "Transaction History",
        tab_transaction_table_column_date: "Date",
        tab_transaction_table_column_time: "Time",
        tab_transaction_table_column_staff: "Process by (Staff ID)",
        tab_transaction_table_column_user: "User",
        tab_transaction_table_column_action: "Top-up/Deduct",
        tab_transaction_action_top_up: "Top-up",
        tab_transaction_action_deduct: "Deduct",
        tab_transaction_action_normal:"Normal",
        tab_transaction_action_times_deduct: "Times Deduct",
        tab_transaction_action_boss_deduct: "Boss Pay",
        tab_transaction_action_integral_deduct: "Integral Deduct",
        tab_transaction_action_refund: "Package Refund",
        tab_transaction_table_column_service: "Service",
        tab_transaction_table_column_amount: "Amount",
        tab_top_up_title: "Top-up balance in account",
        tab_top_up_content_name: "Name",
        tab_top_up_content_hkid: "HKID",
        tab_top_up_content_balance: "Top-up Balance",
        tab_top_up_button_confirm: "Confirm",
        tab_transfer_title: "Transfer balance from one family account to another",
        tab_transfer_content_name: "Name",
        tab_transfer_content_hkid: "HKID",
        tab_transfer_content_member: "Member ID",
        tab_transfer_content_current_family: "Current Family ID",
        tab_transfer_content_transfer_family: "Transfer to",
        tab_transfer_content_transfer_placeholder: "input family ID",
        tab_transfer_content_balance: "Transfer Balance",
        tab_transfer_button_confirm: "Confirm",
        error_board_title: "Error",
        error_board_button_ok: "OK",
        user_name: "{{name}} {{surname}}",
        user_all_title: "Customer Management",
        user_all_table_column_id: "ID",
        user_all_table_column_surname: "Surname",
        user_all_table_column_name: "Name",
        user_all_table_column_tel: "Tel",
        user_all_table_column_gender: "Gender",
        user_all_table_column_hkid: "HKID",
        user_all_table_column_family_code: "Family Code",
        user_all_table_column_family_balance: "Family Balance",
        user_all_table_column_detail: "View Details",
        user_all_button_add_user: "Add new customer",
        user_all_search_bar_placeholder: "search",
        user_view_title: "Customer Management > View Customer Details",
        user_edit_item_id: "AUTO-GENERATED",
        user_feedback_title: "Service Feedback",
        user_feedback_table_column_date: "Date",
        user_feedback_table_column_surname: "Surname",
        user_feedback_table_column_name: "Name",
        user_feedback_table_column_tel: "Tel",
        user_feedback_table_column_center: "Center",
        user_feedback_table_column_appointment: "Appointment",
        user_feedback_table_column_staff_name: "Staff Name",
        user_feedback_table_column_general_rating: "General Rating",
        user_feedback_table_column_staff_rating: "Staff Rating",
        user_feedback_table_column_view_feedback: "View Feedback",
        user_feedback_dialog_title: "Feedback",
        user_feedback_dialog_title_date_time: "Service Date/Time",
        user_feedback_dialog_title_user_name: "Name",
        user_feedback_dialog_title_contact: "Contact",
        user_feedback_dialog_title_center: "Center",
        user_feedback_dialog_title_appointment: "Appointment",
        user_feedback_dialog_title_staff_name: "Staff",
        user_feedback_dialog_title_overall: "Overall",
        user_feedback_dialog_title_therapy: "Therapy",
        user_feedback_dialog_title_note: "Note",
        booking_appointment_list: "Appointment List",
        booking_table_column_date: "Data",
        booking_table_column_time: "Time",
        booking_table_column_user: "User",
        booking_table_column_phone: "Phone",
        booking_table_column_staff: "beautician",
        booking_table_column_server: "Service",
        booking_table_column_status: "Status",
        booking_table_column_action: "Action",
        booking_table_column_Issue_an_order: "Issue",
        booking_table_download: "DownLoad",
        booking_table_pay_boss: "Boss Pay",
        booking_table_pay_normal: "Normal Pay",
        booking_table_pay_redeem: "Redeem",
        booking_table_settlement_method: "Settlement Method",
        booking_table_commission_ratio_selection: "Commission Ratio Selection",
        booking_table_full_commission: "Full Commission",
        booking_table_full_ninety_percentage_commission: "90% Commission",
        booking_revise_record: "Appointment list modification record",
        booking_revise_record_room_update: "Update Room",
        booking_revise_record_price_update: "Update Price",
        booking_revise_record_discount_update: "Update Discount",
        booking_revise_record_content_update: "Update Content",
        booking_revise_record_price: "Price",
        booking_revise_record_discount_type: "Discount Type",
        booking_revise_record_discount_price: "Dicount Price",
        booking_revise_record_real_deal_price: "Real Deal Pirce",
        booking_revise_record_update_date: "Update Date",
        booking_discount_type_relief: "Relief",
        booking_discount_type_discount: "Discount",
        booking_discount_type_normal: "Normal",
        staff_all_title: "All Staff",
        staff_all_table_column_id: "Staff ID",
        staff_all_table_column_type: "Staff Type",
        staff_all_table_column_surname: "surname",
        staff_all_table_column_name: "name",
        staff_all_table_column_salary_commission: "Salary Commission",
        staff_all_table_column_expected_commission: "Expected Commission",
        staff_all_table_column_mobile: "Mobile Number",
        staff_all_table_column_gender: "Gender",
        staff_all_table_column_detail: "View Details",
        staff_all_table_column_schedule: "View Schedule",
        staff_all_button_add_staff: "Add new staff",
        staff_all_search_bar_placeholder: "search by staffId/ name",
        staff_detail_title: "Staff Management > View Detail",
        staff_commission_salary_title: "Staff Management > Salary Commission",
        staff_commission_expected_title: "Staff Management > Expected Commission",
        staff_detail_tab_basic_info: "Basic Info",
        staff_detail_tab_service_detail: "Service Detail",
        staff_detail_cms_permission: "CMS Permission",
        staff_detail_tab_salary_preview: "Salary Preview",
        staff_tab_basic_info_title_id: "Staff ID*:",
        staff_tab_basic_info_title_surname: "Surname*:",
        staff_tab_basic_info_title_name: "Name*:",
        staff_tab_basic_info_title_gender: "Gender*:",
        staff_tab_basic_info_title_hkid: "HKID*:",
        staff_tab_basic_info_title_tel: "Tel*:",
        staff_tab_basic_info_title_email: "Email*:",
        staff_tab_basic_info_title_address: "Address*:",
        staff_tab_basic_info_title_qualification: "Qualifications(for therapist/practitioner):",
        staff_tab_basic_info_title_status: "Status*:",
        staff_tab_basic_info_title_basic_salary: "Basic Salary*:",
        staff_tab_basic_info_title_divide_into: "Divided Into*:",
        staff_tab_basic_info_status_working: "Working",
        staff_tab_basic_info_status_resigned: "Resigned",
        staff_tab_basic_info_title_staff_type: "Staff Type*:",
        staff_tab_basic_info_type_super_admin: "Super Admin",
        staff_tab_basic_info_type_admin: "Admin",
        staff_tab_basic_info_type_normal: "Normal",
        staff_tab_basic_info_type_therapist: "Therapist",
        staff_tab_basic_info_type_beautician: "Beautician",
        staff_tab_service_detail_title_center: "Center*:",
        staff_tab_service_detail_title_service_type: "Service Type*:",
        staff_tab_service_detail_title_project: "Project",
        staff_tab_service_detail_title_price: "Price",
        staff_tab_service_detail_title_commission: "Commission",
        staff_tab_cms_permission_title_center: "Center Details",
        staff_tab_cms_permission_title_category: "Service Category",
        staff_tab_cms_permission_title_service: "Service Detail",
        staff_tab_cms_permission_title_company: "About Us",
        staff_tab_cms_permission_title_document: "Terms and Condition etc",
        staff_tab_cms_permission_title_health_tips: "Health Tips",
        staff_tab_cms_permission_title_body_condition: "Body Condition",
        staff_tab_cms_permission_title_notification: "Permission",
        staff_tab_cms_permission_title_web: "Website",
        staff_tab_cms_permission_title_user: "Customer Details",
        staff_tab_cms_permission_title_family: "Associated Family members",
        staff_tab_cms_permission_title_reservation: "Reservation (including report)",
        staff_tab_cms_permission_title_staff: "Staff Management",
        staff_tab_cms_permission_title_schedule: "Staff Schedule",
        staff_tab_cms_permission_title_self: "Staff own Detail",
        staff_tab_cms_permission_title_permission: "CMS Permission",
        staff_tab_cms_permission_title_press: "Press Articles",
        staff_tab_cms_permission_title_times: "Times managerment",
        staff_tab_cms_permission_title_product: "Product Managerment",
        staff_tab_cms_permission_sub_title_hwl_center: "HWL Center Management Permission",
        staff_tab_cms_permission_sub_title_customers: "Customers Management Permission",
        staff_tab_cms_permission_sub_title_staff: "Staff Management Permission",
        staff_tab_cms_permission_no_permission: "No permission",
        staff_tab_salary_commission: "Month To Date Commission",
        staff_tab_salary_expected_commission: "Estimated Remaining Commission",
        staff_tab_basic_info_edit_id: "AUTO-GENERATED",
        staff_tab_basic_info_edit_button_upload: "Upload",
        staff_tab_basic_info_edit_button_reset: "Reset Password",
        staff_tab_cms_permission_view: "View",
        service_all_main_title: "Service",
        service_all_center_filter_title: "Center",
        service_all_center_filter_all: "ALL",
        service_all_table_column_category: "Category",
        service_all_table_column_service_name: "Service Name：",
        service_all_table_column_status: "Status",
        service_all_table_column_session_interval: "Session Interval",
        service_all_table_column_session_price: "service fee：",
        service_all_table_column_center: "Center",
        service_all_table_column_view_detail: "View Detail",
        service_all_button_add_new_service: "Add new service",
        service_detail_title_view: "Service Management > View Service Detail",
        service_detail_title_edit: "Service Management > Edit Service Detail",
        service_detail_title_create: "Service Management > Create New Service",
        service_detail_content_title_center: "Center:",
        service_detail_content_title_category: "Category:",
        service_detail_content_title_walk_in: "Reservation / walk-in:",
        service_detail_content_value_reservation: "Reservation",
        service_detail_content_value_walk_in: "Walk-in",
        service_detail_content_title_status: "Status?",
        service_detail_content_value_inactive: "Inactive",
        service_detail_content_value_active: "Active",
        service_detail_content_title_images: "Images:",
        service_detail_content_title_video: "Video:",
        service_detail_content_value_video: "Upload(1 video max)",
        service_detail_content_title_session_interval: "Session Interval:",
        service_detail_content_value_minutes: "minutes",
        service_detail_content_title_price: "Price:",
        center_all_main_title: "Center Management",
        center_all_button_add_new_center: "Add Center",
        center_all_table_column_center: "Center",
        center_all_table_column_status: "Status",
        center_all_table_column_address: "Address",
        center_all_table_column_phone: "Phone Number",
        center_all_table_column_opening: "Opening Hours",
        center_all_table_column_view: "View Details",
        center_detail_title_view: "Center Management > View Center",
        center_detail_title_edit: "Center Management > Edit Center",
        center_detail_title_create: "Center Management > New Center",
        center_detail_content_title_status: "Status:",
        center_detail_content_value_inactive: "Inactive",
        center_detail_content_value_active: "Active",
        center_detail_content_title_tel: "Tel:",
        center_detail_content_title_opening_hour: "Opening Hours:",
        center_detail_content_value_open_time: "Opening Time",
        center_detail_content_value_close_time: "Close Time",
        center_detail_content_title_address_coordinates: "Address Coordinates:",
        enter_detail_content_title_latitude: "Latitude:",
        enter_detail_content_title_longitude: "Longitude:",
        staff_type_normal: "Normal",
        staff_type_admin: "Admin",
        staff_type_super_admin: "Super Admin",
        staff_type_therapist: "Therapist",
        company_detail_title_view: "About Hong Wo Lok > View",
        company_detail_title_edit: "About Hong Wo Lok > Edit",
        company_detail_content_value_video: "Upload(1 video max)",
        company_detail_content_title_images: "Images:",
        company_detail_content_title_video: "Video:",
        document_detail_title_view: "Terms and Conditions > View",
        document_detail_title_edit: "Terms and Conditions > Edit",
        health_tips_all_main_title: "Health Tips Articles",
        health_tips_all_button_add: "Add new article",
        health_tips_all_table_title_date: "Date",
        health_tips_all_table_title_body_condition: "Body Condition",
        health_tips_all_table_title_title: "Title",
        health_tips_all_table_title_bookmark_count: "Bookmark Count",
        health_tips_all_table_title_action: "Action",
        health_tips_main_title_add: "Health Tips Articles > Add New Post",
        health_tips_main_title_edit: "Health Tips Articles > Edit Post",
        health_tips_title_date: "Date:",
        health_tips_sub_title_condition: "User Body Condition",
        health_tips_sub_title_slideshow: "Header Slideshow",
        health_tips_detail_content_title_images: "Images",
        health_tips_detail_content_title_video: "Video",
        health_tips_detail_content_value_video: "Upload(1 video max)",
        health_tips_sub_title_article: "Article Content",
        press_all_main_title: "Press Articles",
        press_all_button_add: "Add new press",
        press_all_table_title_date: "Date",
        press_all_table_title_title: "Title",
        press_all_table_title_bookmark_count: "Bookmark Count",
        press_all_table_title_action: "Action",
        press_main_title_add: "Press Articles > Add New Post",
        press_main_title_edit: "Press Articles > Edit Post",
        press_title_date: "Date:",
        press_sub_title_slideshow: "Header Slideshow",
        press_detail_content_title_images: "Images",
        press_detail_content_title_video: "Video",
        press_detail_content_value_video: "Upload(1 video max)",
        press_sub_title_article: "Article Content",
        category_all_main_title: "Category",
        category_all_button_add_new_category: "ADD new Category",
        category_all_table_column_view_detail: "View",
        category_all_table_column_category: "primary classification",
        category_all_table_column_category_tow: "Secondary classification",
        category_tow_name: "Secondary classification name：",
        category_detail_content_title_images: "Image:",
        category_detail_title_view: "Category Detail",
        category_detail_title_edit: "Category Edit",
        category_detail_title_create: "Category Create",
        notification_all_main_title: "Push Notification",
        notification_all_search_bar_placeholder: "search by message title",
        notification_all_table_column_title: "Title",
        notification_all_table_column_status: "Status",
        notification_all_status_pushed: "Pushed",
        notification_all_status_un_sent: "Unsent",
        notification_all_table_column_push_time: "Push Time",
        notification_all_table_column_target: "Targets",
        notification_all_table_column_action: "Action",
        notification_all_button_add_new_notification: "Add Notification",
        notification_all_target_type_gender: "Gender",
        notification_all_target_type_age_range: "Age Range",
        notification_all_target_type_body_condition: "Body Condition",
        notification_all_target_type_users: "Users",
        notification_all_target_type_birthday: "Birthday",
        notification_all_target_type_all_users: "All Users",
        notification_detail_title_view: "Push Notification Detail",
        notification_detail_title_edit: "Edit Push Notification",
        notification_detail_title_create: "Create Push Notification",
        notification_detail_content_title_images: "Image",
        notification_detail_content_title_target: "Target",
        notification_detail_content_title_push_time: "Push Time",
        notification_detail_content_push_time_place_holder: "Please input a date",
        notification_detail_content_title_status: "Status",
        notification_detail_content_title_repeat: "Repeat Every",
        notification_detail_content_repeat_per_day: "Days",
        notification_detail_radio_all_users: "All Users",
        notification_detail_radio_group: "Group",
        notification_detail_target_age_range: "Age Range:",
        notification_detail_target_gender: "Gender:",
        notification_detail_gender_male: "Male",
        notification_detail_gender_female: "Female",
        notification_detail_target_birthday: "Birthday:",
        notification_detail_birthday_message: "Give notification before",
        notification_detail_birthday_message_days: "day(s)",
        notification_detail_target_body_condition: "Body Condition:",
        notification_detail_radio_users: "Users",
        notification_birthday_january: "January",
        notification_birthday_february: "February",
        notification_birthday_march: "March",
        notification_birthday_april: "April",
        notification_birthday_may: "May",
        notification_birthday_june: "June",
        notification_birthday_july: "July",
        notification_birthday_august: "August",
        notification_birthday_september: "September",
        notification_birthday_october: "October",
        notification_birthday_november: "November",
        notification_birthday_december: "December",
        room_all_main_title: "Room Management",
        room_all_table_column_center: "Center",
        room_all_table_column_title: "Title",
        room_all_table_column_service: "Service(s)",
        room_all_table_column_status: "Status",
        room_all_table_column_view_detail: "View Detail",
        room_all_center_filter_title: "Center",
        room_all_center_filter_all: "ALL",
        room_all_button_add_new_room: "Add new room",
        room_detail_title_edit: "Room Edit",
        room_detail_title_create: "Room create",
        room_detail_content_title_title: "Title",
        room_detail_content_title_center: "Center",
        room_detail_content_title_service: "Service",
        room_detail_content_title_status: "Status",
        room_detail_content_value_active: "Active",
        room_detail_content_value_inactive: "Inactive",
        video_uploaded: "Video Upload!",
        confirm_dialog_title: "Confirm?",
        confirm_dialog_button_yes: "Yes",
        confirm_dialog_button_no: "No",
        web_homepage_title_view: 'Web - Homepage View',
        web_homepage_title_edit: 'Web - Homepage Edit',
        web_homepage_title_images: 'Banner Images',
        web_homepage_title_section_1: 'Section 1',
        web_homepage_title_section_2: 'Section 2',
        web_mobile_description_title_view: 'Web - Mobile App Description View',
        web_mobile_description_title_edit: 'Web - Mobile App Description Edit',
        booking_overview_title: "Booking Overview",
        booking_overview_sub_title_center: "Center:",
        booking_overview_sub_title_service: "Service:",
        booking_overview_sub_title_therapist: "Therapist:",
        booking_overview_option_all: "All",
        booking_overview_button_add_booking: "Add booking",
        add_booking_title: "Add booking",
        message_title: "Appointment information",
        add_booking_button_back: "Back",
        add_booking_user_name: "Client name",
        add_booking_user_number: "Client number",
        register_as_new_client: "Registered as a new client",
        add_booking_sub_title_appointment: "Appointment*",
        add_booking_sub_title_date: "Date/Time*",
        add_booking_sub_title_center: "Center",
        add_booking_sub_title_therapist: "Therapist",
        add_booking_sub_title_client: "Client",
        add_booking_sub_title_client_number: "Contact Number",
        add_booking_remark: "Remark",
        add_booking_option_any: "Any",
        add_booking_table_time: "Time",
        add_booking_table_therapist: "Therapist",
        add_booking_table_center: "Center",
        add_booking_table_price: "Price",
        add_booking_table_action: "Action",
        add_booking_table_button_book: "Book",
        add_booking_success: "Success!",
        add_booking_failure: "Error! Please check if there is any time conflict",
        staff_schedule_title: "Staff Scheduling > Set Schedule available for booking",
        staff_schedule_button_prev: "< Prev",
        staff_schedule_button_next: "Next >",
        staff_schedule_button_edit_selected: "Edit Selected",
        staff_schedule_button_add_schedule: "+ Schedule",
        staff_schedule_button_cancel: "Cancel",
        staff_schedule_dialog_title: "Add Schedule",
        staff_schedule_dialog_center: "Center",
        staff_schedule_dialog_service: "Service",
        staff_schedule_dialog_time: "Time",
        staff_schedule_dialog_repeat: "Repeat in future?",
        staff_schedule_dialog_alldays: "All Days",
        staff_schedule_dialog_date: "Date",
        staff_schedule_dialog_day: "Day",
        staff_schedule_dialog_month: "Month",
        staff_schedule_dialog_day_sunday: "Sunday",
        staff_schedule_dialog_day_monday: "Monday",
        staff_schedule_dialog_day_tuesday: "Tuesday",
        staff_schedule_dialog_day_wednesday: "Wednesday",
        staff_schedule_dialog_day_thursday: "Thursday",
        staff_schedule_dialog_day_friday: "Friday",
        staff_schedule_dialog_day_saturday: "Saturday",
        staff_schedule_dialog_start_date: "Starting Date",
        staff_schedule_dialog_end_date: "End Date",
        staff_schedule_dialog_add: "Add",
        staff_schedule_dialog_cancel: "Cancel",
        center: 'center',
        time_from: 'start time',
        time_to: 'end time',
        day_of_week: 'day of week',
        starting_date: 'start date',
        end_date: 'end date',
        date: 'date',
        user_inform_title: 'User Information',
        center_detail_content_title_email: 'Email',
        staff_tab_service_detail_title_staff_type: "Type",
        staff_tab_cms_permission_title_transaction: "Transaction(Payment, Top-up)",
        view: 'View',
        edit: 'Edit',
        no_this_permission: 'No this permission',
        remark: 'Remark：',
        type: 'Type',
        schedule: 'Schedule',
        active: 'Active',
        inactive: 'Inactive',
        new: 'New',
        family: 'Family',
        account: 'Account',
        id: 'ID',
        booking: 'Booking',
        inform: 'Inform',
        room: 'Room',
        original: 'Original',
        available: 'Available',
        exchange: 'Exchange',
        target: 'Target',
        update: 'Update',
        delete: 'Delete',
        cancel: 'Cancel',
        time: 'Time',
        past: 'Past',
        enable: 'Enable',
        rebook: 'Rebook',
        download_report: 'Download Report',
        timeline: 'Timeline',
        schedule_create_unsuccessfully: 'The following schedules are not created successfully due to time conflict:',
        select_time: 'Select time',
        select_client_category: 'Please select customer category',

        edit_schedule: '$t(edit) $t(schedule)',
        past_time: '$t(past) $t(time)',
        original_room: '$t(original) $t(room)',
        available_room: '$t(available) $t(room)',
        exchange_room: '$t(exchange) $t(room)',
        exchange_booking_room: '$t(exchange) $t(booking) $t(room)',
        exchange_target: '$t(exchange) $t(target)',
        booking_inform: '$t(booking) $t(inform)',
        family_id: '$t(family) $t(id)',
        new_family_account: '$t(new) $t(family) $t(account)',
        past_time_enable: '$t(past_time) $t(enable)',
        delete_and_rebook: '$t(delete) & $t(rebook)',
        input_color: 'Enter the color',
        storage: 'storage',
        details: 'details',
        repertoryIn: 'in',
        repertoryOut: 'out',
    },
    error: {
        please_input_field: 'Please input {{field}}',
        field_type_number: '{{field}} must be a number',
        max_min_correct: 'Please make sure the minimum and maximum value are correct!',
    }
};
