import React, { Component } from 'react';
import "./index.scss"
import { Form, Input, Layout,Select,Button,Message } from 'element-react';
import PageTable from "../../../components/page-table.component";
import ResultLimit from "../../../components/result-limit.component";
import AddTimesPackagesComponent from './add-times-packages'
import { translate } from 'react-i18next';
import * as packagesApi from "../../../api/servicePackages";
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'

@translate()
export default class TimesComboManagement extends Component {
    state = {
        search:{
            page:1,
            limit:40
        },
        count:0,
        data:[],
        columns:[
            {
                label: "套餐名稱",
                prop: "name",
            },
            {
                label: "套餐包含服務",
                render:(row)=>{
                    { return row.service_time_cards.map(item => {
                        let services = item.containService.map(i => i.name)
                        return (
                            <div className='table-colum' key={item.id}>
                                <p>{services.join(" / ")}</p> 
                                <p>次數： {item.times}</p>
                                <p>單價：{item.price}</p>
                            </div>
                        )
                    })}
                }
            },
            {
                label: "套餐價格",
                prop: "price",
            }
        ],
        loading:false,
        visible:false,
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, { [key]: value })
        });
    }

    setCurrentPage(page) {
        this.setState({
            search: {
                ...this.state.search,
                page: page,
            }
        }, () => {
            this.getData()
        })
    }
    
    setResultLimit(limit) {
        this.setState({
            search: {
                ...this.state.search,
                limit: limit,
            }
        }, () => {
            this.getData()
        })
    }

    getData(){
        let query =  removeEmptyFiled(this.state.search)
        const {loading} = this.state
        if (loading) return
        this.setState({
            loading:true
        })
        packagesApi.list(query).then(result => {
            const {count , result:data} = result.data
            this.setState({
                count,
                data
            })
        }).catch(error => {
            Message.error(error)
            this.setState({
                loading:false
            })
        }).finally(()=>{
            this.setState({
                loading:false
            })
        })
    }

    componentDidMount(){
        this.getData()   
    }

    render() {
        const { search,count,data,columns,loading,visible } = this.state
        const { limit, page } = search
        return(
            <main className='times-combo-contanier'>
                <section className='times-combo-title'>{'次卡套餐管理'}</section>
                <section className='times-combo-header'>
                    <Form model={search} labelWidth="100" labelPosition='left'>
                        <Layout.Row gutter={20}>
                            <Layout.Col lg={8} md={8} sm={12} xm={24}>
                                <Form.Item prop="name" label='次卡名称'>
                                    <Input value={search.name} onChange={this.onChange.bind(this, 'name')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            {/* <Layout.Col lg={8} md={8} sm={12} xm={24}>
                                <Form.Item prop="service" label='服务'>
                                    <Select value={search.service}  onChange={this.onChange.bind(this, 'service')} clearable={true} style={{width:'100%'}}>
                                        <Select.Option label="male" value="male"></Select.Option>
                                        <Select.Option label="female" value="female"></Select.Option>
                                    </Select>
                                </Form.Item>
                            </Layout.Col> */}
                            <Layout.Col lg={8} md={8} sm={12} xm={24}>
                                    <Form.Item labelWidth={0}>
                                        <div className='submit-buttons'>
                                            <Button onClick={() => this.getData()}>查詢</Button>
                                            <Button onClick={() => this.setState({visible:true})}>新增次卡</Button>
                                        </div>
                                    </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='times-combo-main'>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <PageTable limit={limit} currentPage={page} totalResult={count} results={data} columns={columns} setCurrentPage={(page) => this.setCurrentPage(page)} loading={loading}/>
                </section>
                { visible ? <AddTimesPackagesComponent isClosed={ () => this.setState({visible: false})} success={ ()=> {this.setState({visible: false},()=>{this.getData()})}}/> : null }
            </main>
        )
    }
}