import {translate} from "react-i18next";
import React, {Component} from "react";
import {Button, Table} from "antd";
import {Form, Input, Layout} from "element-react"
import './product-order.scss'
import * as productOrderApi from "../../../../api/productOrder.api"
import CreateOrderDialog from "./create-order-dialog";
import OrderDetailsDialog from "./order-details-dialog";
import moment from "moment";

@translate()
export default class productList extends Component {
    state = {
        total: 100,
        tableData: [],
        orderDetailVisible: false,
        orderDetails:{},
        loading: false,
        visible: false,
        storageVisible : false,
        echoProductData: {},
        search: {
            page: 1,
            limit: 40,
            keywords: "",
            orders: [],
        },
        exportList: [
            {label: "产品编号", value: ''},
            {label: "產品名", value: ''},
            {label: "總存量", value: ''},
            {label: "產品圖片", value: ''},
        ],
        columns: [
            {
                title: '訂單編號',
                dataIndex: 'id',
                key: 'id',
                sorter: {
                    multiple: 1,
                },
            },
            {
                title: '用戶名',
                key: 'name',
                dataIndex: 'name',
                render:(_ , row) => {
                    return <div>{row.userSurname ? row.userSurname + row.username : row.username}</div>
                }
            },
            {
                title: '銷售人',
                key: 'repertory',
                dataIndex: 'repertory',
                render:(_ , row) => {
                    return <div>{row.staffSurname ? row.staffSurname + row.staffname : row.staffname}</div>
                }
            },
            {
                title: '銷售時間',
                key: 'date',
                dataIndex: 'date',
                sorter: {
                    multiple: 2,
                },
                render : (_,row) => {
                    return <div>{moment(row.date).format('YYYY-MM-DD HH:mm')}</div>
                }
            },
            {
                title: '操作',
                render: (_,row) => {
                    return <span>
                        <Button type='text' onClick={() => this.setState({orderDetails: row} , () => this.setState({orderDetailVisible: true}))}>{this.props.t('details')}</Button>
                    </span>
                }
            }
        ]
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, {[key]: value})
        });
    }

    pageAndOrder(pagination, filters, sorter, extra) {
        console.log(pagination)
        let orders = []
        if (sorter.length > 0) {
            sorter.forEach(item => {
                let obj = {
                    field: item.field,
                    orders: item.order == "ascend" ? "ASC" : "DESC"
                }
                orders.push(obj)
            });
        } else if (sorter.field) {
            orders.push({
                field: sorter.field,
                orders: sorter.order == "ascend" ? "ASC" : "DESC"
            })
        }
        this.setState({
            search: Object.assign({}, this.state.search, {
                orders,
                page: pagination.current,
                limit: pagination.pageSize
            })
        }, () => {
            this.getData()
        })
    }

    getData() {
        const {search} = this.state
        let query = {...search}
        this.setState({
            loading: true
        })

        productOrderApi.page(query).then(result => {
            const {data} = result
            this.state.tableData = data.data
            this.state.total = data.count
        }).catch(err => {
            this.setState({
                loading: false
            })
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.getData()
    }


    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }


    render() {
        const {search, columns, tableData, total , visible , storageVisible , echoProductData , orderDetailVisible , orderDetails} = this.state
        return (
            <main className='product-order-main'>
                <section className='search-info'>
                    <Form ref='form' model={search} labelWidth='75' labelPosition='left' className='rule-form'>
                        <Layout.Row gutter='20'>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="查詢框">
                                    <Input value={search.keywords} placeholder="請輸入"
                                           onChange={this.onChange.bind(this, 'keywords')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Button onClick={() => this.getData()}>查詢</Button>
                                <Button className='create-product' onClick={() => this.setState({visible: true})}>創建訂單</Button>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='repertory-table-container'>
                    <div className='repertory-table-main'>
                        <Table columns={columns} dataSource={tableData}
                               pagination={{"total": total, showSizeChanger: true}}
                               onChange={this.pageAndOrder.bind(this)}></Table>
                    </div>
                </section>
                {visible ? <CreateOrderDialog isClosed={() => this.setState({visible: false})} success={() => {
                    this.setState({visible: false}, () => {
                        this.getData()
                    })
                }}/> : null}

                {orderDetailVisible ? <OrderDetailsDialog isClosed={() => this.setState({orderDetailVisible: false})} orderDetails={orderDetails} /> : null}
            </main>
        )
    }
}