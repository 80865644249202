import client from '../apiClient';

export async function getFreeRooms(centerId, serviceId, startTime, endTime) {
    let response = await client.get('/reservation/free-rooms', {
        params: {
            centerId,
            serviceId,
            startTime,
            endTime
        },
    });
    return response.data;
}

export async function changeBookingRoom(reservationId, roomId) {
    let response = await client.patch(`/reservation/change-rooms/${reservationId}`, {
        roomId,
    });
    return response.data;
}

export async function getExchangeBooking(reservationId) {
    let response = await client.get(`/reservation/exchange-rooms/${reservationId}`);
    return response.data;
}

export async function getUserIntegral(obj) {
    let response = await client.get(`/users/integralConsumeList`, {
        params: obj
    });
    return response.data;
}

export async function exchangeBookingRoom(reservationId, targetBooking) {
    let response = await client.patch(`/reservation/exchange-rooms/${reservationId}`, {
        targetBooking,
    });
    return response.data;
}

export async function createReservation(targetUserId, bookUserId, staffScheduleIds, serviceId, bookingUser, remark, startTime, endTime,staffLevel,levelPrice,discountType,discount) {
    let response = await client.post('/reservation', {
        targetUserId,
        bookUserId,
        staffScheduleIds,
        serviceId,
        bookingUser,
        remark,
        startTime,
        endTime,
        staffLevel,
        levelPrice,
        discountType,
        discount
    })
    return response;
}
// reservation/1a88acc2-d212-4bd4-a93a-3065c1dd208/check-in


export async function updateReservation(reservationId, body) {
    let response = await client.patch(`/reservation/${reservationId}`, body);
    return response.data;
}

export async function cancelReservation(reservationId) {
    let response = await client.delete(`/reservation/${reservationId}`);
    return response.data;
}

export async function getDayReservations(startTime, endTime, filters) {
    let response = await client.get('/reservation/byDayl', {
        params: {
            startTime, endTime,
            ...filters,
        }
    });
    return response.data;
}

export async function getDayReservation(startTime, endTime, filters) {
    let response = await client.get('/reservation/byDay', {
        params: {
            startTime, endTime,
            ...filters
        }
    });
    return response.data;
}

export async function getUserReservations(userId, feedback, page, orderBy, direction, limit) {
    let response = await client.get('/reservation/cms', {
        params: {
            userId,
            // feedback,
            page,
            orderBy,
            direction,
            limit,
        }
    });
    return response.data;
}

export async function deleteReport(reservationId) {
    let response = await client.delete(`/reservation/${reservationId}/report`);
    return response.data;
}

export async function uploadReport(reservationId, report) {
    const formData = new FormData();
    formData.append("report", report);
    let response = await client.post(`/reservation/${reservationId}/report`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function deletePrescription(reservationId) {
    let response = await client.delete(`/reservation/${reservationId}/prescription`);
    return response.data;
}

export async function uploadPrescription(reservationId, prescription) {
    const formData = new FormData();
    formData.append("prescription", prescription);
    let response = await client.post(`/reservation/${reservationId}/prescription`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function checkIn(query) {
    let response = await client.patch(`/reservation/${query.reservationId}/check-in`, {...query});
    return response.data;
}

export async function batchCheckIn(data) {
    let response = await client.post(`/reservation/batch-check-in`, data);
    return response
}

export async function pay(reservationId, remark) {
    let response = await client.patch(`/reservation/${reservationId}/pay`, {
        remark,
    });
    return response.data;
}

export async function getReservationList(data) {
    let response = await client.get(`/reservation/list`, {
        params: data
    })
    return response.data
}

export async function getReservationRecords(data) {
    let response = await client.get('/reservation/reservationRecords', {
        params: data
    })
    return response.data
}

// 员工设置休息时添加预约代替回显
export async function createRest(data) {
    let response = await client.post('/reservation/insert', data)
    return response.data
}

export async function getReservationOverview(data) {
    let response = await client.get(`/reservation/overview`, {
        params: data
    })
    return response.data
}