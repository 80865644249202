import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {  FileExcelFilled } from '@ant-design/icons';
import { Button, Select, Form, Input , DatePicker, Col, Row, Typography,Table } from 'antd';
import "./record.scss"
import "./ant-picker-header.scss"
import moment from 'moment';
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import * as reportApi from "../../../api/report.api";
import { downloadExcel } from "../../../utils/execlUtils";

const { RangePicker } = DatePicker;


const { Text } = Typography;
@translate()
export default class RechargeReport extends Component {
    formRef = React.createRef();
    state={
        search:{
            date:[moment(new Date()),moment(new Date())]
        },
        loading:false,
        top:[],
        topColumns:[
            {
                title: '客户名称',
                width:200,
                render: (value, row) => {
                    return (<div>{row.userSurname} {row.userName}</div>)
                },
            },
            {
                title: '客户充值前的余额',
                width:200,
                render: (value, row) => {
                    return (<div>{parseFloat(row.beforeBalance)}</div>)
                },
            },
            {
                title: '充值金额',
                width:200,
                dataIndex: 'amount',
                key: 'amount',
                sorter: {
                    multiple: 1,
                    compare: (a, b) => a.amount - b.amount,
                },
            },
            {
                title: '客户余额',
                width:200,
                dataIndex: 'balance',
                key: 'balance',
                render: (value, row) => {
                    return (<div>{parseFloat(row.beforeBalance) + parseFloat(row.amount)}</div>)
                },
            },
            {
                title: '充值时间',
                width:200,
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (value, row) => {
                    return (<div>{moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>)
                },
                sorter: {
                    compare: (a, b) => a.createdAt - b.createdAt,
                    multiple: 2,
                },
            }
        ],
        deduct:[],
        deductColumns:[
            {
                title: '客户名称',
                width:200,
                render: (value, row) => {
                    return (<div>{row.userSurname} {row.userName}</div>)
                },
            },
            {
                title: '客户充值前的余额',
                width:200,
                render: (value, row) => {
                    return (<div>{parseFloat(row.beforeBalance)}</div>)
                },
            },
            {
                title: '消费金额',
                width:200,
                dataIndex: 'amount',
                key: 'amount',
                sorter: {
                    compare: (a, b) => a.amount - b.amount,
                    multiple: 1,
                },
            },
            {
                title: '消费的服务',
                width:200,
                dataIndex: 'serviceName',
                key: 'serviceName',
            },
            {
                title: '客户余额',
                width:200,
                dataIndex: 'balance',
                key: 'balance',
            },
            {
                title: '消费时间',
                width:200,
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (value, row) => {
                    return (<div>{moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>)
                },
                sorter: {
                    compare: (a, b) => a.createdAt - b.createdAt,
                    multiple: 2,
                },
            }
        ]
    }

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 
        if (search.birthday) {
            query.birthday = search.birthday.format('YYYY-MM') + "-01 00:00:00"
        }
        delete query.date

        return removeEmptyFiled(query)
    }

    getData(){
        this.setState({loading:true})

        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
        }

        if (data.startTime && data.endTime) {
            query.startTime = moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            query.endTime = moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        reportApi.topDeductRecordList(query).then(result => {
            const { data:{ data } } = result
            let top = data.filter((item,index) => {
                item.key = index + 1  
                return item.action == "Top-up"
            })
            let deduct = data.filter((item,index) => {
                item.key = index + 1
                return item.action == "Deduct"
            })
            this.setState({
                top,
                deduct
            })
        }).finally(()=>{
            this.setState({loading:false})
        })
    };

    onChange  (pagination, filters, sorter, extra){
        console.log(sorter)
    };

    componentDidMount(){
        this.getData()
    }

    render(){
        const { top,topColumns , deduct, deductColumns , loading } = this.state
        return (
            <main className='report-contanier'>
                <section className='search-contanier'>
                    <Form ref={this.formRef} initialValues={{ date:[moment(new Date()),moment(new Date())] }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                        <Row gutter={20}>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <Form.Item name="date" label="查詢日期">
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item name="gender" label="客人分類">
                                    <Select allowClear>
                                        <Select.Option value="male">男客</Select.Option>
                                        <Select.Option value="female">女客</Select.Option>
                                        <Select.Option value="null">其他</Select.Option>
                                    </Select>
                                </Form.Item> 
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item name="birthday" label="客户生日">
                                    <DatePicker picker="month" format={"MM"} popupClassName="month-main"/>
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        查詢
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </section>
                <section className='table-contanier'>
                    <div className='table-main'>
                        <Table scroll={{x:true,y:true}} columns={topColumns} dataSource={top} pagination={false} loading={loading} bordered={true}
                        title={(currentPageData) => {
                            let userMaps = {}
                            currentPageData.forEach(item => {
                                userMaps[item.userId] = userMaps[item.userId] || {
                                    id : item.userId,
                                    name : item.userSurname + item.userName,
                                    total : 0
                                }
                                userMaps[item.userId].total += parseFloat(item.amount)
                            })

                            let sortMaps =  Object.keys(userMaps).map(item => userMaps[item]).sort((a,b) => {
                                return a.total > b.total ? -1 : 1
                            })
                            return (
                                <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                    <div>
                                        <p style={{margin:0}}>客戶充值</p>
                                        {
                                            sortMaps.map((item,index) => {
                                                return (
                                                    <span key={index} style={{margin:"10px",color:"#f44447"}}>{index + 1}. {item.name} : {item.total}</span>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel("充值報表",topColumns,top)}></Button>
                                    </div>
                                </main>
                            )
                        }}
                        onChange={this.onChange.bind(this)}
                        ></Table>
                    </div>
                    <div className='table-main'>
                        <Table scroll={{x:true,y:true}} columns={deductColumns} dataSource={deduct} pagination={false} loading={loading} bordered={true}
                        title={(currentPageData) => {
                            let userMaps = {}
                            currentPageData.forEach(item => {
                                userMaps[item.userId] = userMaps[item.userId] || {
                                    id : item.userId,
                                    name : item.userSurname + item.userName,
                                    total : 0
                                }
                                userMaps[item.userId].total += parseFloat(item.amount)
                            })

                            let sortMaps =  Object.keys(userMaps).map(item => userMaps[item]).sort((a,b) => {
                                return a.total > b.total ? -1 : 1
                            })

                            return (
                                <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                    <div>
                                        <p style={{margin:0}}>客戶点数消费</p>
                                            {
                                                sortMaps.map((item,index) => {
                                                    return (
                                                        <span key={index} style={{margin:"10px",color:"#f44447"}}>{index + 1}. {item.name} : {item.total}</span>
                                                    )
                                                })
                                            }
                                    </div>
                                    <div>
                                       <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel("點數消費報表",deductColumns,deduct)}></Button>
                                    </div>
                                </main>
                            )
                        }}
                        onChange={this.onChange.bind(this)}
                        ></Table> 
                    </div>
                </section>
            </main>
        )
    }
}