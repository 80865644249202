import React, { Component } from 'react';
import './tab-service-detail-edit.scss';
import { translate } from 'react-i18next';
import { Checkbox, Button, Input, Select } from 'element-react';
import { Col, Row } from 'antd';

@translate()
export default class StaffServiceDetailEditTab extends Component {
    state = {
        orderServices:[],
        servicesFiliter:[],
        filterValue:'',
    }

    componentDidMount() {
        const { services }  = this.props 
        let categorys = {}
        services.forEach(element => {
            if(element.category) {
                categorys[element.categoryId] = categorys[element.categoryId] || {
                    categoryId:element.categoryId,
                    categoryTitle: element.category.title,
                    categoryChildren:[]
                }
            }
        });

        for (var i in categorys) {
            categorys[i].categoryChildren = services.filter(service => service && service.category && i == service.categoryId)
        }
        
        let orderServices = Object.keys(categorys).map(categoryId=> categorys[categoryId])

        let servicesFiliter = Object.keys(categorys).map(categoryId=> {
            return {
                value:categorys[categoryId].categoryId,
                label:categorys[categoryId].categoryTitle
            }
        })

        this.setState({
            orderServices,
            servicesFiliter
        })
    }

    filterServices(value){
        this.setState({filterValue:value})
    } 

    render() {
        const { t, centers, updateStaffCenter, updateStaffService, submit } = this.props;
        const { orderServices, filterValue} = this.state

        let catetroyData = orderServices
        
        if(filterValue) {
            catetroyData = orderServices.filter(category => category.categoryId == filterValue)
        }

        return (
             <div className="service-detail-tab-edit">
                <Row gutter={16} style={{margin:0}}>
                    <Col span={6} className="col-span" style={{padding:"10px 0"}}>
                        {t('staff_tab_service_detail_title_center')}
                    </Col>
                    <Col span={18} className="col-span" style={{padding:"10px 0"}}>
                        <div className="select-box">
                            {
                                centers.map((center, i) => {
                                    return (
                                        <div className="select-option" key={center.id}>
                                            <Checkbox checked={center.selected} onChange={() => updateStaffCenter(i)}>
                                                {center.center_details[0].name}
                                            </Checkbox>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Col>
                    <Col span={6} className="col-span" style={{padding:"10px 0"}}>
                        服務分類篩選:
                    </Col>
                    <Col span={18} className="col-span" style={{padding:"10px 0"}}>
                        <Select value={this.state.filterValue} clearable placeholder="请选择" onChange={(value)=> this.filterServices(value) }>
                            {
                                this.state.servicesFiliter.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                })
                            }
                        </Select>
                    </Col>
                    <Col span={6} className="col-span" style={{padding:"10px 0"}}>
                        {t('staff_tab_service_detail_title_service_type')}
                    </Col>
                    <Col span={18} className="col-span" style={{padding:"10px 0"}}>
                        <div className="select-box">
                            {
                                catetroyData&&catetroyData.map((category, index) => {
                                    return (
                                        <div key={index}>
                                            <p>{category.categoryTitle}</p>
                                            {category.categoryChildren.map((service,i)=>{
                                                return(
                                                    <div className="select-option" style={{marginBottom: '5px'}} key={service.id}>
                                                        <Checkbox checked={service.selected} onChange={() => updateStaffService(service.id)}>
                                                            {`${service.category.title}-${service.name}`}
                                                        </Checkbox>
                                                        <div className="space"></div>
                                                        <Input disabled={!service.selected} value={service.extraFee} onChange={(e) => updateStaffService(service.id, e)} />
                                                        <Input disabled={!service.selected} value={service.commission} onChange={(e) => updateStaffService(service.id, null, e)} />
                                                        <Input disabled={!service.selected} value={service.fixedCommission} onChange={(e) => updateStaffService(service.id, null,null, e)} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {/* <div className="tab-body">
                    <div className="row">
                        <div className="title">
                            {t('staff_tab_service_detail_title_center')}
                        </div>
                        <div className="value">
                            <div className="select-box">
                                {
                                    centers.map((center, i) => {
                                        return (
                                            <div className="select-option" key={center.id}>
                                                <Checkbox checked={center.selected} onChange={() => updateStaffCenter(i)}>
                                                    {center.center_details[0].name}
                                                </Checkbox>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            服務分類篩選:
                        </div>
                        <div className="value">
                            <Select value={this.state.filterValue} clearable placeholder="请选择" onChange={(value)=> this.filterServices(value) }>
                                {
                                    this.state.servicesFiliter.map(el => {
                                        return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('staff_tab_service_detail_title_service_type')}
                        </div>
                        <div className="value">
                            <div className="select-box">
                                {
                                    catetroyData&&catetroyData.map((category, index) => {
                                        return (
                                            <div key={index}>
                                                <p>{category.categoryTitle}</p>
                                                {category.categoryChildren.map((service,i)=>{
                                                    return(
                                                        <div className="select-option" style={{marginBottom: '5px'}} key={service.id}>
                                                            <Checkbox checked={service.selected} onChange={() => updateStaffService(service.id)}>
                                                                {`${service.category.title}-${service.name}`}
                                                            </Checkbox>
                                                            <div className="space"></div>
                                                            <Input disabled={!service.selected} value={service.extraFee} onChange={(e) => updateStaffService(service.id, e)} />
                                                            <Input disabled={!service.selected} value={service.commission} onChange={(e) => updateStaffService(service.id, null, e)} />
                                                            <Input disabled={!service.selected} value={service.fixedCommission} onChange={(e) => updateStaffService(service.id, null,null, e)} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="board-footer" style={{textAlign:"center"}}>
                    <Button onClick={() => submit()}>{t('button_save')}</Button>
                </div>
            </div> 
        );
    }
}