import client from '../apiClient';


export async function page(data) {
    let response = await client.post('/productOrder/page' , data)
    return response
}

export async function createOrder(data) {
    let response = await client.post('/productOrder/createOrder' , data)
    return response
}