import {translate} from "react-i18next";
import AccountIcon from "../../../../components/icon.component";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, {Component} from "react";
import {Button, Table, Modal} from "antd";
import {Form, Input, Layout} from "element-react"
import './product-list.scss'
import * as productApi from "../../../../api/product.api"
import AddProductDialog from "./add-product-dialog";
import StorageDialog from './storage-dialog'

const { confirm } = Modal;

@translate()
export default class productList extends Component {
    state = {
        total: 100,
        tableData: [],
        loading: false,
        visible: false,
        storageVisible : false,
        echoProductData: {},
        productDetail:null,
        search: {
            page: 1,
            limit: 40,
            keywords: "",
            orders: [],
        },
        exportList: [
            {label: "产品编号", value: ''},
            {label: "產品名", value: ''},
            {label: "總存量", value: ''},
            {label: "產品圖片", value: ''},
        ],
        columns: [
            {
                title: '產品編號',
                dataIndex: 'id',
                key: 'id',
                sorter: {
                    multiple: 1,
                },
            },
            {
                title: '產品名',
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: '總存量',
                key: 'repertory',
                dataIndex: 'repertory',
                sorter: {
                    multiple: 2,
                },
            },
            {
                title: '價格',
                key: 'price',
                dataIndex: 'price',
                render: (_,row) => {
                    return <div>{parseFloat(row.price).toFixed(2)}</div>
                },
                sorter: {
                    multiple: 3,
                },
            },
            {
                title: '產品圖片',
                key: 'icon',
                dataIndex: 'icon',
                render: (_,row) => {
                    return <div className='avatar'>
                        {
                            row.icon ? <AccountIcon className="icon" icon={row.icon}/> : ""
                        }
                    </div>
                }
            },
            {
                title: '操作',
                render: (_,row) => {
                    return <span>
                        <Button type='text' onClick={() => this.showDeleteModal(row.id)}>{this.props.t('button_delete')}</Button>
                        <Button type='text' onClick={() => this.setState({visible: true,productDetail:row})}>{this.props.t('button_update')}</Button>
                        <Button type='text' onClick={()=> this.setState({echoProductData : row},()=>{ this.setState({storageVisible:true}) }) }>{this.props.t('storage')}</Button>
                    </span>
                }
            }
        ]
    }

    showDeleteModal = (value) => {
        confirm({
            title: '刪除',
            icon: <ExclamationCircleOutlined />,
            content: '您確認要刪除該商品嗎？',
            okText: '確認',
            okType: 'danger',
            cancelText: '取消',
            onOk :() => {
                this.handleDelete(value)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    
    handleDelete(id) {
        productApi.deleteProduct(id).then(result => {
            console.log(result)
        }).catch(error => {
            console.log(error)
        }).finally(()=>{
            this.getData()
        })
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, {[key]: value})
        });
    }

    pageAndOrder(pagination, filters, sorter, extra) {
        console.log(pagination)
        let orders = []
        if (sorter.length > 0) {
            sorter.forEach(item => {
                let obj = {
                    field: item.field,
                    orders: item.order == "ascend" ? "ASC" : "DESC"
                }
                orders.push(obj)
            });
        } else if (sorter.field) {
            orders.push({
                field: sorter.field,
                orders: sorter.order == "ascend" ? "ASC" : "DESC"
            })
        }
        this.setState({
            search: Object.assign({}, this.state.search, {
                orders,
                page: pagination.current,
                limit: pagination.pageSize
            })
        }, () => {
            this.getData()
        })
    }

    getData() {
        const {search} = this.state
        let query = {...search}
        this.setState({
            loading: true
        })

        productApi.page(query).then(result => {
            const {data} = result
            this.state.tableData = data.data
            this.state.total = data.count
        }).catch(err => {
            this.setState({
                loading: false
            })
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.getData()
    }


    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }


        render() {
        const {search, columns, tableData, total , visible , storageVisible , echoProductData,productDetail} = this.state
        return (
            <main className='repertory-main'>
                <section className='search-info'>
                    <Form ref='form' model={search} labelWidth='75' labelPosition='left' className='rule-form'>
                        <Layout.Row gutter='20'>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="查詢框">
                                    <Input value={search.keywords} placeholder="請輸入"
                                           onChange={this.onChange.bind(this, 'keywords')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Button onClick={() => this.getData()}>查詢</Button>
                                <Button className='create-product' onClick={() => this.setState({visible: true})}>創建產品</Button>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='repertory-table-container'>
                    <div className='repertory-table-main'>
                        <Table columns={columns} dataSource={tableData}
                               pagination={{"total": total, showSizeChanger: true}}
                               onChange={this.pageAndOrder.bind(this)}></Table>
                    </div>
                </section>
                {visible ? <AddProductDialog productDetail={productDetail} isClosed={() => this.setState({visible: false,productDetail:null})} success={() => {
                    this.setState({visible: false,productDetail:null}, () => {
                        this.getData()
                    })
                }}/> : null}
                {storageVisible ? <StorageDialog isClosed={() => this.setState({storageVisible: false})} success={() => {
                    this.setState({storageVisible: false}, () => {
                        this.getData()
                    })
                }} product={echoProductData}/> : null}

            </main>
        )
    }
}