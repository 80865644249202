import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './record.scss'
import { Button, Select, Form, Input , DatePicker, Col, Row,Table, Spin,Checkbox,message ,Typography} from 'antd';
import moment from 'moment';
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import * as reportApi from "../../../api/report.api";
import {
    DEFAULT_COLUMN_WIDTH, 
    generateHeaders,
    getColumnNumber,
    addData2Table,
    multiHeader,
    saveWorkbook,
} from "../../../utils/execlUtils";

import * as ExcelJs from "exceljs";

const { Text } = Typography;
const { RangePicker } = DatePicker;


@translate()
export default class RechargeReport extends Component {
    formRef = React.createRef();
    state={
        search:{
            date:[moment(new Date()),moment(new Date())],
            status:"checked"
        },
        columns: [
            {
                title: '員工編號',
                dataIndex: 'staffId',
                key:'staffId'
            },
            {
                title: '員工名稱',
                dataIndex: 'name',
                key:"name"
            },
            {
                title: '服務項目',
                dataIndex:"reservations",
                key:"reservations",
                children:[
                    {
                        title: '數量',
                        dataIndex:"reservations_times",
                        key:"reservations_times",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["reservations"] && row.datas["reservations"].times) || 0}</div>
                        }
                    },
                    {
                        title: '總價價格',
                        dataIndex:"reservations_price",
                        key:"reservations_price",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["reservations"] && row.datas["reservations"].price) || 0}</div>
                        }
                    },
                    {
                        title: '提成',
                        dataIndex:"reservations_commision",
                        key:"reservations_commision",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["reservations"] && row.datas["reservations"].commission.toFixed(2)) || 0}</div>
                        }
                    }
                ]
            },
            {
                title: '產品銷售',
                dataIndex:"products",
                key:"products",
                children:[
                    {
                        title: '數量',
                        dataIndex:"products_times",
                        key:"products_times",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["products"] && row.datas["products"].times) || 0}</div>
                        }
                    },
                    {
                        title: '總價價格',
                        dataIndex:"products_price",
                        key:"products_price",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["products"] && row.datas["products"].price) || 0}</div>
                        }
                    },
                    {
                        title: '提成',
                        dataIndex:"products_commission",
                        key:"products_commission",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["products"] && row.datas["products"].commission.toFixed(2)) || 0}</div>
                        }
                    }
                ]
            },
            {
                title: '次卡銷售',
                dataIndex:"times",
                key:"times",
                children:[
                    {
                        title: '數量',
                        dataIndex:"times_times",
                        key:"times_times",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["times"] && row.datas["times"].times) || 0}</div>
                        }
                    },
                    {
                        title: '總價價格',
                        dataIndex:"times_price",
                        key:"times_price",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["times"] && row.datas["times"].price) || 0}</div>
                        }
                    },
                    {
                        title: '提成',
                        dataIndex:"times_commission",
                        key:"times_commission",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["times"] && row.datas["times"].commission.toFixed(2)) || 0}</div>
                        }
                    }
                ]
            },
            {
                title: '其他',
                dataIndex:"other",
                key:"other",
                children:[
                    {
                        title: '數量',
                        dataIndex:"other_times",
                        key:"other_times",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["other"] && row.datas["other"].times) || 0}</div>
                        }
                    },
                    {
                        title: '總價價格',
                        dataIndex:"other_price",
                        key:"other_price",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["other"] && row.datas["other"].price) || 0}</div>
                        }
                    },
                    {
                        title: '提成',
                        dataIndex:"other_commission",
                        key:"other_commission",
                        render(_,row) {
                            return <div>{(row.datas && row.datas["other"] && row.datas["other"].commission.toFixed(2)) || 0}</div>
                        }
                    }
                ]
            },
            {
                title:"total",
                dataIndex:"total",
                key:"total",
                render(_,row){
                    let total = Object.keys(row.datas).reduce((pre,current)=>{
                        return pre + row.datas[current].commission
                    },0)
                    return <div>{total.toFixed(2)}</div>
                }
            },
            {
                title:"总贡献占比",
                dataIndex:"revenue",
                key:"revenue",
                defaultSortOrder:"descend",
                sorter:true,
                sorter: (a, b) => a.revenue - b.revenue,
                render(_,row){
                    return <div>{row.revenue.toFixed(2)}</div>
                }
            },
        ],
        loading:false,
    }

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 
        if (search.birthday) {
            query.birthday = search.birthday.format('YYYY-MM') + "-01 00:00:00"
        }
        delete query.date

        return removeEmptyFiled(query)
    }

    toDecimal(x) {    
        var val = Number(x)   
       if(!isNaN(parseFloat(val))) {    
          val = val.toFixed(1);
       }  
       return  val;     
    }

    getData(){
        this.setState({loading:true})

        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
        }

        if (data.startTime && data.endTime) {
            query.startTime = moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            query.endTime = moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        reportApi.staffCommissionSummaryList(query).then(result => {
            const {data:{data}} = result
            let userTimesMaps = {}
            let totalTimes = data.reduce((pre,current)=> {
                userTimesMaps[current.staffId] = 0
                let data = current.datas
                let times = Object.keys(data).reduce((pre,currentValue) => {
                    userTimesMaps[current.staffId] +=  parseFloat(data[currentValue].times) || 0
                    return pre + ( parseFloat(data[currentValue].times) || 0)
                },0) 
                return pre + times
            },0)

            data.forEach(item => {
                item.revenue = ( userTimesMaps[item.staffId] / totalTimes  ) * 100
            })
            this.setState({result:data},()=>{
                console.log(this.state.result)
            })
        }).catch(error => {
            console.log(error)
        }).finally(()=>{
            this.setState({loading:false})
        })
    };

    onChange  (pagination, filters, sorter, extra){
        console.log(sorter)
    };
    componentDidMount(){
        this.getData()
    }

    onExportMultiHeaderExcel(params) {
        // 创建工作簿
        const workbook = new ExcelJs.Workbook();
        // 添加sheet
        const worksheet = workbook.addWorksheet('demo sheet');
        // 设置 sheet 的默认行高
        worksheet.properties.defaultRowHeight = 20;
        // 解析 AntD Table 的 columns
        const headers = generateHeaders(params.columns);
        console.log({headers})
        // 第一行表头
        const names1 = [];
        // 第二行表头
        const names2 = [];
        // 用于匹配数据的 keys
        const headerKeys = [];
        headers.forEach(item => {
          if (item.children) {
            // 有 children 说明是多级表头，header name 需要两行
            item.children.forEach(child => {
              names1.push(item.header);
              names2.push(child.header);
              headerKeys.push(child.key);
            });
          } else {
            const columnNumber = getColumnNumber(item.width);
            for (let i = 0; i < columnNumber; i++) {
              names1.push(item.header);
              names2.push(item.header);
              headerKeys.push(item.key);
            }
          }
        });
        multiHeader(worksheet, headers, names1, names2);
        // 添加数据
        addData2Table(worksheet, headerKeys, headers,params.dataSource,params.columns);
        // 给每列设置固定宽度
        worksheet.columns = worksheet.columns.map(col => ({ ...col, width: DEFAULT_COLUMN_WIDTH }));
        // 导出excel
        saveWorkbook(workbook, params.name);
    }

    render(){
        const { loading,result,columns } = this.state
        return (
            <main className='report-contanier'>
                <section className='search-contanier'>
                    <Form initialValues={{ date:[moment(new Date()),moment(new Date())],status:"checked" }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                        <Row gutter={20}>
                            <Col span={8} style={{ textAlign: 'left' }}>
                                <Form.Item name="date" label="查詢日期">
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <Form.Item name="status" label="狀態">
                                    <Select allowClear>
                                        <Select.Option value="checked">已完成</Select.Option>
                                        <Select.Option value="waiting">未完成</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        查詢
                                    </Button>
                                    <Button style={{marginLeft:"10px"}} onClick={() => this.onExportMultiHeaderExcel({columns,dataSource:result,name:"員工提成匯總報表.xlsx"})}>導出</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </section>
                <section className='table-contanier'>
                    <div className='table-main'>
                        <Table columns={columns} dataSource={result} pagination={false} loading={loading} bordered={true}
                        summary={(pageData) => {
                            let totalReservationTimes = 0;
                            let totalTimes = 0;
                            let totalProductTimes = 0;
                            let totalOtherTimes = 0
                            let totalReservationPrice = 0;
                            let totalTimesPrice = 0;
                            let totalProductPrice = 0;
                            let totalOtherPrice = 0
                            let totalReservationCommission = 0;
                            let totalTimesCommission = 0;
                            let totalProductCommission = 0;
                            let totalOtherCommission = 0
                            let totalRevenue = 0

                            pageData.forEach(({ revenue,datas }) => {
                                totalRevenue +=  revenue
                                totalReservationTimes += (datas["reservations"] && parseFloat(datas["reservations"].times)) || 0
                                totalReservationPrice += (datas["reservations"] && parseFloat(datas["reservations"].price)) || 0
                                totalReservationCommission += (datas["reservations"] && parseFloat(datas["reservations"].commission)) || 0
                                totalTimes += (datas["times"] && parseFloat(datas["times"].times))  || 0
                                totalTimesPrice += (datas["times"] && parseFloat(datas["times"].price)) || 0
                                totalTimesCommission += (datas["times"] && parseFloat(datas["times"].commission)) || 0
                                totalProductTimes += (datas["products"] && parseFloat(datas["products"].times)) || 0
                                totalProductPrice += (datas["products"] && parseFloat(datas["products"].price)) || 0
                                totalProductCommission +=  (datas["products"] && parseFloat(datas["products"].commission)) || 0
                                totalOtherTimes += (datas["other"] && parseFloat(datas["other"].times)) || 0
                                totalOtherPrice += (datas["other"] && parseFloat(datas["other"].price)) || 0
                                totalOtherCommission += (datas["other"] && parseFloat(datas["other"].commission)) || 0
                            });

                            return (
                                <Table.Summary.Row className="summary-cell">
                                  <Table.Summary.Cell index={0} colSpan={2} style={{borderRight:"0"}}>合计</Table.Summary.Cell>
                                  <Table.Summary.Cell index={2} style={{borderRight:"0",borderLeft:"0"}}>
                                    <Text type="danger">{totalReservationTimes}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={3}>
                                    <Text type="danger">{totalReservationPrice.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={4}>
                                    <Text type="danger">{totalReservationCommission.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={5}>
                                    <Text type="danger">{totalProductTimes}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={6}>
                                    <Text type="danger">{totalProductPrice.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={7}>
                                    <Text type="danger">{totalProductCommission.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={8}>
                                    <Text type="danger">{totalTimes}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={9}>
                                    <Text type="danger">{totalTimesPrice.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={10}>
                                    <Text type="danger">{totalTimesCommission.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={11}>
                                    <Text type="danger">{totalOtherTimes}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={12}>
                                    <Text type="danger">{totalOtherPrice.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={13}>
                                    <Text type="danger">{totalOtherCommission.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={14}>
                                    <Text type="danger">{(totalReservationCommission + totalProductCommission + totalTimesCommission + totalOtherCommission).toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={15}>
                                    <Text type="danger">{totalRevenue.toFixed(2)}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                        onChange={this.onChange.bind(this)}
                        ></Table>
                    </div>
                </section>
            </main>
        )
    }
}