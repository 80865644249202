import client from '../apiClient';

export async function login(staffId, password) {
    let response = await client.post('/staffs/login', {
        staffId,
        password,
    });
    return response.data;
}

export async function getStaff(staffId) {
    let response = await client.get(`/staffs/${staffId}`);
    return response.data;
}

export async function getStaffAll() {
    let response = await client.get(`/staffs/all`);
    return response.data;
}

export async function getStaffs(data) {
    let response = await client.post('/staffs', data);
    return response.data;
}

export async function createStaff(body) {
    let response = await client.put('/staffs', body);
    return response;
}

export async function updateStaff(staffId, body) {
    let response = await client.patch(`/staffs/${staffId}`, body);
    return response.data;
}

export async function deleteStaff(staffID) {
    let response = await client.delete(`/staffs/${staffID}`);
    return response.data;
}

export async function uploadIcon(staffId, icon) {
    const formData = new FormData();
    formData.append("icon", icon);
    let response = await client.post(`/staffs/${staffId}/icon`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function assignCenter(staffId, centers) {
    let response = await client.post(`/staffs/${staffId}/assignCenter`, {
        centers,
    });
    return response.data;
}

export async function assignService(staffId, services) {
    let response = await client.post(`/staffs/${staffId}/assignService`, {
        services,
    });
    return response.data;
}

export async function assignPermission(staffId, permissions) {
    let response = await client.post(`/staffs/${staffId}/assignPermission`, {
        permissions,
    });
    return response.data;
}

export async function refreshToken(refreshToken) {
    let response = await client.post('/auth/staffTokens', {
        refreshToken,
    });
    return response.data;
}

export async function changePassword(staffId, oldPassword, newPassword, confirmNewPassword) {
    let response = await client.patch(`/staffs/${staffId}/changePassword`, {
        oldPassword,
        newPassword,
        confirmNewPassword,
    });
    return response.data;
}

export async function getStaffCommission(staffId, type) {
    let response = await client.get(`/staffs/${staffId}/commission`, {
        params: {
            type
        }
    });
    return response.data;
}

export async function getStaffsAll(){
    let response = await client.get('/staffs/list')
    return response.data;
}

export async function  getStaffPrice(staffId,status) {
    let response = await client.get(`staffs/commissionList`, {
        params: {
            staffId,
            status
        }
    });
    return response.data;
}

export async function  getStaffSaraly(staffId) {
    let response = await client.get(`staffs/salaryPreviewList`,{
        params:{
            staffId
        }
    });
    return response.data;
}

export async function getSalesCommission(query) {
    let response = await client.get(`/secondaryCardTransaction/sales`,{
        params:query
    });
    return response;
}