
import React, { Component } from 'react';
import { translate } from "react-i18next";
import { Button, Dialog, Table, Form, Pagination, Message, Loading, Collapse } from 'element-react';
import moment from 'moment';
import * as reservationApi from '../../../../api/reservation.api'


@translate()
export default class extends Component {

  state = {
    userId: this.props.userId,
    limit: 10,
    page: 1,
    data: [],
    cardData: {},
    visible: false,
    loading: false,
    count: 0,
    columns: [
      {
        label: this.props.t('booking_table_column_date'),
        prop: "startTime",
        sortable: "custom",
        render: this.renderDate
      },
      {
        label: this.props.t('booking_table_column_time'),
        prop: "startTime",
        render: this.renderTime
      },
      {
        label: this.props.t('booking_table_column_user'),
        prop: "user.name",
        render: (row) => {
          return (
            <div>
              <span>{row.user.surname}</span>
              <span>{row.user.name}</span>
            </div>
          )
        }
      },
      {
        label: this.props.t('booking_table_column_phone'),
        prop: "user.phone",
        width: 200
      },
      {
        label: this.props.t('booking_table_column_staff'),
        render: function (data) {
          return (
            <div>
              <span>{data.staff_schedule.staff.staff_details[0].surname}</span>
              <span>{data.staff_schedule.staff.staff_details[0].name}</span>
            </div>
          )
        }
      },
      {
        label: this.props.t('booking_table_column_server'),
        render: function (data) {
          return (
            <span>{data.service.service_details[0].name}</span>
          )
        }
      },
      {
        label: this.props.t('booking_revise_record_price'),
        prop: "price",
      },
      {
        label: this.props.t('booking_revise_record_discount_price'),
        render: function (data) {
          if (data.type === 'discount') {
            return (
              <span>{ (data.price && data.discount &&  (parseInt(data.price * (1 - data.discount / 100))).toFixed(2)) || 0}</span>
            )
          } else {
            return (
              <span>{(data.discount && (data.discount).toFixed(2)) || 0}</span>
            )
          }
        }
      },
      {
        label: this.props.t('booking_revise_record_real_deal_price'),
        prop: "discountPrice",
        render: (row) => {
            return (
                <span>{row.discountPrice || 0}</span>
            )
        }
      },
      {
        label: this.props.t('booking_table_column_status'),
        prop: "status",
      },
    ],
    activeName: '',
  };

  componentDidMount() {
    const { userId } = this.state
    this.getList(userId)
  }

  componentWillReceiveProps(props) {
    if (props.userId !== this.state.userId) {
      this.setState({ userId: props.userId })
      this.getList(props.userId)
    }
  }

  async getList(userId) {
    const { page, limit } = this.state
    let obj = {
      page,
      limit,
      userId: userId,
      status: "checked"
    }
    this.setState({
      loading: true
    })
    await reservationApi.getReservationList(obj).then(result => {
      if (result.rows.length > 0) {
        this.setState({
          cardData: result.rows[0]
        })
      }
      this.setState({
        data: result.rows,
        count: result.count
      })
    }).catch(error => {
      Message.error(error)
    }).finally(() => {
      this.setState({
        loading: false
      })
    })
  }


  CurrentChange(value) {
    console.log(value)
  }

  renderDate(date) {
    var date = new Date(date.startTime).toJSON();
    var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    return moment(new Date(val)).format('YYYY-MM-DD')
  }

  renderTime(date) {
    var startTime = new Date(date.startTime).toJSON();
    var endTime = new Date(date.endTime).toJSON();
    var from = new Date(+new Date(startTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    var to = new Date(+new Date(endTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    return moment(new Date(from)).format('HH:mm:ss') + "-" + moment(new Date(to)).format('HH:mm:ss')
  }
  render() {
    const { data, cardData, visible, page, limit, loading, count, activeName } = this.state
    const { t } = this.props
    return (
      <div className='message-view-bottom'>
        <div className="pages-title" style={{ marginBottom: '10px' }}>最近消費記錄</div>
        {
          data.length > 0 ? (
            <Collapse value={activeName}>
              <Collapse.Item title="最近一筆消費記錄" name="First" key='First'>
                <div style={{ margin: '-10px -5px' }}>
                  <Form labelPosition="left" labelWidth="50" className="demo-form-stacked">
                    <Form.Item label={t("staff_schedule_dialog_date")}>
                      {moment(cardData.date).format('YYYY-MM-DD')}
                    </Form.Item>
                    <Form.Item label={t("staff_schedule_dialog_time")}>
                      {moment(cardData.startTime).format('HH:mm:ss')}-{moment(cardData.endTime).format('HH:mm:ss')}
                    </Form.Item>
                    <Form.Item label={t("staff_schedule_dialog_service")}>
                      {cardData.service && cardData.service.service_details.length > 0 && cardData.service.service_details[0].name}
                    </Form.Item>
                    <Form.Item label={t("price")}>
                      {cardData.price}
                    </Form.Item>
                    <Form.Item label={t("staff")}>
                      <span>{cardData.staff_schedule && cardData.staff_schedule.staff && cardData.staff_schedule.staff.staff_details.length > 0 && cardData.staff_schedule.staff.staff_details[0].surname}</span>
                      <span>{cardData.staff_schedule && cardData.staff_schedule.staff && cardData.staff_schedule.staff.staff_details.length > 0 && cardData.staff_schedule.staff.staff_details[0].name}</span>
                    </Form.Item>
                    <Form.Item label={t("add_booking_sub_title_client")}>
                      <span>{cardData.user ? cardData.user.surname : ""}</span> <span>{cardData.user && cardData.user.name}</span>
                    </Form.Item>
                  </Form>
                </div>
              </Collapse.Item>
            </Collapse>
          ) : null
        }
        <Button style={{margin:'6px 0'}} onClick={() => this.setState({ visible: !visible })}>更多記錄</Button>
        <Dialog title="消費記錄表" size="tiny" size="large" visible={visible} onCancel={() => this.setState({ visible: false })} lockScroll={false} >
          <Dialog.Body>
            {data.length > 0 && (
              <div>
                <Loading loading={loading}>
                  <Table style={{ width: '100%' }} columns={this.state.columns} data={data} />
                </Loading>
                {
                  count > 10 ? <Pagination layout="prev, pager, next" total={count} small={true} pageSize={limit} currentPage={page} onCurrentChange={() => { }} ></Pagination> : null
                }
              </div>
            )}
          </Dialog.Body>
        </Dialog>
      </div>
    );
  }
}
