import {saveAs} from 'file-saver';
import * as ExcelJs from 'exceljs';
import {Workbook, Worksheet, Row, Cell} from 'exceljs';

export const DEFAULT_COLUMN_WIDTH = 20;
// 默认行高
export const DEFAULT_ROW_HEIGHT = 20;
/**
 * 下载导出简单的表格
 * @param params
 */
export function downloadExcel(params) {
    console.log({params});
    // 创建工作簿
    const workbook = new ExcelJs.Workbook();
    params.sheets.forEach((sheet) => handleEachSheet(workbook, sheet));
    saveWorkbook(workbook, `${params.filename}.xlsx`);
}

function handleEachSheet(workbook, sheet) {
    // 添加sheet
    const worksheet = workbook.addWorksheet(sheet.sheetName);
    // 设置 sheet 的默认行高。设置默认行高跟自动撑开单元格冲突
    // worksheet.properties.defaultRowHeight = 20;
    // 设置列
    worksheet.columns = generateHeaders(sheet.columns);
    handleHeader(worksheet);
    // handleData(worksheet, sheet);
    handleDataWithRender(worksheet, sheet);
}

// 根据 antd 的 column 生成 exceljs 的 column
export function generateHeaders(columns) {
    return columns.map((col) => {
      const obj = {
        // 显示的 name
        header: col.title,
        // 用于数据匹配的 key
        key: col.dataIndex,
        // 列宽
        width: col.width / 5 > DEFAULT_COLUMN_WIDTH ? col.width / 5 : DEFAULT_COLUMN_WIDTH,
      };
      if (col.children) {
        obj.children = col.children.map((item) => ({
          key: item.dataIndex,
          header: item.title,
          width: item.width,
          parentKey: col.dataIndex,
        }));
      }
      return obj;
    });
  }

export function handleHeader(worksheet) {
  // 给表头添加背景色。因为表头是第一行，可以通过 getRow(1) 来获取表头这一行
  const headerRow = worksheet.getRow(1);
  headerRow.height = 22;
  // 通过 cell 设置样式，更精准
  headerRow.eachCell((cell) => addCellStyle(cell, {color: '#fff', fontSize: 12, horizontal: 'left'}));
}

function handleDataWithRender(worksheet, sheet) {
  const {dataSource, columns} = sheet;
  const rowsData = dataSource.map(data => {
    return columns.map(column => {
      // @ts-ignore
      const renderResult = column.render ? column.render(data[column.dataIndex], data) : undefined
      if (renderResult) {
        // 如果不是 object 说明没包裹标签，是基本类型直接返回
        if (typeof renderResult !== "object") {
          return renderResult;
        }
        console.log("value",renderResult)
        // 如果是 object 说明包裹了标签，逐级取出值
        let value = getValueFromRender(renderResult)

        if ( value && Array.isArray(value)) { 
            if (value[0].type) {
              value = value.map(item => item.props.children).join("  ")
            } else {
              value = value.join("")
            }
        }
        return value;
      }
      // @ts-ignore
      return data[column.dataIndex];
    })
  })
  // 添加行
  const rows = worksheet.addRows(rowsData);
  // 设置每行的样式
  addStyleToData(rows);
}

export function getValueFromRender(renderResult) {
  if (renderResult.type) {
    let children = renderResult.props.children;
    if (children.type) {
      return getValueFromRender(children);
    } else {
      return children;
    }
  }
  return ''
}

function addStyleToData(rows) {
  // 设置每行的样式
  rows.forEach((row) => {
    // 设置字体
    // eslint-disable-next-line no-param-reassign
    row.font = {
      size: 11,
      name: '微软雅黑',
    };
    // 设置对齐方式
    // eslint-disable-next-line no-param-reassign
    row.alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
  });
}

export function addCellStyle(cell,  attr) {
  const {color, fontSize, horizontal, bold} = attr || {};
  // eslint-disable-next-line no-param-reassign
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
  };
  // eslint-disable-next-line no-param-reassign
  cell.font = {
    bold: bold || false,
    size: fontSize || 11,
    name: '微软雅黑',
  };
  // eslint-disable-next-line no-param-reassign
  cell.alignment = {vertical: 'middle', wrapText: true, horizontal: horizontal || 'left'};

  cell.border = {
    top: {style:'thin',color: {argb:'E6E6E6'}},
    left: {style:'thin',color: {argb:'E6E6E6'}},
    bottom: {style:'thin',color: {argb:'E6E6E6'}},
    right: {style:'thin',color: {argb:'E6E6E6'}}
  }
}

export function saveWorkbook(workbook, fileName) {
  // 导出文件
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {type: ''});
    saveAs(blob, fileName);
  });
}

export function addHeaderStyle(row, attr) {
  const { color, fontSize, horizontal, bold } = attr || {};
  // eslint-disable-next-line no-param-reassign
  row.height = DEFAULT_ROW_HEIGHT;
  row.eachCell((cell, colNumber) => {
    // eslint-disable-next-line no-param-reassign
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      bold: bold || true,
      size: fontSize || 11,
      name: '微软雅黑',
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = { vertical: 'middle', wrapText: true, horizontal: horizontal || 'left' };
    // border
    cell.border = {
      top: {style:'thin',color: {argb:'E6E6E6'}},
      left: {style:'thin',color: {argb:'E6E6E6'}},
      bottom: {style:'thin',color: {argb:'E6E6E6'}},
      right: {style:'thin',color: {argb:'E6E6E6'}}
    }
  });
}

export function getColumnNumber(width) {
  // 需要的列数，四舍五入
  return Math.round(width / DEFAULT_COLUMN_WIDTH);
}
  

  // 合并行和列，用于处理表头合并
export function mergeColumnCell(
    headers,
    rowHeader1,
    rowHeader2,
    nameRow1,
    nameRow2,
    worksheet,
  ) {
    // 当前 index 的指针
    let pointer = -1;
    nameRow1.forEach((name, index) => {
      // 当 index 小于指针时，说明这一列已经被合并过了，不能再合并
      if (index <= pointer) return;
      // 是否应该列合并
      const shouldVerticalMerge = name === nameRow2[index];
      // 是否应该行合并
      const shouldHorizontalMerge = index !== nameRow1.lastIndexOf(name);
      pointer = nameRow1.lastIndexOf(name);
      if (shouldVerticalMerge && shouldHorizontalMerge) {
        // 两个方向都合并
        worksheet.mergeCells(
          Number(rowHeader1.number),
          index + 1,
          Number(rowHeader2.number),
          nameRow1.lastIndexOf(name) + 1,
        );
      } else if (shouldVerticalMerge && !shouldHorizontalMerge) {
        // 只在垂直方向上同一列的两行合并
        worksheet.mergeCells(Number(rowHeader1.number), index + 1, Number(rowHeader2.number), index + 1);
      } else if (!shouldVerticalMerge && shouldHorizontalMerge) {
        // 只有水平方向同一行的多列合并
        worksheet.mergeCells(
          Number(rowHeader1.number),
          index + 1,
          Number(rowHeader1.number),
          nameRow1.lastIndexOf(name) + 1,
        );
        // eslint-disable-next-line no-param-reassign
        const cell = rowHeader1.getCell(index + 1);
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      }
    });
}
  
  // 行合并单元格
  export function mergeRowCell(headers, row, worksheet) {
    // 当前列的索引
    let colIndex = 1;
    headers.forEach(header => {
      const { width, children } = header;
      if (children) {
        children.forEach(child => {
          colIndex += 1;
        });
      } else {
        // 需要的列数，四舍五入
        const colNum = getColumnNumber(width);
        // 如果 colNum > 1 说明需要合并
        if (colNum > 1) {
          worksheet.mergeCells(Number(row.number), colIndex, Number(row.number), colIndex + colNum - 1);
        }
        colIndex += colNum;
      }
    });
  }


 export function multiHeader(
  worksheet,
  headers,
  names1,
  names2,
) {
  // 判断是否有 children, 有的话是两行表头
  const isMultiHeader = headers.some(item => item.children);
  if (isMultiHeader) {
    // 加表头数据
    const rowHeader1 = worksheet.addRow(names1);
    const rowHeader2 = worksheet.addRow(names2);
    // 添加表头样式
    addHeaderStyle(rowHeader1, {color: 'ffffff'});
    addHeaderStyle(rowHeader2, {color: 'ffffff',border:""});
    mergeColumnCell(headers, rowHeader1, rowHeader2, names1, names2, worksheet);
    return;
  }
  // 加表头数据
  const rowHeader = worksheet.addRow(names1);
  // 表头根据内容宽度合并单元格
  mergeRowCell(headers, rowHeader, worksheet);
  // 添加表头样式
  addHeaderStyle(rowHeader, {color: 'dff8ff'});
}

export function addData2Table(worksheet, headerKeys, headers,datas,columns) {
  let columnsMaps = {}
  columns.forEach(item => {
      if (item.children) {
          item.children.forEach(e => {
              columnsMaps[e.key] = item 
          })
      } else {
          columnsMaps[item.key] = item
      }
  })

  datas.forEach((item) => {
      let rowData = headerKeys.map(key => {
          const renderResult = columnsMaps[key] && columnsMaps[key].render ? columnsMaps[key].render(null,item) : undefined
          if (renderResult) {
              // 如果不是 object 说明没包裹标签，是基本类型直接返回
              if (typeof renderResult !== "object") {
                return renderResult;
              }
              // 如果是 object 说明包裹了标签，逐级取出值
              let value = getValueFromRender(renderResult)
              return value;
          } else if (columnsMaps[key]  && columnsMaps[key].children) {
              let keyData = columnsMaps[key].children.find(item => item.key == key)
              const renderResult = keyData && keyData.render ? keyData.render(null,item) : undefined
              if (renderResult) {
                  // 如果不是 object 说明没包裹标签，是基本类型直接返回
                  if (typeof renderResult !== "object") {
                    return renderResult;
                  }
                  // 如果是 object 说明包裹了标签，逐级取出值
                  let value = getValueFromRender(renderResult)

                  return value;
              } 
              return item[key] || 0
          } else {
              return item[columnsMaps[key].dataIndex];
          }
      })
      const row = worksheet.addRow(rowData);
      mergeRowCell(headers, row, worksheet);
      row.height = DEFAULT_ROW_HEIGHT;
      // 设置行样式, wrapText: 自动换行
      row.alignment = { vertical: 'middle', wrapText: false, shrinkToFit: false };
      row.font = { size: 11, name: '微软雅黑' };
  })
}