import React, { Component } from 'react';
import { Form, Select, DatePicker, Button, Table, Dialog, Checkbox, Input, TimeSelect, Layout, ColorPicker, Radio, Cascader, MessageBox, Collapse,InputNumber } from 'element-react';
import './add-booking.scss';
import * as userApi from "../../../api/user.api";
import * as centerApi from "../../../api/center.api";
import * as serviceApi from "../../../api/service.api";
import { translate } from "react-i18next";
import UserSearchBar from '../../../components/user/search-bar/search-bar.component';
import * as staffApi from "../../../api/staff.api";
import * as scheduleApi from "../../../api/schedule.api";
import moment from 'moment';
import { setError } from "../../../redux/staff/error.action";
import store from '../../../redux/store';
import * as reservationApi from "../../../api/reservation.api";
import history from '../../../history';
import RecordsList from './recent-consumption-records'

@translate()
export default class extends Component {

    state = {
        status: '',
        error: '',
        showConfirm: false,
        selectedBooking: null,
        centers: [],
        services: [],
        staffs: [],
        showSearchResult: false,
        form: {
            date: new Date(),
            from: undefined,
            to: undefined,
            service: [],
            center: '',
            staff: '',
            user: '',
            newUserName: '',
            newUserPhoneNumber: '',
            bookingRemark: '',
            hkid: '',
            intentionalService: [],
            customerClass: '',
            birthday: null,
        },
        customerClass: [],
        intentionalService: [],
        rules: {
            user: [
                { required: true, message: '请输入客户名', trigger: 'blur' }
            ],
            newUserName: [
                { required: true, message: '请输入客户名', trigger: 'blur' }
            ],
            newUserPhoneNumber: [
                {
                    validator: (rule, value, callback) => {
                        var regPos = /^[0-9]+.?[0-9]*/ // 判断是否是数字
                        var resPhone = /^1[3-9]\d{9}$/
                        if (value === '') {
                            callback(new Error('请输入手機號碼'))
                        } else if (!resPhone.test(value)) {
                            callback(new Error('請輸入正確的手機號碼'))
                        } else if (!regPos.test(value)) {
                            callback(new Error('請輸入正確的手機號碼'))
                        } else {
                            callback()
                        }
                    }
                }
            ]
        },
        selectedUser: null,
        pastTimeDisable: true,
        schedules: [],
        message: [],
        regAsNewClient: true,
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            },{
                value: "現金",
                label: "現金"
            },{
                value:"Octpus",
                label: "Octpus"
            },{
                value:"Kpay",
                label: "Kpay"
            }
        ],
        colorValue1: '#20A0FF',
        columns: [
            {
                label: this.props.t('add_booking_table_time'),
                prop: 'startTime',
                render: (row, column, index) => {
                    return <span>
                        {`${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}`}
                    </span>
                }
            },
            {
                label: this.props.t('add_booking_table_therapist'),
                prop: 'staff.name',
                render: (row, column, index) => {
                    return <span>
                        {this.props.t('user_name', row.staff)}
                    </span>
                }
            },
            {
                label: this.props.t('add_booking_table_center'),
                prop: 'center.name',
            },
            {
                label: this.props.t('add_booking_table_price'),
                prop: 'price',
            },
            {
                label: this.props.t('add_booking_table_action'),
                prop: 'action',
                render: (row, column, index) => {
                    return <span>
                        <Button type="text" size="small"
                            onClick={() => {
                                this.setState({
                                    selectedBooking: row,
                                    showConfirm: true,
                                });
                            }}>
                            {this.props.t('add_booking_table_button_book')}
                        </Button>
                    </span>
                }
            }
        ],
        height: 0,
        prop: {
            value: 'value',
            label: "name"
        },
        activeName: '1',
    };

    // 提交处理数据
    async reservation(message) {

        if (this.state.loading) {
            return;
        }
        this.setState({
            loading: true,
        });
        var proccessedItems = [];
        var newUsers = [];

        console.log('message', message)

        // message.forEach(async (item, index, object) => {
        for (const [index, item] of message.entries()) {
            if (item.status === 'success' || item.status === 'error') {
                proccessedItems.push(index);
            } else {
                // const regAsNewClient = this.state.regAsNewClient;
                if (!item.user) {
                    if (item.newUserName && item.newUserPhoneNumber) {
                        if (item.regAsNewClient) {
                            var user;
                            //found if created first
                            user = newUsers.filter((e) => e.phone === item.newUserPhoneNumber);
                            console.log(user);

                            if (user[0])
                                user = user[0];
                            else {
                                try {
                                    user = await userApi.createUser({
                                        name: item.newUserName,
                                        phone: item.newUserPhoneNumber,
                                        customerClass: item.customerClass,
                                        birthday: item.birthday,
                                        hkid: item.hkid,
                                        address: item.address,
                                        email: item.email,
                                        gender: item.gender,
                                        intentionalService: item.intentionalService,
                                        surname: ""
                                    })
                                }
                                catch (e) {
                                    console.log(e);
                                    alert('Failed to create member. Please check if mobile is correct');
                                }
                                newUsers.push(user);
                            }
                            item.user = user.id
                            item.bookingUser = {
                                name: user.name,
                                surname: user.surname,
                                phone: user.phone
                            }
                        } else {
                            item.bookingUser = {
                                name: item.newUserName,
                                phone: item.newUserPhoneNumber
                            }
                        }
                    } else {
                        throw new Error('Please select user');
                    }
                }
                var staffScheduleIds = [];
                for (var key in item.staffScheduleIds) {
                    if (item.staffScheduleIds[key].staffId === item.staff.id)
                        staffScheduleIds = item.staffScheduleIds[key].ids;
                }

                await reservationApi.createReservation(item.user, item.user, staffScheduleIds, item.serviceId, item.bookingUser, item.bookingRemark, item.startTime, item.endTime,item.staffLevel,item.levelPrice,item.discountType,item.discount,item.depositCheck,
                    item.depositPrice,
                    item.depositType).then(response => {
                    item.status = 'success'
                    let newObj = { ...this.state.form }
                    Object.keys(newObj).forEach(key => {
                        if (key == 'from' || key == 'to') {
                            newObj.form = new Date().getTime()
                            newObj.form = newObj.form + 3600
                        } else {
                            switch (Object.prototype.toString.call(newObj[key])) {
                                case '[object String]':
                                    newObj[key] = '';
                                    break;
                                case '[object Boolean]':
                                    newObj[key] = false;
                                    break;
                                case '[object Date]':
                                    newObj[key] = new Date();
                                    break;
                                case '[object Number]':
                                    newObj[key] = 0;
                                    break;
                                case '[object Array]':
                                    newObj[key] = [];
                                    break;
                                case '[object Object]':
                                    newObj[key] = {};
                                    break;
                                case '[object Undefined]':
                                    newObj[key] = undefined;
                                    break;
                                case '[object Null]':
                                    newObj[key] = null;
                                    break;
                            }
                        }
                    })
                    this.setState({
                        status: 'success',
                        form: { ...this.state.form, ...newObj },
                        selectedUser: null
                    })
                }).catch(error => {
                    item.status = 'error'
                    this.setState({
                        status: 'error',
                        error: error.userMessage
                    })
                }).finally(() => {
                    this.setState({
                        showConfirm: false,
                        schedules: []
                    });
                })
            }

            if (index === message.length - 1) {
                var newMessage = [];
                for (var index in message) {
                    if (!proccessedItems.includes(Number(index))) {
                        newMessage.push(message[index]);
                    }
                }

                this.setState({
                    loading: false,
                    message: newMessage
                });
            }
        };

        if (message.length === 0) {
            this.setState({
                loading: false,
            });
        }
    }

    async addToBookings(schedule) {
        console.log(" ==== message =========")
        console.log(schedule)
        console.log(" ==== end message =========")
        this.setState({
            loading: true,
        });
        const { center, staff } = schedule;
        const { form, selectedUser, schedules, regAsNewClient, services } = this.state
        const { date, from, to, service, user, newUserName, newUserPhoneNumber, bookingRemark, hkid, birthday, address, email, gender, customerClass } = form
        let index = service.length - 1
        let serverData = service[index]
        const data = {
            bookingRemark,
            staffScheduleIds: schedules.concat(),
            date: moment(date).format(),
            serviceId: serverData,
            startTime: moment(from).format(),
            endTime: moment(to).format(),
            center: center.centerId,
            centerName: center.name,
            bookUserId: user,
            user,
            staff,
            newUserName,
            newUserPhoneNumber,
            customerClass,
            hkid,
            address,
            email,
            birthday,
            gender,
            intentionalService: service,
            bookingUser: null,
            regAsNewClient
        }

        try {
            //if reg as new client, check if client already exist
            if (!selectedUser && regAsNewClient) {
                const response = await userApi.getUsers(1, 'createdAt', 'ASC', 1, newUserPhoneNumber);
                if (response.count > 0) {
                    throw new Error("Phone number duplicated with member " + response.rows[0].id + ". Please check if the phone number is correct");
                }
            }

            //rm processed message items
            var proccessedItems = [];
            var message = this.state.message;
            message.forEach(async (item, index, object) => {
                if (item.status === 'success' || item.status === 'error') {
                    proccessedItems.push(index);
                }

                if (index === message.length - 1) {
                    var newMessage = [];
                    for (var index in message) {
                        if (!proccessedItems.includes(Number(index))) {
                            newMessage.push(message[index]);
                        }
                    }
                    message = newMessage;
                }
            });

            console.log(services, serverData)
            //add data to message list
            const serviceObj = services.find((obj) => obj.id == serverData);
            console.log("serviceObj", serviceObj)
            if (!serviceObj) {
                throw new Error('Please select service');
            }
            data.serviceName = serviceObj.name;
            if (selectedUser) {
                console.log('selectUser', selectedUser)
                const { id, phone, name } = selectedUser
                const bookingUser = {}
                data.targetUserId = id
                bookingUser.name = name
                bookingUser.phone = phone

                data.bookingUser = bookingUser
            } else {
                if (!newUserName || !newUserPhoneNumber) {
                    throw new Error('Please select user');
                }
            }

            message.push(data)
            this.setState({
                message: message,
            })
        } catch (error) {
            store.dispatch(setError(error.userMessage || error.message));
        } finally {
            this.setState({
                showConfirm: false,
                loading: false,
            });
            console.log(data)
        }
    }

    async book(schedule) {
        if (this.state.loading) {
            return;
        }
        this.state.loading = true;
        this.setState({
            loading: true,
        });
        try {
            const { form, selectedUser, regAsNewClient } = this.state;
            console.log(" ====== form data =========")
            console.log(form)
            console.log(" ====== end form data =========")
            console.log(regAsNewClient)
            // if (!form.user) {
            //     throw new Error('Please select user');
            // }

            let bookingUser = null
            if (!form.user) {
                if (form.newUserName && form.newUserPhoneNumber) {
                    if (regAsNewClient) {
                        let user = await userApi.createUser({
                            name: form.newUserName,
                            phone: form.newUserPhoneNumber,
                            surname: "",
                        })
                        form.user = user.id
                        bookingUser = {
                            name: user.name,
                            phone: user.phone
                        }
                    } else {
                        bookingUser = {
                            name: form.newUserName,
                            phone: form.newUserPhoneNumber
                        }
                    }
                } else {
                    throw new Error('Please select user');
                }
            }

            const response = await reservationApi.createReservation(form.user, form.user, schedule.ids, form.service, bookingUser, form.bookingRemark, form.from, form.to);
            this.setState({
                showConfirm: false,
            });
            this.refreshScheduleList();
            history.push('/main/booking-overview');
        } catch (error) {
            store.dispatch(setError(error.userMessage || error.message));
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    // 新增会员获取会员等级数据
    async getVipList() {
        await userApi.getVipList().then((result) => {
            const data = result.map(item => {
                return {
                    value: item.id,
                    label: item.level
                }
            })
            this.setState({ customerClass: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        });
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            console.log("ROWS",result.rows);
            let data = await this.ServiceTree(result.rows)
            console.log("test",data);
            this.setState({ intentionalService: data.filter(item => item.children && item.children.length > 0) })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        })
    }

    async ServiceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum == 1) {
                parents.push({
                    value: item.id,
                    name: item && item.category_details && item.category_details[0].title,
                })
            }
        })
        console.log("parents",parents)
        let childrens = datas.filter(item => item.stratum != 1)
        console.log("childrens",childrens)
        await this.formatService(parents, childrens)
        return parents
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId == parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let services = children.services.filter(item => !item.isDeleted).map(service => {
                        return {
                            value: service.id,
                            name: service.service_details[0].name,
                        }
                    })
                    let child = {
                        value: children.id,
                        name: children && children.category_details && children.category_details[0].title,
                    }
                    if (children.services.length > 0) {
                        child.children = services
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        await this.getVipList()
        await this.getServiceList()
        if (Object.keys(params).length) {
            this.presetData(params);
        }

        let query = {
            page : 1, 
            orderBy:null, 
            direction:null, 
            limit:1000, 
            reference:'',
            orders:[]
        }

        const [
            services,
            centers,
            staffs
        ] = await Promise.all([
            serviceApi.getServices(1, 'id', 'ASC', 1000, ''),
            centerApi.getCenters(),
            staffApi.getStaffs(query),
        ]);

        const from = moment().startOf('hour');
        const to = from.clone().add(1, 'hour');

        this.setState({
            services: services.rows.sort((a, b) => ('' + a.name).localeCompare(b.name)),
            centers,
            staffs: staffs.rows,
            form: {
                ...this.state.form,
                from: from.toDate(),
                to: to.toDate(),
            }
        })
    }

    presetData(params) {
        console.log(this.state)

        // const { userId, serviceId, date, centerId, staffId } = params;
        const { serviceId, date, centerId, staffId } = params;
        const form = {};
        let pastTimeDisable = true;
        // if (userId) {
        //     form.user = userId;
        // }
        if (serviceId) {
            form.service = serviceId;
        }
        if (date) {
            if (moment(date) < Date.now() - 8.64e7) {
                pastTimeDisable = false;
            }
            form.date = moment(date).toDate();
        }
        if (centerId) {
            form.center = centerId;
        }
        if (staffId) {
            form.staff = staffId;
        }
        if (Object.keys(form).length) {
            this.setState({ form, pastTimeDisable });
        }
    }

    onUserSelected(user) {
        this.setState({
            selectedUser: user,
        });
        this.onChange('user', user.id);
    }

    Card(e) {
        console.log(e)
        this.state.message.splice(e, 1)
        this.setState({
            message: this.state.message
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        const { form, selectedUser, schedules } = this.state
        if (schedules.length > 0) {
            const { date, from, to, service, user, center, staff, newUserName, newUserPhoneNumber, bookingRemark, birthday } = form
            const data = {}
            data.bookingRemark = bookingRemark
            data.staffScheduleIds = schedules.concat()
            data.date = moment(date).format()
            data.serviceId = service
            data.startTime = moment(from).format()
            data.endTime = moment(to).format()
            data.center = center
            data.bookUserId = ""
            data.user = user
            data.birthday = birthday
            data.staff = staff
            data.newUserName = newUserName
            data.newUserPhoneNumber = newUserPhoneNumber
            if (selectedUser) {
                const { id, phone, name } = selectedUser
                const bookingUser = {}
                data.targetUserId = id
                bookingUser.name = name
                bookingUser.phone = phone
                data.bookingUser = bookingUser
            } else {
                data.targetUserId = ""
                data.bookingUser = {}
            }
            // console.log(data)


            this.state.message.push(data)
            this.setState({
                message: this.state.message
            })
            console.log(this.state.message)
        } else {
            alert('没有日程')
        }
    }

    async Deleted(e) {
        console.log(e.target)
        e.target.style.display = "none"
    }

    async onChange(key, value) {
        await new Promise(res => this.setState({
            form: {
                ...this.state.form,
                [key]: value,
            },
        }, res));
        const { form } = this.state;
        if (form.service && form.date) {
            await this.refreshScheduleList();
        }
    }

    async refreshScheduleList() {
        const { form, pastTimeDisable } = this.state;
        const year = moment(form.date).year()
        const month = moment(form.date).month()
        const day = moment(form.date).date()

        let start = moment();
        if (!pastTimeDisable || moment(form.date).startOf('day').format() !== moment().startOf('day').format()) {
            start = moment(form.date).startOf('day');
        }

        let end = start.clone().startOf('day').add(1, 'day');

        if (form.from) {

            var fromTime = moment(form.from)
            fromTime.year(year)
            fromTime.month(month)
            fromTime.date(day)
            start = fromTime
            form.from = fromTime.toDate()
        }

        if (form.to) {
            var toTime = moment(form.to)
            toTime.year(year)
            toTime.month(month)
            toTime.date(day)
            end = toTime
            form.to = toTime.toDate()
        }

        let service
        if (form.service.length > 0) {
            let length = form.service.length
            service = form.service[length - 1]
            const schedules = await scheduleApi.getBookableSchedules(service, start.toISOString(), end.toISOString(), {
                centerId: form.center || undefined,
                staffId: form.staff || undefined,
            });
            this.setState({
                schedules: schedules.sort((a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf()),
            }, () => {
                console.log(this.state.schedules)
            });
        }
    }

    handleDiscountTypeChange = (value, index, key) => {
        const { message } = this.state;
        
        // 克隆原始message数组
        const updatedMessage = [...message];
        
        // 更新指定index的discountType
        updatedMessage[index][key] = value;
        
        // 更新state
        this.setState({
            message: updatedMessage,
        });
    }

    render() {
        const { t, history } = this.props;
        const { form, centers, staffs, services, selectedUser, showConfirm, selectedBooking, pastTimeDisable, regAsNewClient, message, error, activeName,payTypeOptions } = this.state;
        //定义一个数组，将数据存入数组
        const elements = [];
        message.length > 0 &&
        message.forEach((item, i) => {
            let service = services.find(e => e.id == item.serviceId) 
            item.staffLevel = item.staffLevel || "normal"
            item.levelPrice = item.staffLevel == "normal" ? 0 : item.levelPrice
            item.discountType = item.discountType || "normal"
            item.discount = item.discountType == "normal" ? 0 : item.discount

            console.log(item)

            const totalPrice = (service.price || 0) + item.levelPrice;
            const discountPrice =
            item.discountType === "discount"
                ? totalPrice - totalPrice * (item.discount / 100)
                : item.discount;
            item.actualPrice = totalPrice - (discountPrice || 0);

            elements.push(
                <Collapse.Item title={ `预约${i + 1}` } name={`${i+1}`} key={i} style={{padding:'0px'}}>
                    <div style={{margin : '0'}} className={"box-card " + (item.status === 'success' ? 'success' : item.status === 'error' ? 'danger' : '')} key={i} ref="card">
                        {item.status === 'success'
                            ? <div className="text item">{t("add_booking_success")}</div>
                            : (item.status === 'error' ? <div className="text item">{error}</div> : null)
                        }
                        <span style={{ "lineHeight": "20px" }}>预约{i + 1}</span>
                        <span style={{ "float": "right", "marginRight": "5%", "cursor": "pointer" }} onClick={this.Card.bind(this, i)}>×</span>
                        <div className="text item">{t("staff_schedule_dialog_date")} : {moment(item.date).format('YYYY-MM-DD')}</div>
                        <div className="text item">{t("staff_schedule_dialog_time")} : {moment(item.startTime).format('HH:mm:ss')}-{moment(item.endTime).format('HH:mm:ss')}</div>
                        <div className="text item">{t("add_booking_sub_title_center")} : {item.centerName}</div>
                        <div className="text item">{t("staff_schedule_dialog_service")} : {item.serviceName}</div>
                        <div className="text item">{t("add_booking_sub_title_client")} : {item.bookingUser ? item.bookingUser.name : item.newUserName}</div>
                        <div className="text item">{t("add_booking_sub_title_client_number")} : {item.bookingUser ? item.bookingUser.phone : item.newUserPhoneNumber}</div>
                        <div className="text item">{t("add_booking_sub_title_therapist")} : {item.staff.name}</div>
                        <div className='text item'>
                            {"星级 : "}
                            <Select value={item.staffLevel} onChange={(e) => this.handleDiscountTypeChange(e, i , "staffLevel")}>
                                <Select.Option key="normal" label="普通" value="normal"/>
                                <Select.Option key="senior" label="資深" value="senior"/>
                                <Select.Option key="chief" label="首席" value="chief"/>
                            </Select>
                        </div>
                        <div className="text item" style={{display:"flex",alignItems:"center"}}>
                            {"加價 : "}
                            <div style={{marginLeft:"5px"}}>
                                <InputNumber disabled={item.staffLevel == "normal"} defaultValue={0} value={item.levelPrice} onChange={(e) => this.handleDiscountTypeChange( e, i , "levelPrice")} controls={false}></InputNumber>
                            </div>
                        </div>
                        <div className="text item">
                           {"折扣類型 : "}
                            <Radio.Group value={item.discountType} onChange={(e) => this.handleDiscountTypeChange(e, i , "discountType")}>
                                <Radio value="normal">照常</Radio>
                                <Radio value="relief">减免</Radio>
                                <Radio value="discount">打折</Radio>
                            </Radio.Group>
                        </div>
                        <div className="text item" style={{display:"flex",alignItems:"center"}}>
                            {item.discountType === 'relief' ? '減免金額 : ' : '折扣比 : '}
                            <div style={{marginLeft:"5px"}}>
                                <InputNumber value={item.discount} defaultValue={0} disabled={item.discountType == "normal"} onChange={(e) => this.handleDiscountTypeChange( e, i , "discount")} controls={false}></InputNumber>
                            </div>
                        </div>
                        <div className='text item'>
                            <Checkbox checked={item.depositCheck} onChange={(e) => this.handleDiscountTypeChange( e, i , "depositCheck")} >收取定金</Checkbox>
                        </div>
                        <div className='text item'>
                            {"定金金额 : "}
                            <InputNumber disabled={!item.depositCheck} defaultValue={0} value={item.depositPrice} onChange={(e) => this.handleDiscountTypeChange( e, i , "depositPrice")} controls={false}></InputNumber>
                        </div>
                        <div className='text item'>
                            {"定金支付类型 : "}
                            <Select value={item.depositType} disabled={!item.depositCheck} onChange={(e) => this.handleDiscountTypeChange( e, i , "depositType")} placeholder="請選擇">
                                {
                                    payTypeOptions.map(el => {
                                        return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                            </Select>
                        </div>
                        <div className='text item'>
                            {"實際價格 : "} {item.actualPrice}
                        </div>
                        {!selectedUser
                            ? <div className="text item"><Checkbox checked={item.regAsNewClient} disabled={true}>{t('register_as_new_client')}</Checkbox></div>
                            : null
                        }
                    </div>
                </Collapse.Item>
            )
        });

        return (
            <div className="add-booking-contanier">
                <div className="page-title">{t('add_booking_title')}<Button className='button' onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button></div>
                <Layout.Row gutter="50">
                    <Layout.Col lg="16" md="16" sm="24" ref={divElement => (this.divElement = divElement)}>
                        <div className="form-group" >
                            <Form ref="form" model={this.state.form} rules={this.state.rules} labelWidth="120" labelPosition='left' onSubmit={(e) => this.onSubmit(e)}>
                                <div style={{ marginBottom: '20px' }}>
                                    <UserSearchBar defaultUser={form.user} className="search-bar" onUserSelected={(user) => this.onUserSelected(user)} />
                                </div>
                                <Form.Item label={t("add_booking_user_name")}>
                                    <Input disabled={selectedUser ? true : false} placeholder="" value={selectedUser ? selectedUser.name + ' ' + selectedUser.surname : form.newUserName} onChange={(value) => this.onChange('newUserName', value)} />
                                </Form.Item>
                                <Form.Item label={t("add_booking_user_number")}>
                                    <Input type="text" disabled={selectedUser ? true : false} placeholder="" value={selectedUser ? selectedUser.phone : this.state.form.newUserPhoneNumber} onChange={(value) => this.onChange('newUserPhoneNumber', value)} />
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox checked={regAsNewClient} disabled={selectedUser ? true : false} onChange={(value) => {
                                        this.setState({ regAsNewClient: value })
                                    }}>{t('register_as_new_client')}</Checkbox>
                                </Form.Item>
                                {
                                    this.state.regAsNewClient ? (
                                        <div>
                                            <Form.Item label={t('user_board_edit_gender')}>
                                                <Radio.Group value={selectedUser ? selectedUser.gender : form.gender}
                                                    onChange={(e) => {
                                                        this.onChange('gender', e)
                                                    }}>
                                                    <Radio value="male" disabled={selectedUser ? true : false}>{t('mela')}</Radio>
                                                    <Radio value="female" disabled={selectedUser ? true : false}>{t('femela')}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item label={t('user_board_edit_birthday')}>
                                                <DatePicker
                                                    isDisabled={selectedUser ? true : false}
                                                    value={selectedUser && selectedUser.birthday ? moment(selectedUser.birthday).toDate() : form.birthday}
                                                    onChange={date => { this.onChange("birthday", date); }}
                                                    disabledDate={time => time.getTime() > Date.now() - 8.64e7}
                                                />
                                            </Form.Item>
                                            <Form.Item label={t('user_board_edit_hkid')}>
                                                <Input disabled={selectedUser ? true : false} value={selectedUser ? selectedUser.hkid : form.hkid} onChange={(value) => this.onChange("hkid", value)} />
                                            </Form.Item>
                                            <Form.Item label={t('user_board_edit_email')} >
                                                <Input disabled={selectedUser ? true : false} value={selectedUser ? selectedUser.email : form.email} onChange={(value) => this.onChange("email", value)} />
                                            </Form.Item>
                                            <Form.Item label={t('user_board_edit_address')}>
                                                <Input disabled={selectedUser ? true : false} value={selectedUser ? selectedUser.address : form.address} onChange={(value) => this.onChange("address", value)} />
                                            </Form.Item>
                                            <Form.Item label={t('user_board_edit_customer_class')}>
                                                <Select disabled={selectedUser ? true : false} value={selectedUser ? selectedUser.user_classifies && selectedUser.user_classifies.length > 0 && selectedUser.user_classifies[0].customerClass : form.customerClass} placeholder={t('select_client_category')} onChange={(value) => this.onChange("customerClass", value)}>
                                                    {
                                                        this.state.customerClass.map(el => {
                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }
                                <Form.Item label={t('add_booking_sub_title_appointment')}>
                                    <Cascader
                                        value={form.service}
                                        placeholder={t('please_choose')}
                                        props={this.state.prop}
                                        options={this.state.intentionalService}
                                        filterable={true}
                                        onChange={(value) => this.onChange("service", value)}
                                    ></Cascader>
                                </Form.Item>
                                <Form.Item label={t("add_booking_sub_title_date")}>
                                    <DatePicker
                                        value={form.date}
                                        format="yyyy-MM-dd"
                                        placeholder="Select Date"
                                        onChange={date => this.onChange('date', date)}
                                        disabledDate={time => pastTimeDisable ? time.getTime() < Date.now() - 8.64e7 : pastTimeDisable}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <TimeSelect
                                        start="08:00"
                                        step="00:15"
                                        end="22:00"
                                        onChange={(time) => this.onChange('from', time)}
                                        value={form.from}
                                        placeholder="From"
                                    />
                                    {' - '}
                                    <TimeSelect
                                        start="08:00"
                                        step="00:15"
                                        end="22:00"
                                        onChange={(time) => this.onChange('to', time)}
                                        value={form.to}
                                        minTime={form.from}
                                        placeholder="To"
                                    />
                                </Form.Item>

                                <Form.Item label={t("add_booking_sub_title_center")}>
                                    <Select
                                        value={form.center}
                                        disabled={centers.length <= 0}
                                        onChange={(e) => this.onChange('center', e)}
                                        placeholder="Select">
                                        <Select.Option label={t("add_booking_option_any")} value={''} />
                                        {centers.map(center => (
                                            <Select.Option key={center.id} label={center.center_details[0].name} value={center.id} />
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={t("add_booking_sub_title_therapist")}>
                                    <Select
                                        value={form.staff}
                                        disabled={staffs.length <= 0}
                                        onChange={(e) => this.onChange('staff', e)}
                                        placeholder="Select">
                                        <Select.Option label={t("add_booking_option_any")} value={''} />
                                        {staffs.map(staff => (
                                            <Select.Option key={staff.id} label={staff.staff_details[0].name} value={staff.id} />
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={t("add_booking_remark")}>
                                    <Input type="text" placeholder="" onChange={(value) => this.onChange('bookingRemark', value)} />
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="schedule-list">
                            <Table
                                style={{ width: '100%' }}
                                columns={this.state.columns}
                                maxHeight={300}
                                data={this.state.schedules}
                            />
                        </div>
                    </Layout.Col>
                    <Layout.Col lg="8" md="8" sm="24" style={{ "height": "969px" }}>
                        <section className='message-flex'>
                            {
                                this.state.selectedUser && this.state.selectedUser.id ? (
                                    <RecordsList userId={selectedUser.id}></RecordsList>
                                ) : null
                            }
                            <div style={{padding : '0 2px 7px'}} className="pages-title">{t('message_title')}</div>
                            <div className="message-view">
                                <Collapse value={activeName}>
                                    {elements}
                                </Collapse>     
                                <Button style={{ display: message.length === 0 ? 'none' : "inline-block", margin: "7px 0", }} onClick={() => { this.reservation(message) }} >预约</Button>       
                            </div>
                        </section>
                    </Layout.Col>
                </Layout.Row>
                <Dialog size={'tiny'} title={t('confirm_dialog_title')} visible={showConfirm} onCancel={() => this.setState({ showConfirm: false })}>
                    <Dialog.Body>
                        <div className="dialog">
                            <div className="dialog-body">
                                <div className="row">
                                    <span style={{ "marginRight": "10px" }}>{t('input_color')}</span>
                                    <ColorPicker value={this.state.colorValue1}></ColorPicker>
                                </div>
                                <div className="row">
                                    <Button disabled={this.state.loading} type="primary" onClick={() => this.addToBookings(selectedBooking)}>{t('confirm_dialog_button_yes')}</Button>
                                    {/* <Button disabled={this.state.loading} type="primary" onClick={() => this.book(selectedBooking)}>{t('confirm_dialog_button_yes')}</Button> */}
                                    <Button onClick={() => this.setState({ showConfirm: false })}>{t('confirm_dialog_button_no')}</Button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Body>
                </Dialog>
            </div>
        )
    }
}