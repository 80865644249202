import {translate} from "react-i18next";
import React, {Component} from "react";
import {Layout, Dialog, Message, Button} from 'element-react'
import {Table} from "antd";
import * as productOrderDetailsApi from '../../../../api/productOrderDetails'
import './order-details-dialog.scss'
import moment from "moment";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

@translate()

export default class OrderDetailsDialog extends Component {
    state = {
        visible:true,
        orderDetails: {},
        productsList : [],
        columns: [
            {
                title : '產品名稱',
                dataIndex : 'name',
                key:'name',
            },
            {
                title : '數量',
                dataIndex: 'count',
                key: 'count',
                render(_ , row) {
                    return <div>
                        {row.count ? parseFloat(row.count).toFixed(2) : 0.00}
                    </div>
                }
            },
            {
                title: '單價',
                dataIndex: 'price',
                key : 'price',
                render(_ , row) {
                    return <div>
                        {row.price ? parseFloat(row.price).toFixed(2) : 0.00}
                    </div>
                }
            }
        ]
    }

    selectById() {
        const {id} = this.props.orderDetails
        productOrderDetailsApi.selectByOrderId(id).then(result => {
            const { code , data } = result.data
            if(code !== 200) return
            this.setState({productsList:data})
            console.log(this.state.productsList)
        }).catch(err => {
            Message.error(err)
        })
    }

    px2mm(px) {
        var ratio = 0;
        var div = document.createElement('div')
        div.style.width = '1cm'
        div.id = 'puc'
        document.body.appendChild(div)
        var w = getComputedStyle(div, null).width
        ratio = w.substr(0, w.length - 2)
        div.parentNode.removeChild(div)

        var r = ratio / 10
        return Math.round(px / r)
    }

    async downloadDPF() {
        const element = document.getElementById("orderDetailsMain")
        // const element = document.getElementsByClassName("el-dialog")
        const w = element.offsetWidth;  // 获得该容器的宽
        const h = element.offsetHeight;  // 获得该容器的高
        const offsetTop = element.offsetTop; // 获得该容器到文档顶部的距离
        const offsetLeft = element.offsetLeft; // 获得该容器到文档最左的距离
        const canvas = document.createElement("canvas")
        let abs = 0;
        const win_i = document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
        const win_o = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
        if(win_o > win_i){
            abs = (win_o - win_i) / 2; // 获得滚动条宽度的一半
        }
        canvas.width = w * 2; // 将画布宽&&高放大两倍
        canvas.height = h * 2;
        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.translate(-offsetLeft - abs, -offsetTop);
        html2canvas(element , {
            allowTaint: true,
            scale: 2
        }).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            // 一页pdf显示html页面生成的canvas高度
            const pageHeight = this.px2mm(contentHeight / 2);
            // 未生成pdf的html页面高度
            let leftHeight = contentHeight;
            // 页面偏移
            let position = 0;
            // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            const imgWidth = 297;
            const imgHeight = this.px2mm(contentHeight / 2)
            // 通过 html2canvas 将 html 渲染成 canvas，然后获取图片数据
            const pageDate = canvas.toDataURL('image/jpeg', 1.0);
            // 设置pdf 格式
            var doc = new jsPDF({
                orientation: "landscape",
                unit: 'mm',
                format: 'a4',
            })
            doc.setFontSize(10)
            // 设置 a4 纸尺寸
            // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面的高度（841.89）
            // 当内容未超过pdf一页显示的范围，无需分页
            if(leftHeight < pageHeight) {
                doc.addImage(pageDate,'JPEG', 0, position, imgWidth, imgHeight);
            }else { // 分页
                while (leftHeight > 0){
                    doc.addImage(pageDate, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 210;
                    // 避免添加空白页
                    if(leftHeight > 0){
                        doc.addPage()
                    }
                }
            }
            const date = Date().split(" ");
            const dateStr = date[1] + date[2] + date[3] + date[4];
            const {id} = this.props.orderDetails
            doc.save(`${id}_print_${dateStr}.pdf`);
        })
    }

    componentDidMount() {
        this.selectById()
    }


    render() {
        const { columns , productsList , visible } = this.state
        const {orderDetails , isClosed} = this.props
        return (
            <main className='order-details-main' >
                <Dialog title='订单详情' visible={visible} onCancel={ isClosed}>
                    <Button className='print' onClick={()=>this.downloadDPF()}>打印訂單</Button>
                    <div className='order-details-container' id="orderDetailsMain">
                        <section className='order-details-title'>
                            <Layout.Row gutter='20'>
                                <Layout.Col xs="24" sm="24" md="12" lg="12">
                                    <div>訂單編號：{orderDetails.id}</div>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="12" lg="12">
                                    <div>用戶名：{orderDetails.userSurname ? orderDetails.userSurname + orderDetails.username : orderDetails.username}</div>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="12" lg="12">
                                    <div>銷售時間: {moment(orderDetails.date).format('YYYY-MM-DD HH:mm')}</div>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="12" lg="12">
                                    <div>銷售人: {orderDetails.staffSurname ? orderDetails.staffSurname + orderDetails.staffname : orderDetails.staffname}</div>
                                </Layout.Col>
                            </Layout.Row>
                        </section>
                        <section className='product-list'>
                            <Table columns={columns} dataSource={productsList} pagination={false}></Table>
                        </section>
                    </div>
                </Dialog>
            </main>
        )
    }
}