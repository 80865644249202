import {translate} from "react-i18next";
import AccountIcon from "../../../../components/icon.component";
import React, {Component} from "react";
import {Button, Table} from "antd";
import {DateRangePicker, Form, Input, Layout} from "element-react"
import {  FileExcelFilled } from '@ant-design/icons';
import './repertory-report.scss'
import * as reportApi from "../../../../api/report.api"
import moment from "moment";
import {getRepertoryReport} from "../../../../api/report.api";
import { removeEmptyFiled } from '../../../../utils/removeEmptyFiled'
import { downloadExcel } from "../../../../utils/execlUtils";

@translate()
export default class repertoryReport extends Component {
    state = {
        total : 100,
        tableData : [],
        loading : false,
        search : {
            page : 1,
            limit : 40,
            keywords : "",
            orders:[],
        },
        exportList :[
            {label:"产品编号" , value :''},
            {label:"產品名" , value :''},
            {label:"總存量" , value :''},
            {label:"產品圖片" , value :''},
        ],
        columns : [
            {
                title: '產品編號',
                dataIndex : 'productId',
                sorter: {
                    multiple: 1,
                },
            },
            {
                title: '產品名',
                dataIndex: 'name',
            },
            {
                title: '类型',
                dataIndex: 'type',
                render: (_ , row) => {
                    return <div>{row.type == 'in' ? "入庫" : "出庫"}</div>
                }
            },
            {
                title: '單價',
                dataIndex: 'price',
                render: (_,row) => {
                    return <div>{(parseFloat(row.price)  || 0).toFixed(2)}</div>
                },
                sorter: {
                    multiple: 3,
                },
            },
            {
                title: '數量',
                dataIndex: 'count',
                render: (_,row) => {
                    return <div>{(parseFloat(row.count)  || 0).toFixed(2)}</div>
                }
            },
            {
                title: '成本',
                dataIndex: 'cost',
                render : (_,row) => {
                    return <span>
                        {(parseFloat(row.cost)  || 0).toFixed(2)}
                    </span>
                }
            },
            {
                title: "時間",
                sorter: {
                    multiple: 4,
                },
                dataIndex: 'date',
                render : (_,row) => {
                    return <div>{moment(row.date).format('YYYY-MM-DD HH:mm')}</div>
                }
            }
        ]
    }

    onExportExcel(name,columns,list) {
        if (list.length == 0) return
        downloadExcel({
          filename: name,
          sheets: [{
            sheetName: name,
            columns: columns,
            dataSource: list
          }]
        })
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, { [key]: value })
        });
    }


    pageAndOrder(pagination, filters, sorter, extra) {
        console.log(pagination)
        let orders = []
        if (sorter.length > 0) {
            sorter.forEach(item => {
                let obj = {
                    field:item.field,
                    orders:item.order == "ascend"?"ASC":"DESC"
                }
                orders.push(obj)
            });
        } else if (sorter.field) {
            orders.push({
                field:sorter.field,
                orders:sorter.order == "ascend"?"ASC":"DESC"
            })
        }
        this.setState({
            search: Object.assign({}, this.state.search, {
                orders,
                page:pagination.current,
                limit: pagination.pageSize
            })
        },() => {
            this.getData()
        })
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  moment(moment(search.date[0]).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss"),
            query.endTime = moment(moment(search.date[1]).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        return removeEmptyFiled(query)
    }

    getData() {
        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data
        }
        

        this.setState({
            loading: true
        })

        reportApi.getRepertoryReport(query).then(result => {
            const {data} = result
            this.state.tableData = data.data
            this.state.total = data.count
        }).catch(err => {
            this.setState({
                loading: false
            })
        }).finally( () => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount(){
        this.getData()
    }


    render() {
        const {search , columns , tableData , total} = this.state
        return (
            <main className='repertory-main'>
                <section className='search-info'>
                    <Form ref='form' model={search} labelWidth='75' labelPosition="left" className="rule-form">
                        <Layout.Row gutter="20">
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="日期時間" prop="date">
                                    <DateRangePicker value={search.date} placeholder="選擇日期範圍" onChange={this.onChange.bind(this,'date')} align="right" style={{width:'100%'}} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="查詢框">
                                    <Input value={search.keywords} placeholder="請輸入" onChange={this.onChange.bind(this, 'keywords')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <div className='submit-buttons' style={{textAlign:"left"}}>
                                    {/*<Button onClick={() => this.setState({exportVisibled:true})}>導出</Button>*/}
                                    <Button onClick={() => this.getData()}>查詢</Button>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='repertory-table-container'>
                    <div className='repertory-table-main'>
                        <Table columns={columns} dataSource={tableData} pagination={{"total":total , showSizeChanger:true}} onChange={this.pageAndOrder.bind(this)}
                        title ={() => {
                            return (
                                <main style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
                                    <div>
                                        <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportExcel("積分報表",columns,tableData)}></Button>
                                    </div>
                                </main>
                            )
                        }}
                        ></Table>
                    </div>
                </section>
            </main>
        )
    }
}