import React, { Component } from 'react';
import { translate } from 'react-i18next';
import "./record.scss"
import { Button, Form,DatePicker, Col, Row,Table,  Typography } from 'antd';
import {  FileExcelFilled } from '@ant-design/icons';
import moment from 'moment';
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import * as reportApi from "../../../api/report.api";
import * as ExcelJs from "exceljs";
import {
    DEFAULT_COLUMN_WIDTH, 
    generateHeaders,
    getColumnNumber,
    addData2Table,
    multiHeader,
    saveWorkbook,
} from "../../../utils/execlUtils";

const { RangePicker } = DatePicker;
const { Text } = Typography;
@translate()
export default class RevenueSummaryReport extends Component {
    formRef = React.createRef();
    state = {
        search:{
            date:[moment(new Date()),moment(new Date())]
        },
        loading:false,
        result:[],
        gednerResult:[],
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Master",
                label: "Master"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            },
            {
                value: "現金",
                label: "現金"
            },{
                value:"Octpus",
                label: "Octpus"
            },{
                value:"Kpay",
                label: "Kpay"
            }
        ],
        isShowRow:false,
        rowObject:{},
        columns: [
            {
                title:"業績類別",
                dataIndex: 'type',
                key:"type",
                width: 140,
                render:(_,row)=>{
                    let type = {
                        times:"次卡銷售",
                        products:"產品業績",
                        reservations:"服務業績",
                        topUp:"充值業績",
                        other:"其他收入",
                        deposit:"定金"
                    }
                    return  <div style={{width:140}}>
                        {type[row.type]} 
                        {<Button type="link" size="small" onClick={() => {this.getDetails(row)}}>明细</Button>}
                        </div>
                },
                fixed: 'left',
            },
            {
                title:"業績",
                dataIndex: 'achievement',
                key:"achievement",
                width: 200,
                children:[
                    {
                        title:"總計",
                        dataIndex: 'achievement',
                        key:"achievement",
                        width: 100, 
                        render(_,row){
                            let reg = new RegExp("(?<!_amountAfter)$")
                            let price = Object.keys(row).reduce((pre,current) => {
                                return pre + (current != "type" && current != "item" && reg.test(current) ? parseFloat(row[current]) : 0)
                            },0)
                            return <div style={{width:100}}>{price || 0}</div>
                        }
                    },
                    {
                        title:"實際",
                        dataIndex:"achievement_amountAfter",
                        key:"achievement_amountAfter",
                        width: 100,
                        render(_,row){
                            let reg = new RegExp("(?<!_amountAfter)$")
                            let price = Object.keys(row).reduce((pre,current) => {
                                if (row.type == "reservations") {
                                    return pre + (current != "type" && current != "item" && !reg.test(current) ? parseFloat(row[current]) : 0)
                                } else {
                                    return pre + (current != "type" && current != "item" && reg.test(current) ? parseFloat(row[current]) : 0)
                                }
                            },0)
                            return <div style={{width:100}}>{price || 0}</div>
                        }
                    }
                ]
            },
            {
                title:"免單",
                dataIndex:"free",
                key:"free",
                width:100,
                render(_,row){
                    return <div style={{width:100}}>{row["free"] || 0}</div>
                }
            },
            {
                title:"點數",
                dataIndex:"balance",
                key:"balance",
                width: 200,
                children:[
                    {
                        title:"總計",
                        dataIndex:"balance",
                        key:"balance",
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row["balance"] || 0}</div>
                        }
                    },
                    {
                        title:"實際",
                        dataIndex:"balance_amountAfter",
                        key:"balance_amountAfter",
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row["balance_amountAfter"] || 0}</div>
                        }
                    }
                ]
            },
            {
                title:"次卡",
                dataIndex:"times",
                key:"times",
                width: 200,
                children:[
                    {
                        title:"總計",
                        dataIndex:"times",
                        key:"times",
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row["times"] || 0}</div>
                        }
                    },
                    {
                        title:"實際",
                        dataIndex:"times_amountAfter",
                        key:"times_amountAfter",
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row["times_amountAfter"] || 0}</div>
                        }
                    }
                ]
            },
        ],
        gednerColumns : [
            {
                title:"業績類別",
                dataIndex: 'type',
                key:"type",
                render(_,row){
                    let type = {
                        times:"次卡銷售",
                        products:"產品業績",
                        reservations:"服務業績",
                        topUp:"充值業績",
                        other:"其他收入"
                    }
                    return <div style={{width:140}}>{type[row.type]}</div>
                },
                fixed: 'left',
            },
            {
                title:"業績",
                dataIndex: 'achievement',
                key:"achievement",
                children:[
                    {
                        title:"總計",
                        dataIndex: 'achievement',
                        key:"achievement",
                        render(_,row){
                            let total = Object.keys(row).reduce((pre,current) => {
                                return pre + ((current == "other" || current == "female" || current == "male") ? row[current] : 0 )
                            },0)
                            return <div style={{width:140}}>{total}</div>
                        },
                    },
                    {
                        title:"實際",
                        dataIndex: 'achievement_after',
                        key:"achievement_after",
                        render(_,row){
                            let total = Object.keys(row).reduce((pre,current) => {
                                return pre + ((current == "other_amountAfter" || current == "female_amountAfter" || current == "male_amountAfter") ? row[current] : 0 )
                            },0)
                            return <div style={{width:140}}>{total}</div>
                        },
                    },
                ],
            },
            {
                title:"客數",
                dataIndex: 'times',
                key:"times",
                render(_,row){
                    let total = Object.keys(row).reduce((pre,current) => {
                        return pre + ((current == "other_times" || current == "female_times" || current == "male_times") ? row[current] : 0 )
                    },0)
                    return <div style={{width:140}}>{total}</div>
                },
            },
            {
                title:"客單價",
                dataIndex: 'price',
                key:"price",
                render(_,row){
                    let total = Object.keys(row).reduce((pre,current) => {
                        return pre + ((current == "other" || current == "female" || current == "male") ? row[current] : 0 )
                    },0)
                    let times = Object.keys(row).reduce((pre,current) => {
                        return pre + ((current == "other_times" || current == "female_times" || current == "male_times") ? row[current] : 0 )
                    },0)
                    return <div style={{width:140}}>{(total / times).toFixed(2) || 0}</div>
                },
            },

        ]
    }

    onFinish = (values) => {
        let data = {
            ...values
        }
        this.setState({
            search:Object.assign({}, this.state.search, { ...data })
        },()=>{
            this.getData()
        })
    }

    getDetails(row){
        this.setState({
            isShowRow:true,
            rowObject:row
        })
    }

    formatRequest(search) {
        let query = {
            ...search,
        }
        if (search.date &&  search.date.length > 0) {
            query.startTime =  search.date[0].format('YYYY-MM-DD HH:mm:ss')
            query.endTime = search.date[1].format('YYYY-MM-DD HH:mm:ss')
        } 
        if (search.birthday) {
            query.birthday = search.birthday.format('YYYY-MM') + "-01 00:00:00"
        }
        delete query.date

        return removeEmptyFiled(query)
    }

    expandedRowRender = (record) => {
        let columns = []
        let dataSource = []

        if (record.item) {
            dataSource = record.item.filter(e=> !e.isDeleted)
        }
        if (record.type == "reservations") {
            columns = [
                {   
                    title: '日期', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.startTime).format("YYYY-MM-DD")}</div>
                    },
                },
                {   
                    title: '時間', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.startTime).format("HH:mm")} - {moment(row.endTime).format("HH:mm")}</div>
                    },
                },
                {   title: '客戶', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        if (row.userId) {
                            return  <div>{row.surname} {row.name}</div>
                        } else {
                            return <div>{JSON.parse(row.userInfo).name}</div>
                        }
                    },
                },
                {
                    title: '服務', 
                    align: "center", 
                    dataIndex:"serviceName",
                    ellipsis: true ,
                },
                {
                    title: '服務價格', 
                    align: "center", 
                    dataIndex:"price",
                    ellipsis: true ,
                },
                {
                    title: '成交價', 
                    align: "center", 
                    dataIndex:"amountAfterDiscount",
                    ellipsis: true ,
                },
                {
                    title: '師傅', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return <div>{row.staffSurname} {row.staffName}</div>
                    },
                },
                {
                    title: '結算方式', 
                    align: "center", 
                    dataIndex:"payType",
                    ellipsis: true ,
                },
                {
                    title: '支付方式', 
                    align: "center", 
                    dataIndex:"payment",
                    ellipsis: true ,
                },
            ];
        } else if (record.type == "topUp") {
            columns = [
                {   
                    title: '日期', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.createdAt).format("YYYY-MM-DD")}</div>
                    },
                },
                {   
                    title: '時間', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.createdAt).format("HH:mm")}</div>
                    },
                },
                {   title: '客戶', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        if (row.userId) {
                            return  <div>{row.surname} {row.name}</div>
                        } else {
                            return <div>{JSON.parse(row.userInfo).name}</div>
                        }
                    },
                },
                {
                    title: '充值金額', 
                    align: "center", 
                    ellipsis: true ,
                    dataIndex:"amount",
                },
                {
                    title: '支付方式', 
                    align: "center", 
                    ellipsis: true ,
                    dataIndex:"payment",
                }
            ]
        } else {
            columns = [
                {   
                    title: '日期', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.createdAt).format("YYYY-MM-DD")}</div>
                    },
                },
                {   
                    title: '時間', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.createdAt).format("HH:mm")}</div>
                    },
                },
                {   title: '客戶', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        if (row.userId) {
                            return  <div>{row.userSurName} {row.userName}</div>
                        } else {
                            return <div>{JSON.parse(row.userInfo).name}</div>
                        }
                    },
                },
                {   
                    title: '套餐名称', 
                    align: "center", 
                    ellipsis: true ,
                    dataIndex:"name",
                },
                {   
                    title: '套餐价格', 
                    align: "center", 
                    ellipsis: true ,
                    dataIndex:"price",
                },
                {
                    title: '支付方式', 
                    align: "center", 
                    ellipsis: true ,
                    dataIndex:"payment",
                },
                {
                    title: '过期時間', 
                    align: "center", 
                    ellipsis: true ,
                    render(_,row){
                        return  <div>{moment(row.expireDate).format("YYYY-MM-DD HH:mm")}</div>
                    },
                }
            ]
        }
        return <Table columns={columns} dataSource={dataSource} />;
    };

    getData(){
        this.setState({loading:true})

        const { search } = this.state

        let data = this.formatRequest(search)

        let query = {
            ...data,
        }

        if (data.startTime && data.endTime) {
            query.startTime = moment(moment(data.startTime).startOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss") 
            query.endTime = moment(moment(data.endTime).endOf('day').toISOString()).format("YYYY-MM-DD HH:mm:ss")
        }

        reportApi.revenueSummaryRecord(query).then(result => {
            const { data:{ data } } = result
            if (data.length == 0) return
            this.setState({
                result:data
            })
        }).finally(()=>{
            this.setState({loading:false})
        })

        reportApi.revenueSummaryGender(query).then(result => {
            const { data:{ data } } = result
            this.setState({
                gednerResult:data
            })
        }).finally(()=>{
            this.setState({loading:false})
        })
    };

    componentDidMount(){
        const { payTypeOptions,columns } = this.state
        payTypeOptions.forEach(item => {
            columns.push({
                title:item.value,
                dataIndex:item.value,
                key:item.value,
                width: 200,
                children:[
                    {
                        title:"總計",
                        dataIndex:item.value,
                        key:item.value,
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row[item.value] || 0}</div>
                        }
                    },
                    {
                        title:"實際",
                        dataIndex:item.value + "_amountAfter",
                        key:item.value + "_amountAfter",
                        width: 100,
                        render(_,row){
                            return <div style={{width:100}}>{row[item.value + "_amountAfter"] || 0}</div>
                        }
                    }
                ]
            })
        })
        columns.push({
            title:"其他",
            dataIndex:"other",
            key:"other",
            children:[
                {
                    title:"總計",
                    dataIndex:"other",
                    key:"other",
                    render(_,row){
                        return <div style={{width:100}}>{row["other"] || 0}</div>
                    }
                },
                {
                    title:"實際",
                    dataIndex:"other_amountAfter",
                    key:"other_amountAfter",
                    render(_,row){
                        return <div style={{width:100}}>{row["other_amountAfter"] || 0}</div>
                    }
                }
            ]
        })
        this.getData()
    }

    onExportMultiHeaderExcel(params) {
        // 创建工作簿
        const workbook = new ExcelJs.Workbook();
        // 添加sheet
        const worksheet = workbook.addWorksheet('demo sheet');
        // 设置 sheet 的默认行高
        worksheet.properties.defaultRowHeight = 20;
        // 解析 AntD Table 的 columns
        const headers = generateHeaders(params.columns);
        console.log({headers})
        // 第一行表头
        const names1 = [];
        // 第二行表头
        const names2 = [];
        // 用于匹配数据的 keys
        const headerKeys = [];
        headers.forEach(item => {
          if (item.children) {
            // 有 children 说明是多级表头，header name 需要两行
            item.children.forEach(child => {
              names1.push(item.header);
              names2.push(child.header);
              headerKeys.push(child.key);
            });
          } else {
            const columnNumber = getColumnNumber(item.width);
            for (let i = 0; i < columnNumber; i++) {
              names1.push(item.header);
              names2.push(item.header);
              headerKeys.push(item.key);
            }
          }
        });
        multiHeader(worksheet, headers, names1, names2);
        // 添加数据
        addData2Table(worksheet, headerKeys, headers,params.dataSource,params.columns);
        // 给每列设置固定宽度
        worksheet.columns = worksheet.columns.map(col => ({ ...col, width: DEFAULT_COLUMN_WIDTH }));
        // 导出excel
        saveWorkbook(workbook, params.name);
    }

    render(){
        const { loading,result,columns,gednerResult,gednerColumns,rowObject,isShowRow } = this.state
        return (
            <main className='report-contanier'>
                {
                    isShowRow ? 
                    <section>
                        <Button onClick={() => this.setState({isShowRow:false,rowObject:{}})} style={{marginBottom:"10px"}}>返回</Button>
                        {this.expandedRowRender(rowObject)}
                    </section>
                    :
                    <section>
                        <div className='search-contanier'>
                            <Form ref={this.formRef} initialValues={{ date:[moment(new Date()),moment(new Date())] }} onFinish={this.onFinish.bind(this)} autoComplete="off">
                                <Row gutter={20}>
                                    <Col span={6} style={{ textAlign: 'left' }}>
                                        <Form.Item name="date" label="查詢日期">
                                            <RangePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'left' }}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                查詢
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='table-contanier'>
                            <div className='table-main'>
                                <Table tableLayout="auto" scroll={{x:4000,y:true}} columns={columns} dataSource={result} pagination={false} loading={loading} bordered={true}
                                title={(currentPageData) => {
                                    let type = {}
                                    let reg = new RegExp("(?<!_amountAfter)$")
                                    let total = currentPageData.reduce((pre,current)=>{
                                        let price = Object.keys(current).reduce((p,c) => {
                                            if(c != "type" && c != "item" && c != "free_amountAfter" && reg.test(c)) {
                                                type[c] = type[c] || 0
                                                type[c]+= parseFloat(current[c])
                                            }
                                            return p + (c != "type" && c != "item" && reg.test(c) ? parseFloat(current[c]) : 0)
                                        },0)
                                        return pre + price
                                    },0)
                                    let totalAmount = currentPageData.reduce((pre,current)=>{
                                        let price = Object.keys(current).reduce((p,c) => {
                                            if(c != "type" && c != "item" && c != "free_amountAfter" && !reg.test(c)) {
                                                type[c] = type[c] || 0
                                                type[c]+= parseFloat(current[c])
                                            }
                                            return p + (c != "type" && !reg.test(c) ? parseFloat(current[c]) : 0)
                                        },0)
                                        return pre + price
                                    },0)

                                    let typeLabel = {
                                        free:"免單",
                                        times:"使用次卡",
                                        balance:"點數支付",
                                        other:"其他"
                                    }
                                    return (
                                        <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                            <div>
                                                <p style={{margin:0,fontSize:18}}>營業收入:
                                                    <span style={{fontSize:16,color:"#f44447"}}> 總計: {total}</span>
                                                    <span style={{fontSize:16,color:"#f44447"}}> ( </span>
                                                    {
                                                        Object.keys(type).length > 0 ? Object.keys(type).filter(item => reg.test(item)).map(item => {
                                                            return <span key={item} style={{margin:"0 5px",fontSize:14,color:"#f44447"}}> { typeLabel[item] ?typeLabel[item] : item }  : {type[item] }</span>
                                                        }) : null
                                                    }
                                                    <span style={{fontSize:16,color:"#f44447"}}> ) </span>
                                                </p>
                                                <p style={{margin:0,fontSize:18}}>
                                                    <span style={{fontSize:16,color:"#f44447"}}>  實際: {totalAmount}</span>
                                                    <span style={{fontSize:16,color:"#f44447"}}> ( </span>
                                                    {
                                                        Object.keys(type).length > 0 ? Object.keys(type).filter(item => !reg.test(item)).map(item => {
                                                            return (
                                                                type[item] > 0 ? <span key={item} style={{margin:"0 5px",fontSize:14,color:"#f44447"}}> { typeLabel[item.replace("_amountAfter","")]? typeLabel[item.replace("_amountAfter","")] : item.replace("_amountAfter","") }  : {type[item] }</span>  :null
                                                            )
                                                        }) : null
                                                    }
                                                    <span style={{fontSize:16,color:"#f44447"}}> ) </span>
                                                </p>
                                            </div>
                                            <div>
                                                <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportMultiHeaderExcel({columns,dataSource:result,name:"營業收入報表.xlsx"})}/>
                                            </div>
                                        </main>
                                    )
                                }}
                                summary={(currentPageData) => {
                                    let type = {}
                                    let reg = new RegExp("(?<!_amountAfter)$")
                                    let total = currentPageData.reduce((pre,current)=>{
                                        let price = Object.keys(current).reduce((p,c) => {
                                            if(c != "type" && c != "item" && c != "free_amountAfter" && reg.test(c)) {
                                                type[c] = type[c] || 0
                                                type[c]+= parseFloat(current[c])
                                            }
                                            return p + (c != "type" && c != "item" && reg.test(c) ? parseFloat(current[c]) : 0)
                                        },0)
                                        return pre + price
                                    },0)
                                    let totalAmount = currentPageData.reduce((pre,current)=>{
                                        let price = Object.keys(current).reduce((p,c) => {
                                            if(c != "type" && c != "item" && c != "free_amountAfter" && !reg.test(c)) {
                                                type[c] = type[c] || 0
                                                type[c]+= parseFloat(current[c])
                                            }
                                            return p + (c != "type" && c != "item" && !reg.test(c) ? parseFloat(current[c]) : 0)
                                        },0)
                                        return pre + price
                                    },0)

                                    let columns = {}
                                    this.state.columns.forEach((item) => {
                                        if (item.key == 'type' || item.key == "achievement") return
                                        if (item.children) {
                                            item.children.forEach(e => {
                                                columns[e.key] = type[e.key] || 0
                                            })
                                        } else {
                                            columns[item.key] = type[item.key]
                                        }
                                    })

                                    return (
                                        <Table.Summary.Row className="summary-cell">
                                        <Table.Summary.Cell index={0} colSpan={1} style={{borderRight:"0",zIndex:99}}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} style={{borderRight:"0",borderLeft:"0"}}>
                                            <Text type="danger">{total}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <Text type="danger">{totalAmount}</Text>
                                        </Table.Summary.Cell>
                                        {
                                            Object.keys(columns).map((item,index) => {
                                                return (
                                                    <Table.Summary.Cell key={index} index={3 + index}>
                                                        <Text type="danger">{columns[item]}</Text>
                                                    </Table.Summary.Cell>
                                                )
                                            })
                                        }
                                        </Table.Summary.Row>
                                    );
                                }}
                                rowKey={record => record.type}
                                ></Table>
                            </div>
                        </div>
                        <div className='table-contanier'>
                            <div className='table-main'>
                                <Table tableLayout="auto" scroll={{x:true,y:true}} columns={gednerColumns} dataSource={gednerResult} pagination={false} loading={loading} bordered={true}
                                title={(currentPageData) => {
                                    let total = {
                                        "other":0 ,
                                        "other_amountAfter": 0,
                                        "other_times": 0,
                                        "female": 0,
                                        "female_amountAfter": 0,
                                        "female_times": 0,
                                        "male": 0,
                                        "male_amountAfter": 0,
                                        "male_times": 0
                                    }

                                    let typeLabel = {
                                        "other":"其他客戶總計" ,
                                        "other_amountAfter": "其他客戶實際業績",
                                        "other_times": "其他客戶人數",
                                        "female": "女客總計",
                                        "female_amountAfter": "女客實際",
                                        "female_times": "女客人數",
                                        "male": "男客總計",
                                        "male_amountAfter": "男客實際",
                                        "male_times": "男客人數"
                                    }

                                    if (currentPageData.length == 0) return
                                    currentPageData.forEach(item => {
                                        Object.keys(item).forEach((e) => {
                                            if (e=="type") return
                                            total[e] += parseFloat(item[e]) 
                                        })
                                    })

                                    return (
                                        <main style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end"}}>
                                            <div>
                                                <p style={{margin:0,fontSize:18}}>客情分析:
                                                    <span style={{fontSize:16,color:"#f44447"}}> ( </span>
                                                    {
                                                        Object.keys(total).length > 0 ? Object.keys(total).map(item => {
                                                            return (
                                                                total[item] > 0 ? <span key={item} style={{margin:"0 5px",fontSize:14,color:"#f44447"}}> { typeLabel[item] }  : {total[item] }</span>  :null
                                                            )
                                                        }) : null
                                                    }
                                                    <span style={{fontSize:16,color:"#f44447"}}> ) </span>
                                                </p>
                                            </div>
                                            <div>
                                                <Button style={{background:"#abbac3"}} icon={<FileExcelFilled style={{color:"white"}}/>} size={"small"} onClick={() => this.onExportMultiHeaderExcel({columns:gednerColumns,dataSource:gednerResult,name:"客情分析報表.xlsx"})}/>
                                            </div>
                                        </main>
                                    )
                                }}
                                summary={(currentPageData) => {
                                    let total = {
                                        "other":0 ,
                                        "other_amountAfter": 0,
                                        "other_times": 0,
                                        "female": 0,
                                        "female_amountAfter": 0,
                                        "female_times": 0,
                                        "male": 0,
                                        "male_amountAfter": 0,
                                        "male_times": 0
                                    }

                                    if (currentPageData.length == 0) return

                                    currentPageData.forEach(item => {
                                        Object.keys(item).forEach((e) => {
                                            if (e=="type") return
                                            total[e] += parseFloat(item[e]) 
                                        })
                                    })

                                    return (
                                        <Table.Summary.Row className="summary-cell">
                                        <Table.Summary.Cell index={0} style={{borderRight:"0"}}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} style={{borderRight:"0",borderLeft:"0"}}>
                                            <Text type="danger">{parseFloat(total['other'] || 0) + parseFloat(total['male'] || 0) + parseFloat(total['female'] || 0)}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <Text type="danger">{parseFloat(total['other_amountAfter'] || 0) + parseFloat(total['male_amountAfter'] || 0) + parseFloat(total['female_amountAfter'] || 0)}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>
                                        <Text type="danger">{parseFloat(total['other_times'] || 0) + parseFloat(total['male_times'] || 0) + parseFloat(total['female_times'] || 0)}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>
                                            <Text type="danger">{(((parseFloat(total['other'] || 0) + parseFloat(total['male'] || 0) + parseFloat(total['female'] || 0)) / (parseFloat(total['other_times'] || 0) + parseFloat(total['male_times'] || 0) + parseFloat(total['female_times'] || 0)) || 0).toFixed(2)) }</Text>
                                        </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    );
                                }}
                                ></Table>
                            </div>
                        </div>
                    </section>
                }
            </main>
        )
    }
}